import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Globals/GlobalInterface";
import { useAuthUser } from "../../../contexts/auth-context";
import customaxios from "../../../helpers/axios-helper";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Loading_Default from "../../Loading_Defualt";
import Product_Arch_Category_Edit from "../../Product/Product_Arch_Category_Edit";
import FileUploadModal, { FileUploadModalProps } from "../../FileUpload/FileUploadModal";
import ProductRow from "./productrow";
import ImageRow from "../../Images/ImageRow";

export default function ProductArch({ products, arch }: any) {
  const addtocart = true;
  const _globalstate = useSelector((state: RootState) => state);
  const { productName, oeM_Code, productArchId } = arch;
  const AuthUser = useAuthUser();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState(productName);
  const [isUploading, setIsUploading] = useState(false);
  const [removeBackground, setRemoveBackground] = useState(false);
  const [isProductsMinimized, setIsProductsMinimized] = useState(_globalstate.Arch_minmise);

  const toggleProductsMinimized = () => {
    setIsProductsMinimized((prevIsProductsMinimized) => !prevIsProductsMinimized);
  };


  useEffect(() => {
    setIsProductsMinimized(_globalstate.Arch_minmise);
  }, [_globalstate.Arch_minmise]);

  function updateArchName() {
    const formData = {
      Id: productArchId,
      Name: title,
      archImages: null,
    };

    customaxios
      .post("ProductArch/UpdateProuductArchName", formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Product Arch Name Updated");
        }
      });
  }

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRemoveBackground = () => {
    setRemoveBackground((prevState) => !prevState);
  };

  const handleFileUpload = (files: File[]) => {
    const formDataToSend2 = new FormData();
    formDataToSend2.append("productArchId", productArchId.toString());

    files.forEach((file) => {
      formDataToSend2.append("files", file);
    });

    customaxios
      .post(`productarch/${removeBackground ? "ARchImages" : "ARchImages_back"}`, formDataToSend2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          handleCloseModal();
          toast.success(files.length + " Image Uploaded To " + productName);
        }
      });
  };

  const fileUploadModalProps: FileUploadModalProps = {
    isOpen: showModal,
    remove_background: removeBackground,
    onClose: handleCloseModal,
    onFileUpload: handleFileUpload,
    toggleRemoveBackground: handleRemoveBackground,
  };

  let stockcount = 0;

  const filteredlockeditems = products
    .sort((a, b) => a.unmoveditem - b.unmoveditem)
    .filter((product) => {
      stockcount += Number(product.productQuantity.quantity);
      return !_globalstate.zeroqty || product.productQuantity.quantity > 0;
    });

  return stockcount > 0 ? (
    <div className="productArch">
      <div className="archtitlewrapper">
        <div className="title">
          {!isEditingTitle ? (
            <p className="titlename" onClick={() => setIsEditingTitle(true)}>
              {title}
            </p>
          ) : (
            <input
              type="text"
              className="titlename"
              value={title}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => setTitle(event.target.value)}
              onBlur={() => {
                setIsEditingTitle(false);
                updateArchName();
              }}
              autoFocus
            />
          )}
          <p className="ArcCode">
            <Product_Arch_Category_Edit products={products} arch={arch} />
          </p>
          <p className="minimize-button" onClick={toggleProductsMinimized}>
            {isProductsMinimized ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
          </p>
          <div className="OEM_Code">
            <p>{oeM_Code}</p>
          </div>
          <div className="iamgeupload">
            <button onClick={handleOpenModal}>image</button>
          </div>
          <FileUploadModal {...fileUploadModalProps} />
        </div>
      </div>
      <div className="products">
      <ImageRow minfied={true} productarch={arch} />

      {!isProductsMinimized && filteredlockeditems.map((product, index) => (
          <ProductRow addtocart={addtocart} product={product} index={index} key={index} />
        ))}
        {isUploading && <Loading_Default />}
      </div>
    </div>
  ) : (
    <div className="productArch">
      <div className="archtitlewrapper">
        <div className="title">
          {!isEditingTitle ? (
            <p
              className="titlename"
              onClick={
                AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Director"
                  ? () => setIsEditingTitle(true)
                  : undefined
              }
            >
              {title}
            </p>
          ) : (
            <input
              type="text"
              className="titlename"
              value={title}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => setTitle(event.target.value)}
              onBlur={() => {
                setIsEditingTitle(false);
                updateArchName();
              }}
              autoFocus
            />
          )}
          <p className="ArcCode">
            <Product_Arch_Category_Edit products={products} arch={arch} />
          </p>
          <p className="minimize-button" onClick={toggleProductsMinimized}>
            {isProductsMinimized ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
          </p>
          <div className="OEM_Code">
            <p>{oeM_Code}</p>
          </div>
          <div className="iamgeupload">
            <button onClick={handleOpenModal}>image</button>
          </div>
          <FileUploadModal {...fileUploadModalProps} />
        </div>
      </div>
      <div className="products">
      <ImageRow productarch={arch} minfied={true}/>
      {!isProductsMinimized &&filteredlockeditems.map((product, index) => (
          <ProductRow addtocart={addtocart} product={product} index={index} key={index} />
        ))}
        {isUploading && <Loading_Default />}
      </div>
    </div>
  );
}
