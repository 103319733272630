import React, { useState, useEffect, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { faShoppingCart, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Product_Sensitive_Info({ product }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPurchaseHistoryVisible, setIsPurchaseHistoryVisible] = useState(false);
  const [purchaseTrend, setpurchaseTrend] = useState<any>();

  const modalRef = useRef(null);

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };

  const handleTogglePurchaseHistory = () => {
    setIsPurchaseHistoryVisible(!isPurchaseHistoryVisible);
  };

  useEffect(() => {
    if (isPurchaseHistoryVisible) {
      // Code to run when isPurchaseHistoryVisible is true
      customaxios.get(`Stock/GetPurchaseTrend?productId=${product.productId}`)
      .then((res) => {
        console.log(res.data);
      setpurchaseTrend(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
      console.log("Purchase history is visible!");
    }
  }, [isPurchaseHistoryVisible]);
  return (
    <>
      {isModalOpen && (
        <div className="modal_brand" onClick={handleClose} ref={modalRef}>
          <div className="modal_brand-content">
            <h3>History</h3>

            <div className="Product_Last_Purchase_Price">
              <p>
                Last Purchase Price:{" "}
                {product.productPrice.last_PurchasePrice &&
                typeof product.productPrice.last_PurchasePrice === "number"
                  ? product.productPrice.last_PurchasePrice.toLocaleString()
                  : ""}
              </p>
            </div>

            <div className="supplier">
              <p>Supplier: {product.supplier.supplier_Name}</p>
              <p>Supplier Short: {product.supplier.supplier_Short}</p>
            </div>

            <div className="purchase_history">
              <button className={isPurchaseHistoryVisible ? "button_one" : "button_one danger" } onClick={handleTogglePurchaseHistory}>
                {isPurchaseHistoryVisible? "Hide History" : "Show History"}
              </button>
              {isPurchaseHistoryVisible && (
                <div className="Product_history_data">
              

                  <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"> 
                      <tr >
                        <th>Date</th>
                        <th>Qty</th>
                        <th>USD Unit Price</th>
                        <th>Price Unit LKR</th>
                        <th>Total LKR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseTrend &&
                        purchaseTrend.map((purchase) => (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"  key={purchase.id}>
              <td>
                            {purchase.grnItem.created_Datetime.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: '2-digit',
              })}

                            </td>
                            <td>{purchase.movement.toLocaleString()}</td>

              <td>{purchase.grnItem.price_Usd.toLocaleString()} $</td>
              <td>{purchase.grnItem.price_mainUnit.toLocaleString()}</td>
              <td>{Number(purchase.grnItem.amount).toLocaleString()}</td>
                            
                          </tr>
                        ))}
              {
                !purchaseTrend && ((<>No Data Available</>))
              }
                    </tbody>
                  </table>
                </div>
              )}

            </div>
          </div>
        </div>
      )}

      <p className="Sensitive_Info" onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faEye} />
      </p>
    </>
  );
}
