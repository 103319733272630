import React from 'react';
import "./Style/Style.css";
function PaymentModeSelector({ paymentMode, creditType, setPaymentMode, setCreditType }) {
  return (
    <div className="paymentmode">
      <button
        className={`paymentModeBtn ${paymentMode === "credit" ? "selected" : ""}`}
        onClick={() => setPaymentMode("credit")}
      >
        Credit
      </button>
      <button
        className={`paymentModeBtn ${paymentMode === "cash" ? "selected" : ""}`}
        onClick={() => setPaymentMode("cash")}
      >
        Cash
      </button>
      {paymentMode === "credit" && (
        <div className="creditterms">
          <select value={creditType} onChange={(e) => setCreditType(e.target.value)}>
            <option value="">Select a credit type</option>
            <option value="30">30 Days</option>
            <option value="45">45 Days</option>
            <option value="60">60 Days</option>
          </select>
        </div>
      )}
    </div>
  );
}

export default PaymentModeSelector;
