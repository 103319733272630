import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import customaxios from '../../../helpers/axios-helper';
import Spinner from '../../Animations/LoadingAnimation'; // assuming you have the Spinner component in a separate file

interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function PaymentPage() {
  const { id } = useParams<RouteParams>();
  const [payment, setPayment] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true); // state variable for loading status

  useEffect(() => {
    setIsLoading(true); // set loading to true before making the API call
    customaxios.get(`Payment/GetPaymentById/${id}`).then((response) => {
      setPayment(response.data);
      console.log(response.data);
      setIsLoading(false); // set loading to false after getting the data
    });
  }, [id]);

  return (
    <div>
      <h1>Payment Details</h1>
      {isLoading ? ( // conditional rendering of the loading animation
        <Spinner />
      ) : (
        <table>
          <thead>
            <tr>
              <th>Payment Mode</th>
              <th>Amount</th>
              <th>Cheque Number</th>
              <th>Bank Name</th>
              <th>Payment Date</th>
              <th>Customer</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{payment.paymentMode}</td>
              <td>{payment?.amount?.toLocaleString('en-US', {style: 'currency', currency: 'lkr'})}</td>
              <td>{payment.chequeNumber}</td>
              <td>{payment.bankName}</td>
              <td>{payment.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : ''}</td>
              <td>{payment.customer?.customerName}</td>
              <td>{payment.paymentStatus}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
