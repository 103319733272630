import React from 'react';

function SalesOrderDate({ createdDateTime }) {
  const createdDate = new Date(createdDateTime);
  const now = new Date();
  const diffInMs = now.getTime() - createdDate.getTime();
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

  let formattedDate;
  if (diffInHours < 24 && createdDate.getDate() === now.getDate()) {
    // if created date is within the last 24 hours and on the same day as now, show the time
    formattedDate = createdDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else {
    // otherwise, show the date in the format "day, month day"
    formattedDate = createdDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }

  return (
    <div className="Created_date">{formattedDate}</div>
  );
}

export default SalesOrderDate;
