import React, { useState } from 'react';
import customaxios from '../../../../helpers/axios-helper';
import Modal from 'react-modal';

interface GeneralExpense {
  name: string;
  description: string;
  ledger_Id?: number;
  Company_Name:string;
  Value: number;
}

const CreateBank_Loan_Lease: React.FC = () => {
  const [formData, setFormData] = useState<GeneralExpense>({
    name: '',
    description: '',
    Value: 0,
    Company_Name: '',

  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: name === 'Value' ? parseFloat(value) : value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await customaxios.post('Bank_Loan_Lease/Add', formData);
      console.log('New Bank_Loan_Lease ID:', response.data);
      // Optionally, you can perform actions after a successful creation, like redirecting or showing a success message.
      closeModal();
    } catch (error) {
      console.error('Error creating Bank Loan Lease:', error);
      // Optionally, you can handle errors here, e.g., showing an error message to the user.
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button className='button_one'  onClick={openModal}>Create Bank Loan & Lease</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Bank Loan & Lease"
      >
        <h2>Create Bank Loan & Lease</h2>
        <form onSubmit={handleSubmit}>
        <div>
            <label>type:</label>
            <select
                name="Company_Name"
                value={formData.Company_Name}
                onChange={handleChange}
                id="Type"
              >
                <option value="-">-</option>
                <option value="OAM">OAM</option>
                <option value="RAH-Maruti">RAH-Maruti</option>
              </select>
          </div>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
            <label>Amount:</label>
            <input
              type="number"
              name="Value" // Changed from "description" to "Amount"
              value={formData.Value.toString()}
              onChange={handleChange}
              required
            />
          </div>
          {/* Additional input fields can be added for other properties */}
          <button type="submit">Create</button>
          <button onClick={closeModal}>Cancel</button>
        </form>
      </Modal>
    </div>
  );
};

export default CreateBank_Loan_Lease;
