import React from "react";

import { useLocation } from "react-router-dom";

import customaxios from "../helpers/axios-helper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Product} from "../models/Products/product";
import { CartProvider, Item, useCart } from "../components/Cart/index";


import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


export default function ProductListbybrand({props}:any) {


  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [productView, setProductView] = useState<Product>();
  const [CartProduct, setCartProduct] = useState<Item>();


    const location = useLocation();
    //console.log(location);
    //console.log(location.state);

    const { addItem,  isEmpty,
      totalUniqueItems,
      items,
      updateItemQuantity,
      removeItem, } = useCart();

console.log( location.state);

 
    //create product list 
    const [Product_List, setItems] = useState([]);
    
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('stock/movementrate_brands',
        {            categoryname: location.state.brand_Name,
        })

            .then((response) => {
              console.log(response);
                setItems(response.data);
                console.log(Product_List);
            })
    }, []);
   
   
   /* const notify = () => 
    toast.success(Product_List.length +" Products Loaded")*/
    return(

        <div className="groupProductview">
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{
        productView?(
       <div>
           <TableContainer component={Paper}>
          <Table  sx={{ minWidth: 650 }}  aria-label="simple table">
          <TableRow >
                <TableCell align="left">{productView.suppliercode}</TableCell>
                <TableCell align="left">{productView.productName}</TableCell>
                <TableCell align="left">{productView.oaM_ACode}</TableCell>
                <TableCell align="left">{productView.brand.brand_Name}</TableCell>
                <TableCell align="left">subtotal</TableCell>
                 
                <TableCell align="left"></TableCell>
              
                </TableRow>
            <TableBody>

            <TableCell align="left">
            <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="web">Credit</ToggleButton>
          <ToggleButton value="android">Cash</ToggleButton>
        </ToggleButtonGroup>
            </TableCell>
            <TableCell>
            Unit Price <br/>{productView.productPrice.price} LKR
            </TableCell>
            <TableCell align="left">QTY <input type="number" value={productView.quantity} onChange={(e) => setProductView({...productView, quantity: parseInt(e.target.value)})} /></TableCell>
  
            <TableCell align="left">Discount <input type="number" value={productView.quantity} onChange={(e) => setProductView({...productView, quantity: parseInt(e.target.value)})} /></TableCell>
            <TableCell align="left"> {productView.productPrice.price * parseInt(productView.stringQuantity)}</TableCell>
            <TableCell align="left"></TableCell>
                
            </TableBody>
            <TableRow >
            <TableCell align="left">
            <TextField id="standard-basic" label="notes" variant="standard" />

            </TableCell>
            <Button variant="contained">add to cart</Button>



                </TableRow>
          </Table>
        </TableContainer>
        <div>
         
       
    
        </div>
       </div>
        
         
        ):(
          <div>Loading...</div>
        )
      }
        
        
        </Box>
      </Modal>
                    <div 
                    //add custom style 
                    key={location.state.brand_Name}
                    className="BrandCard"
                    //style
                    style={{
                        backgroundImage: `url(${location.state.image_url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                       //width
                        width: "450px",
                        height: "250px",
                        //padding
                        padding: "10px",
                        //border
                        border: "1px solid #ddd",
                        //border radius
                        borderRadius: "10px",
                        //box shadow
                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                        //margin
                        margin: "0 auto",
                        //cursor
                        cursor: "pointer",
                    }}
                >

                   
                    <p className="ItemName">{location.state.image_url ? "" : location.state.brand_Name}</p>

                    <p className="ProductCount">{"products " + location.state.productcount}</p>

                </div>
                
      

                <TableContainer component={Paper}>
      <Table  sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Supplier Code</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>A Code</TableCell>
            <TableCell align="left">qty instock</TableCell>
            <TableCell align="left">Sale Price</TableCell>
            <TableCell align="left">number of invoices</TableCell>
            <TableCell align="left">avg units in invoice</TableCell>
            <TableCell align="left">avg sale Price</TableCell>
            <TableCell align="left">avg discount%</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {Product_List.sort((a, b) => b.length-a.length).map((row) => (

             <TableRow 
             style={
              //borderraduis
              {
                borderRadius: "10px",
              //border
              border: "1px solid #e0e0e0",
              //boxshadow
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
              //padding
              padding: "10px",
              //margin
              margin: "10px",
              //background
              background: "#fff",
              //transition
              
              

             }
             }
             
              key={row.stocklevelsId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
          
              <TableCell align="left">{row[0].product.suppliercode}</TableCell>
              <TableCell align="left">{<Link state={row[0].product} to="/ProductPage">{row[0].product.productName}</Link>}</TableCell>
              <TableCell align="left">{row[0].product.oaM_ACode}</TableCell>
              <TableCell align="left">{row[0].product.stringQuantity}</TableCell>
              <TableCell align="left">{row[0].product.string_Type_Sale_Price}</TableCell>
              <TableCell align="left">{row.length}</TableCell>
              <TableCell align="left">{(row.reduce((total, obj) => total + obj.movement, 0) / row.length).toFixed(2)}</TableCell>
              <TableCell align="left">{
              (
                row.reduce((total, obj) => {
                  if (obj.orderItem && obj.orderItem.total_Amount) {
                    return total + obj.orderItem.total_Amount;
                  }
                  return total;
                }, 0) / row.length).toFixed(2).toLocaleString()
              
              
              }</TableCell>
                              
                              <TableCell align="left">{
              (
                row.reduce((total, obj) => {
                  if (obj.orderItem && obj.orderItem.discount_Percentage) {
                    return total + obj.orderItem.discount_Percentage;
                  }
                  return total;
                }, 0) / row.length).toFixed(2).toLocaleString()
              
              
              }</TableCell>
    
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>

       
    );

}


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: '#F3FFF5',
  border: '2px solid #25D442',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};