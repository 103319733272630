import React, { useState, useEffect, useRef } from "react";
import customaxios from "../../../helpers/axios-helper";
import ProductChart from "./ProductChart";
import InStockTable from "./InStockTable";
import OutStockTable from "./OutStockTable";
import { Chart } from "chart.js";
import "chart.js/auto";
import RTNStockTable from "./RTNStockTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Sales_By_Customer from "./Sales_By_Customer/Sales_By_Customer";
import Sales_By_Salesrep from "./Get_Sales_By_Sales_rep/Sales_By_Salesrep";
import "./Style/Style.css"
interface ProductStockLevelProps {
  id: string;
  opening_stock: number;
}

const ProductStockLevel: React.FC<ProductStockLevelProps> = ({ id,opening_stock }) => {
  const [productStockLevel, setProductStockLevel] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>("out");

  useEffect(() => {
    // get stock levels by product ID
    customaxios
      .post(`Stock/GetStockLevelsByProductId/`, { productId: id })
      .then((res) => {
        //console.log(res.data);
        setProductStockLevel(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const calculateTotalSales = () => {
    let totalSales = 0;
    for (const item of productStockLevel) {
      if (item.type === "out") {
        totalSales += item.movement;
      }
    }
    return totalSales;
  };

  const calculateTotalPurchase = () => {
    let totalPurchase = 0;
    for (const item of productStockLevel) {
      if (item.type === "In") {
        totalPurchase += item.movement;
      }
    }
    return totalPurchase;
  };
  const calculateTotalReturns = () => {
    let TotalReturns = 0;
    for (const item of productStockLevel) {
      if (item.type === "RTN") {
        TotalReturns += item.movement;
      }
    }
    return TotalReturns;
  };
  const calculateTotalOffset = () => {
    let calculateTotalOffset = 0;
    for (const item of productStockLevel) {
      if (item.type === "ADJUSTMENT") {
        calculateTotalOffset += item.movement;
      }
    }
    return calculateTotalOffset;
  };

  const calculateCurrentStock = () => {
    let calculateCurrentStock = opening_stock;
    calculateCurrentStock= calculateCurrentStock-calculateTotalSales()+calculateTotalPurchase()-calculateTotalReturns();

    return calculateCurrentStock;
  };
  const calculateCurrentStock_withoffset = () => {
    let calculateCurrentStock = opening_stock;
    calculateCurrentStock= calculateCurrentStock-calculateTotalSales()+calculateTotalOffset()+calculateTotalPurchase()-calculateTotalReturns();

    return calculateCurrentStock;
  };

  const PieChart = () => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext("2d");
        if (ctx) {
          new Chart(ctx, {
            type: "pie",
            data: {
              labels: ["Total Sales", "Total Purchase"],
              datasets: [
                {
                  data: [calculateTotalSales(), calculateTotalPurchase()],
                  backgroundColor: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
                },
              ],
            },
          });
        }
      }
    }, [productStockLevel]);

    return <canvas id="chartContainer" ref={chartRef} style={{ maxWidth: "150px" }} />;
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <div className="Product_Stock_Info">
      <h2>Stock Movement</h2>
     <div className="Transaction_Short">
     <div className="totals-section">
        <table>
          <tbody>
            <tr>
              <td>Current Stock without offset/Adjustment</td>
              <td>{calculateCurrentStock().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td>Current Stock with offset/Adjustment</td>
              <td>{calculateCurrentStock_withoffset().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td>Total Sales</td>
              <td>{calculateTotalSales().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td>Total Purchase</td>
              <td>{calculateTotalPurchase().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td>Total Returns</td>
              <td>{calculateTotalReturns().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td>Total Offsets</td>
              <td>{calculateTotalOffset().toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
            </tr>
          </tbody>
        </table>
      </div>
     <ProductChart data={productStockLevel} />
    
     </div>

      <div className="Transaction_Stock_details">
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Stock Tabs">
        <Tab label="In Stock" value="in" />
        <Tab label="Out Stock" value="out" />
        <Tab label="customer" value="customer" />
        <Tab label="Sales rep" value="Sales_rep" />
        <Tab label="Returns" value="returns" />
      </Tabs>
      {activeTab === "in" ? (
        <InStockTable stockLevels={productStockLevel} />
      ) : activeTab === "out" ? (
        <OutStockTable stockLevels={productStockLevel} />
      ) 
      : activeTab == "customer"? (
      <Sales_By_Customer id={id}/>
      )
      : activeTab == "Sales_rep"? (
        <Sales_By_Salesrep id={id}/>
        )
      : (
        <RTNStockTable stockLevels={productStockLevel} />
      )}
      </div>
      {
        /* <PieChart /> */
      }
    </div>
  );
};

export default ProductStockLevel;
