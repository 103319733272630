import React, { useEffect, useState } from "react";

import customaxios from '../../../helpers/axios-helper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
export default function  Branches (){
    const [Branches, setBranches] = useState([]);
    const [Branch, setBranch] = useState<any>({});


    useEffect(() => {
        async function fetchData() {
          try {
            const response = await customaxios.get("Branch/GetAllBranches");
            setBranches(response.data);
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }, []);



      const handleChange = (e) => {
        setBranch({ ...Branch, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await customaxios.post("Branch/CreateBranch", Branch);
          alert("Branch Created successfully!");
        } catch (error) {
          console.error(error);
          alert("Failed to Create Branch");
        }
      };


    return(
        <div>

 <form onSubmit={handleSubmit}>
        <label htmlFor="Branch">Branch:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={Branch.Name}
          placeholder="Name"
          onChange={handleChange}
        />
             <input
          type="text"
          id="Description"
          name="Description"
          placeholder="Description"
          value={Branch.Description}
          onChange={handleChange}
        />
             <input
          type="text"
          id="Contact_No"
          name="Contact_No"
          placeholder="Contact_No"
          value={Branch.Contact_No}
          onChange={handleChange}
        />
            
        <button type="submit">Create Branch</button>
      </form>


         <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Branch ID</TableCell>
            <TableCell>name</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Branches.map((Branch) => (
            <TableRow key={Branch.branchId}>
              <TableCell>{Branch.branchId}</TableCell>
              <TableCell>{Branch.name}</TableCell>
              <TableCell>{Branch.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
    )
}