import React, { useState, useEffect,useCallback,useMemo } from 'react';
import customaxios from '../../../../helpers/axios-helper';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import View_Vouchers_row from '../View_Vouchers_row';
interface payee {
    payeeId: number;
    name: string;
    description: string;
    payeeType: string;
  }
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function VoucherByPayee() {
    const { id } = useParams();
    console.log("id");
    console.log(id);
  const classes = useStyles();
  const [vouchers, setVouchers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalVouchers, setTotalVouchers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pagedVouchers, setPagedVouchers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [payee, setPayee] = useState<payee>(null);
    useEffect(() => {

    customaxios.get(`/Payee/GetPayeeById/${id}`).then((response) => {
      setPayee(response.data);
      console.log(response.data);
      console.log("payee",payee);
    }).catch((error) => {
      console.log(error);
    });
    }, [id]);
  const fetchData = useCallback(async (pageNumber, pageSize, search) => {
    try {

      const response = await customaxios.get(`Voucher/View_Vouchers_By_Payee?PayeeId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
    
      setVouchers(response.data.vouchers);
      console.log(response.data.vouchers);

      setTotalVouchers(response.data.totalVouchers);
      setTotalPages(response.data.totalPages);
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, response.data.vouchers.length);
      setPagedVouchers(response.data.vouchers);

    } catch (error) {
      console.log(error);
    }
  }, []);
  
  useEffect(() => {
    fetchData(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm, fetchData]);
  
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };
  
  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };
  
  return (
    <>

<div className="payeeInfo">
        {payee && (
          <>
            <h2>Payee Information:</h2>
            <p>Payee ID: {payee.payeeId}</p>
            <p>Name: {payee.name}</p>
            <p>Description: {payee.description}</p>
            <p>Payee Type: {payee.payeeType}</p>
          </>
        )}
      </div>
<div className="voucherView">
<div className="voucher_View_Row">
<div className="voucher_Id">
  <p>Id</p>
</div>
<div className="Voucer_Date">
  <p>Date</p>
</div>
<div className="VoucherTotal">
  <p>total</p>
</div>
<div className="Paymentmode">
  <p>paymentmode</p>
</div>
<div className="payee">
          <p>payee</p>
        </div>
</div>

{pagedVouchers.map((voucher) => (
        <View_Vouchers_row voucher={voucher}/>
          ))}
<TablePagination
className='pagination-bar'
      component="div"
      count={totalVouchers}
      page={currentPage - 1}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      labelRowsPerPage="Results per page:"
      onRowsPerPageChange={handleRowsPerPageChange}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} (page ${currentPage} of ${totalPages})`}
    />
</div>

    
   
  </>
  );
}
