import React, { useState, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '../../Globals/GlobalInterface';
import customaxios from '../../helpers/axios-helper';
import { toast } from 'react-toastify';
import { useAuth, useAuthUser } from "../../contexts/auth-context";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./Style/Style.css";
export interface Image {
  src: string;
  alt: string;
}

interface ImageRowProps {
  productarch: any;
  minfied:boolean;

}

function ImageRow({ productarch,minfied }: ImageRowProps ) {
  const _globalstate = useSelector((state: RootState) => state);
  const archimages = Boolean(_globalstate.ArchImages);
  const AuthUser = useAuthUser();

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  useEffect(() => {
    const foundIndex = productarch.archImages.findIndex(
      (item) => item.url === selectedImage
    );
    setSelectedImageIndex(foundIndex > -1 ? foundIndex : 0);
  }, [productarch, selectedImage]);

  const openModal = (url: string) => {
    setSelectedImage(url);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage('');
    setShowModal(false);
  };

  const handleLeftArrowClick = () => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex =
        prevIndex === 0 ? productarch.archImages.length - 1 : prevIndex - 1;
      setSelectedImage(productarch.archImages[newIndex].url);
      return newIndex;
    });
  };

  const handleRightArrowClick = () => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex =
        prevIndex === productarch.archImages.length - 1 ? 0 : prevIndex + 1;
      setSelectedImage(productarch.archImages[newIndex].url);
      return newIndex;
    });
  };

  const handleRemoveImage = () => {
    const updatedProductArch = {
      ...productarch,
      archImages: productarch.archImages.filter(
        (image) => image.url !== selectedImage
      ),
    };

    console.log("updatedProductArch");
    console.log(selectedImageIndex);

    customaxios
      .post('/api/updateProductArch', updatedProductArch)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    closeModal();
  };

  function handleKeyDown(e) {
    if (!showModal) return;

    if (e.keyCode === 37) {
      // left arrow
      handleLeftArrowClick();
    } else if (e.keyCode === 39) {
      // right arrow
      handleRightArrowClick();
    }
  }

  const handleBlockImage = () => {
    setShowModal(false);
    const selectedImageId = productarch.archImages[selectedImageIndex].imageId;
  
    const confirmationMessage = 'Are you sure you want to block this image?';
  
    confirmAlert({
      title: 'Confirmation',
      message: confirmationMessage,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            customaxios
              .post(`/productarch/blockimage?ImageId=${selectedImageId}`)
              .then((response) => {
                console.log(response.data);
                toast.success('Image blocked');
                closeModal();
              })
              .catch((error) => {
                console.log(error);
                toast.error('Image block failed');
              });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const handleSet_Default_Image = (id) => {
    const selectedImageId = productarch.archImages[selectedImageIndex].imageId;
    const imageId = id !== null ? id : productarch.archImages[selectedImageIndex].imageId;

    customaxios
    .post(`/productarch/DefaultImage?ImageId=${imageId}`)
    .then((response) => {
      console.log(response.data);
      toast.success('Image Defaulted');
            // Move the selected image to the first position in productarch.archImages
        //    const selectedImage = productarch.archImages[selectedImageIndex];
        const selectedImageIdToSearch = id != null ? id : selectedImageId;
        const selectedIndex = productarch.archImages.findIndex(image => image.imageId === selectedImageIdToSearch);
        
        if (selectedIndex !== -1) {
          // Remove the selected image from its current position
          const selectedImage = productarch.archImages.splice(selectedIndex, 1)[0];
        
          // Add the selected image to the beginning of the array
          productarch.archImages.unshift(selectedImage);
        } else {
          // Handle the case where the selected image is not found
        }
        
      closeModal();
    })
    .catch((error) => {
      console.log(error);
      toast.error('Image Default failed');
    });
  };



  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImageIndex]);


  return (
    <>
      {archimages ? (
        <div className="imagerow">
          <div  className={' image-wrapper '+(minfied == false ? 'Arch_Mode_View':'')} >
            {productarch.archImages
              .filter((item) => !item.blocked)
              .map((item, index) => (
                <div
                  className="image"
                  key={item.id}
                 // onClick={() => openModal(item.url)}

                  onMouseDown={(e) => {
                    //console.log(item);
                    if (e.ctrlKey) {
                      handleSet_Default_Image(item.imageId);
                    } else {
                      openModal(item.url); // Call openModal directly when Ctrl is not pressed
                    }
                  }}
                >
                  
                  <img className={minfied == false ? 'Arch_Mode_View':''}  src={item.url} alt={productarch.productName} />
                </div>
              ))}
          </div>
        </div>
      ) : null}
      {showModal && (
        <div className="imagerowmodal" onClick={closeModal}>

          <div
            className="imagerowmodal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="Title">{productarch.productName}</p>
            <div className="image-index">
              {selectedImageIndex + 1} of {productarch.archImages.length}
            </div>
            <div className="modal-image">
              <img src={selectedImage} alt={productarch.productName} />
            </div>
            <div className="modal-image-arrows">
              <button className="arrow left-arrow" onClick={handleLeftArrowClick}>
                <FiChevronLeft />
              </button>
              <button className="arrow right-arrow" onClick={handleRightArrowClick}>
                <FiChevronRight />
              </button>
              <div className="modal-buttons">
                {/* <button className="remove-button" onClick={handleRemoveImage}>
                  Remove Image
                </button>
                  <button className="button_one" onClick={handleSet_Default_Image}>
                  Set Default
                </button>
                */}
                
                          {
            AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" ? (
                <button className="remove-button" onClick={handleBlockImage}>
                  Block Image
                </button>
   ):(<> </>)
  }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageRow;
