import React, { useState, useEffect, ChangeEvent,useCallback } from 'react';
import { Orderfilter } from './Orderfilter';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useAuthUser } from '../../../contexts/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { LineProductSummary } from '../LineProductSummary';
import StatusDropdown from '../Status_Model/StatusDropdown';
import Line_Item_Qty from '../Line_Item_Qty';
import StatusDropdown_Bulk from '../Status_Model/StatusDropdown_Bulk';
import SelectedToInvoices from '../SelectedToInvoices';
import Invoice_Controller from '../../Invoice/Invoice_Controller';

interface OrderItemsProps {
  orders: any;
 // onSelectOrderItems: (selectedOrderItems: any[]) => void;
 onStatusChange: (id: number, newStatus: string) => void;
}
const tabOptions = [
  { status: '', label: 'All' },
  { status: 'Approved', label: 'Approved' },
  { status: 'Pending', label: 'Pending' },
  { status: 'ReadyToPack', label: 'Ready To Pack' },
  { status: 'ReadyToDispatch', label: 'Ready To Dispatch' },
  { status: 'Dispatch', label: 'Dispatch' },
  { status: 'Declined', label: 'Declined' },
  { status: 'Invoiced', label: 'Invoiced' },
  { status: 'Refund', label: 'Refund' },
];

const OrderItems_comp_2: React.FC<OrderItemsProps> = ({
  orders,
  onStatusChange,
}) => {
  const [clickedItemIndex, setClickedItemIndex] = useState<number | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredTabOptions, setFilteredTabOptions] = useState([]);
  const auth = useAuthUser();
const [Vchseries,setVchseries] = useState(orders.vchseries)
//console.log(orders.vchseries)
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: any }>({});
  const [selectedHeaderCheckboxes, setSelectedHeaderCheckboxes] = useState<{ [key: string]: boolean }>({});
  const selectedItemsArray = Object.values(selectedItems).flat();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const handleCheckboxChange = (creditPeriod: string, orderId: any, isChecked: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };
  
      if (isChecked) {
        if (!updatedSelectedItems[creditPeriod]) {
          updatedSelectedItems[creditPeriod] = [];
        }
        updatedSelectedItems[creditPeriod].push(orderId);
      } else {
        const selectedCreditPeriod = updatedSelectedItems[creditPeriod];
        if (selectedCreditPeriod) {
          const index = selectedCreditPeriod.indexOf(orderId);
          if (index !== -1) {
            selectedCreditPeriod.splice(index, 1);
          }
        }
      }
  
      return updatedSelectedItems;
    });
  // Use useEffect to log the updated state after it's been updated

  };

  const handleHeaderCheckboxChange = (creditPeriod: string, isChecked: boolean, orders: any[]) => {
    setSelectedHeaderCheckboxes((prevSelectedHeaderCheckboxes) => ({
      ...prevSelectedHeaderCheckboxes,
      [creditPeriod]: isChecked,
    }));
  
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };
      if (isChecked) {
        updatedSelectedItems[creditPeriod] = orders.map((order) => order.id);
      } else {
        delete updatedSelectedItems[creditPeriod];
      }
      return updatedSelectedItems;
    });
  // Use useEffect to log the updated state after it's been updated

  };
  /*
  useEffect(() => {
    console.log(selectedItemsArray);
  }, [selectedItems]);


*/


  useEffect(() => {
    setFilteredTabOptions(
      tabOptions
        .map((option) => {
          const relevantOrders = orders?.ordeOrderItems?.filter((orderItem) => orderItem.orderItemStatus === option.status) ?? [];
          const count = option.status === '' ? orders?.ordeOrderItems?.length : relevantOrders.length;
          return { ...option, count };
        })
        .filter(option => option.count > 0)
    );
  }, [orders]);

  const renderOrderItemsContent = (status: string) => (
    
    <div className="OrderItems__content">
      

<div key={Orderfilter(orders?.ordeOrderItems ?? [], status).length+"key_Orders"} className="Order_Items_Payment_mode">
    

        {Object.entries(Orderfilter(orders?.ordeOrderItems ?? [], status)).map(([paymentMode, ordersByOrderId], paymentIndex) => (
          Object.entries(ordersByOrderId).map(([orderId, ordersByCreditPeriod]) => (
            <div key={orderId} className="Order_Items_Credit_Period">
              {
             // selectedPaymentTab === paymentIndex && (
                <div className="flex border-2 border-gray-200 border-solid rounded border-b-0">
                
               
              

 
                <p className="flex-none -rotate-90 w-7 h-7 flex items-center m-auto justify-center">{paymentMode}</p>

 

  <div  className='flex-initial w-full'>

  {Object.entries(ordersByCreditPeriod).map(([creditPeriod, orders]: [string, any], index) => (
                    <div   key={creditPeriod}>
                      
                      {
                        <div className="flex border-2  border-gray-200 border-solid  rounded border-b-0 w-full ">

                    
                   
                  <p className='flex-none w-10 h-7 m-auto  -rotate-90'>{creditPeriod} D</p>
                 
                 
           {
            /*
            <table  className="flex-initial w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                  <tr key={index} className={` bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${historyRow.orderItemStatus}`}>
                 
            */
           }          <table className="Order_Items_Table">
           <thead className='uppercase text-xs '>
             <tr className="Line_Item_order titles">
                  <th className="rowcount">#</th>
                                  <th>
                                    <input
                                      className="Selector"
                                      type="checkbox"
                                      onChange={event => handleHeaderCheckboxChange(creditPeriod, event.target.checked, orders)}
                                    />
                                  </th>
                                <th className="brand">Brand</th>
                                <th className="productName">Name</th>
                                <th className="Line_Item_Discount">Discount</th>
                                <th className="Line_Item_Qty">Quantity</th>
                                <th className="listPrice">List Price</th>
                                <th className="price">Nett</th>
                                <th className="total_amount">Amount</th>
                                {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
                                  <th className="profit">Profitability</th>
                                )}
                                
                              </tr>
                            </thead>
                            <tbody>
                              {
                             
                                orders.map((historyRow: any, index: number) => (
                                  <tr key={index} className={`Line_Item_order ${historyRow.orderItemStatus}`}>
                               
                                  {clickedItemIndex === index && (
                                    <LineProductSummary historyRow={historyRow} onClose={() => setClickedItemIndex(null)} />
                                  )}
                          
                                  <td className="rowcount">{index + 1}</td>
                    <>      {//console.log(historyRow)
                    }</>
                                    <td>
                                    <input
                  className="Selector"
                  type="checkbox"
                  checked={selectedItems[creditPeriod]?.includes(historyRow.id) || false}
                  onChange={(event) =>  handleCheckboxChange(creditPeriod, historyRow.id, event.target.checked)
                  }
                />
                                    </td>
                            
                                 


                          <td className="brand">{historyRow.product?.brand?.brand_Name}</td>
                                  <td className="productName" onClick={() => setClickedItemIndex(index)}>
                                    {historyRow.product.productName}
                                    {historyRow.notes?.length != null && (
                                    
                                       <>
                                       (<FontAwesomeIcon icon={faStickyNote} className="note-icon" />)
                                       </> 
                                    )}
                                  </td>
                          
                                  <Line_Item_Qty historyRow={historyRow} />
                                  <td className="listPrice">
                                    {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                                      historyRow.listPrice
                                    )}
                                  </td>
                          
                                  <td className="total_amount">
                                    {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                                      historyRow.total_Amount
                                    )}
                                  </td>
                          
                                  {auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer" ? (
                                    <td className="profit">
                                      {(
                                        ((historyRow.total_Amount - historyRow.quantity * historyRow.product.productPrice.last_PurchasePrice) /
                                          historyRow.total_Amount) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </td>
                                  ) : null}
                                </tr>
                               ))}
                            </tbody>
                  </table>
             
                
                        </div>
                    //  )
                  }
<br />

                    </div>
                  ))}



           

             
                 
                </div>
                </div>
           //   )
              }
            </div>
          ))
        ))}
      </div>
    </div>
  );
  

  return (
    <>
    <div className="OrderItems">
    <a className='bg-blue-500 text-white px-4 py-2 mt-4' href={`/Invoice_Controller/`+orders.orderId} target='_blank'> View Invoices</a>
    {selectedItemsArray.length > 0 ? (
      <>
    <h2>    Update {selectedItems.length} Status</h2>
        <StatusDropdown_Bulk ids={selectedItemsArray} onStatusChange={onStatusChange} />
      </>
    ) : null}

{selectedItemsArray.length > 0 ? (
      <>
 
    <SelectedToInvoices vchseries={Vchseries}  billing_Group_Contained={orders.billing_Group_Contained}  ids={selectedItemsArray}    />
     
      </>
    ) : null}
      <div className="Orderitems_Filter_tabs">
        <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)}>
          {filteredTabOptions.map((option, index) => (
            <Tab
              key={index}
              className={option.status}
              label={`${option.label} (${option.count})`}
            />
          ))}
        </Tabs>
      </div>

      {filteredTabOptions[selectedTab] && renderOrderItemsContent(filteredTabOptions[selectedTab].status)}





    </div>

    <div>

    

{/*
  <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
  <Tab label="Invoice/s" />
</Tabs>

tabValue === 0 && (
  <>
    {orders.orderId && <Invoice_Controller id={orders.orderId} />}
  </>
)*/}
    </div>
    </>
  );
};

export default OrderItems_comp_2;
