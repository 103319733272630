import React, { useEffect, useState } from "react";
import customaxios from "../../../../helpers/axios-helper";
import { useParams } from 'react-router-dom';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

import "./Style/Style.css"
interface RouteParams {
  id: string;
  [key: string]: string;
}

const Sales_By_Salesrep: React.FC<{ id: string | null }> = ({ id: routeId }) => {
  const { id: paramId } = useParams<RouteParams>();
  const id = paramId ? paramId : (routeId as string);
  const [productStockLevel, setProductStockLevel] = useState<any[]>([]);
  const [selectedStockLevels, setSelectedStockLevels] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ascending, setAscending] = useState<boolean>(true);

  const [sortKey, setSortKey] = useState<keyof any>('totalValue'); // Default sorting by 'sales_Rep'
  const handleHeaderClick = (key: keyof any) => {
    if (key === sortKey) {
      // If the same header clicked again, toggle the sorting order
      setAscending((prevAscending) => !prevAscending);
    } else {
      // If a different header clicked, set the new key and reset the sorting order to ascending
      setSortKey(key);
      setAscending(true);
    }
  };
  useEffect(() => {
    if (id) {
      // get stock levels by product ID
      customaxios
        .post(`Stock/Get_Sales_By_Sales_rep/`, { productId: id })
        .then((res) => {
       //  console.log(res.data);
          setProductStockLevel(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleRowClick = (stockLevels: any[]) => {
    setSelectedStockLevels(stockLevels);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  console.log(id);
  const sortedProductStockLevel = [...productStockLevel].sort((a, b) => {
    // Sort the data based on the selected key and sorting order
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (ascending) {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
    }
  });

  selectedStockLevels.sort((a, b) => {
    const dateA = a.levelDateTime ? a.levelDateTime : null;
    const dateB = b.levelDateTime ? b.levelDateTime : null;
    return dateB - dateA ;
  });
  return (
    <div>
      <h1>Product Sales by Sales Rep</h1>
      <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="_Table_Custom_Row Title">
        <th onClick={() => handleHeaderClick('sales_Rep')}>
  Sales_rep
  {sortKey === 'sales_Rep' && ascending ? <FaCaretUp /> : <FaCaretDown />}
</th>
<th onClick={() => handleHeaderClick('totalUnitsSold')}>
  Total Units Sold
  {sortKey === 'totalUnitsSold' && ascending ? <FaCaretUp /> : <FaCaretDown />}
</th>
<th onClick={() => handleHeaderClick('totalValue')}>
  Total Value
  {sortKey === 'totalValue' && ascending ? <FaCaretUp /> : <FaCaretDown />}
</th>
<th onClick={() => handleHeaderClick('stocklevels')}>
  Number of sales
  {sortKey === 'stocklevels' && ascending ? <FaCaretUp /> : <FaCaretDown />}
</th>        

</tr>
        </thead>
        <tbody>
          {sortedProductStockLevel.map((item, index) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={index} onClick={() => handleRowClick(item.stocklevels)}>
              <td>{item.sales_Rep}</td>
              <td>{item.totalUnitsSold.toLocaleString('en-GB', { maximumFractionDigits: 2 })}</td>
              <td>{item.totalValue.toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })}</td>
              <td>{item.stocklevels?.length}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {isModalVisible && (
        <div className="sales-by-Sales_rep-modal">
          <div className="sales-by-Sales_rep-modal-content">
            <button className="sales-by-Sales_rep-modal-close-btn button_one danger" onClick={closeModal}>Close</button>
            <h2>Stock Levels</h2>
            <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
     <tr >
             
                  <th>Date</th>
                  <th >Customer</th>
                  <th>price</th>
                  <th>Discount</th>
                  <th>Unit Price</th>
                  <th>QTY</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
  {selectedStockLevels.map((stockLevel, index) => {
    const levelDateTime = stockLevel.levelDateTime ?  stockLevel.levelDateTime : null;
    const prevStockLevel = selectedStockLevels[index - 1];
    const prevLevelDateTime = prevStockLevel ? prevStockLevel.levelDateTime : null;
    const daysDifference =
      index > 0 && levelDateTime && prevLevelDateTime ? Math.floor((prevLevelDateTime - levelDateTime) / (1000 * 60 * 60 * 24)) : 0;

    return (
      <React.Fragment key={index}>
        
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <td>
            {levelDateTime
              ? levelDateTime.toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })
              : 'no date'}
              {" ("+ daysDifference + " ago)"}
          </td>
          <td>{stockLevel.orderItem?.order?.partyname}</td>
          <td>
            {stockLevel.orderItem?.unitPrice.toLocaleString('en-GB', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'LKR',
            })}
          </td>
          <td>{stockLevel.orderItem?.discount_Percentage} %</td>
          <td>
            {stockLevel.orderItem?.price.toLocaleString('en-GB', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'LKR',
            })}
          </td>
          <td>
            {stockLevel.orderItem?.quantity.toLocaleString('en-GB', {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {stockLevel.orderItem?.total_Amount.toLocaleString('en-GB', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'LKR',
            })}
          </td>
        </tr>
      </React.Fragment>
    );
  })}
</tbody>

            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sales_By_Salesrep;
