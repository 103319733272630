import  { useState, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import {  useAuthUser } from "../../contexts/auth-context";

export default function Product_Itemdetails_change({ product }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const AuthUser = useAuthUser();
  const [itemdetails, setcategoryname] = useState(product.itemdetails);

  const modalRef = useRef(null);


  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/product/update_Name", {
        Product_name: itemdetails,
        Product: product.productId,
      })
      .then((response) => {
        console.log("set Item Deitals");
        console.log(response.data);
        console.log("set false");

        setIsEditing(false);
        toast.success('Item Deitals updated');

        product.itemdetails = itemdetails;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setcategoryname(product.itemdetails);
  };

  const handleBrandNameChange = (event) => {
    setcategoryname(event.target.value);
  };

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal_brand" onClick={handleClose} ref={modalRef}>
          <div className="modal_brand-content">
            {isEditing ? (
              <div className="brand_Name">
                <h2>Update item details</h2>
                <input
                  type="text"
                  value={itemdetails}
                  onChange={handleBrandNameChange}
                />
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={handleCancelEditing}>Cancel</button>
              </div>
            ) : (
              <>
                <h4>Update Name</h4>
                <p onClick={() => setIsEditing(true)}> click here to edit {itemdetails}</p>
              </>
            )}
            
          </div>
        </div>
      )}


{
  (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Praveen" || AuthUser?.role === "Developer") ? (
    <p onClick={() => setIsModalOpen(true)}> {product.itemdetails}</p>
  ) : (
    <p>{product.itemdetails}</p>
  )
}

    </>
  );
}
