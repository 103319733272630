import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from "react-router-dom";
import {createStore} from 'redux';
import allreducer from './Reducers/Index';
import { Provider } from 'react-redux';
import {ThemeProvider } from './components/Controls/Theme/ThemeContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(
  allreducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);root.render(

    <Provider store={store}>
        <Router>  
          <ThemeProvider>
           <App />
           </ThemeProvider>
        
        </Router>
 
      </Provider>,

      
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
