// Define the action types as constants

// Define the productnamereducer to handle the SET_PRODUCT_NAME action
 const Purchase_Mode = (state = false, action) => {
    switch (action.type) {
      case "SET_Purchase_Mode":
        return !state; // Toggle the boolean value
      default:
        return state;
    }
  };

  export default Purchase_Mode;