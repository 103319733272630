import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import { Box, Checkbox, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import InvoiceTable from "./InvoiceTable";
import AddPaymentModal from "./AddPaymentModal";
import Loading_Default from "../../components/Loading_Defualt";

export default function Customer_Invoices_Short({ CustomerId }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [selectedInvoiceObjects, setSelectedInvoiceObjects] = useState<any[]>([]);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  
  useEffect(() => {
    setLoading(true);
    setError("");
    customaxios
      .get("invoice/GetInvoicesByCustomer_short/" + CustomerId)
      .then((response) => {
        console.log(response.data);
        setInvoices(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred while loading the invoices.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [CustomerId]);

  useEffect(() => {
   // console.log(selectedInvoices);
    setSelectedInvoiceObjects(
      invoices.filter((invoice) => selectedInvoices.includes(invoice.invoiceId))
    );
  }, [selectedInvoices, invoices]);

  if (loading) {
    return  <Loading_Default />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoices || invoices.length === 0) {
    return <div>No invoices found.</div>;
  }

  const handleSelected = () => {
    // Open modal with selected invoices
    setSelectedInvoiceObjects(
      invoices.filter((invoice) => selectedInvoices.includes(invoice.invoiceId))
    );
    setPaymentModalOpen(true);
  };

  return (
    <div>
      <p>No of invoices {invoices.length}</p>
      <button
  disabled={!(selectedInvoiceObjects && selectedInvoiceObjects.length > 0)}
  className="button_one"
  onClick={handleSelected}
>
  Add payment for selected
</button>


     <InvoiceTable invoices={invoices} selectedInvoices={selectedInvoices} setSelectedInvoices={setSelectedInvoices} />
     
     <Modal open={paymentModalOpen} onClose={() => setPaymentModalOpen(false)}>
     <AddPaymentModal isOpen={paymentModalOpen} onClose={() => setPaymentModalOpen(false)} invoices={selectedInvoiceObjects} />

</Modal>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {selectedInvoiceObjects.map((invoice) => (
            <TableRow key={invoice.invoiceId}>
              <TableCell>Invoice ID: {invoice.invoice_Id_Simplified}</TableCell>
              <TableCell>Invoice Date: {invoice.invoiceValue}</TableCell>
              <TableCell>Due Date: {invoice.paymentDue}</TableCell>
              <TableCell>Invoice Value: {invoice.invoiceValue}</TableCell>
              <TableCell>Payment Type: {invoice.paymentType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


    </div>
  );

}
