import React from "react";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function InvoiceTable(props) {
  const { invoices, selectedInvoices, setSelectedInvoices } = props;

  return (
 
    <table className="ledger_View_Table">
    <thead>
          <tr className="Ledger_View_Table_Row Title">
            <th >
              <Checkbox
                checked={selectedInvoices.length === invoices.length}
                onChange={(event) =>
                  event.target.checked
                    ? setSelectedInvoices(
                        invoices.map((invoice) => invoice.invoiceId)
                      )
                    : setSelectedInvoices([])
                }
              />
            </th>
            <th>ID</th>
            <th>Due Date</th>
            <th>Value</th>
            <th>Type</th>
            <th>Due Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
          <tr className="Ledger_View_Table_Row" key={invoice.invoiceId}>
              <td >
                <Checkbox
                  checked={selectedInvoices.includes(invoice.invoiceId)}
                  onChange={(event) =>
                    event.target.checked
                      ? setSelectedInvoices([
                          ...selectedInvoices,
                          invoice.invoiceId,
                        ])
                      : setSelectedInvoices(
                          selectedInvoices.filter(
                            (id) => id !== invoice.invoiceId
                          )
                        )
                  }
                />
              </td>
              <td>{invoice.invoice_Id_Simplified}</td>
             
              <td>
                {new Date(invoice.dueDate).toLocaleDateString()}
              </td>
              
              <td>
                {invoice.invoiceValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "LKR",
                })}
              </td>
              <td>{invoice.paymentType}</td>
              <td>
                {invoice.paymentDue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "LKR",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

  );
}
