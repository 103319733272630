import React, { useState, useEffect } from 'react';
import customaxios from '../../../helpers/axios-helper';

export default function SelectPaye({ onPayeSelected }) {
  const [selectedPaye, setSelectedPaye] = useState([]);
  const [Payee, setPayee] = useState([]);

  useEffect(() => {
    customaxios.get('Payee/GetAllPayee')
      .then(response => setPayee(response.data))
      .catch(error => console.log(error));
  }, []);

  const handlePayeChange = (event) => {
    const Paye = event.target.value;
    setSelectedPaye(Paye);
    onPayeSelected(Paye);
  };

  return (
    <div className='selectPaye'>
        <p>Payee:</p>
     <select value={selectedPaye} onChange={handlePayeChange}>
  <option value="">Select Paye...</option>
  {Payee.map((Paye) => (
    <option key={Paye.voucherPayeId} value={Paye.payeeId}>
      {Paye.name}
    </option>
  ))}
</select>

    </div>
  );
}
