import { FormEvent, useState } from "react";
import {useNavigate } from "react-router-dom";
import customaxios from "../../helpers/axios-helper";
import { disableLoad, enableLoad } from "../loader";


export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    
    const navigate = useNavigate();


    const sendEmail = (e: FormEvent) => {
        e.preventDefault();

        enableLoad("We're processing your request...");
        console.log(`${window.location.protocol}//${window.location.host}/reset-password`);

        customaxios.post('auth/sendresetpasswordmail', null, {
            params: {
                email: email,
                url: `${window.location.protocol}//${window.location.host}/reset-password`
            }
        })
        .then((response) => {
            disableLoad();

            const data = response.data;

            if(data === "Success"){
                alert("An email has been sent. Please check your spam folder as well and follow the link within.");
                navigate("/");
            }
            else{
                alert(data);
            }

            setEmail("");
        });
    }
    

    return(
        <section className="forgot">
            <h1>Forgot your password?</h1>

            <div className="forgot-form-wrapper">
                <form className="forgot-form" id="forgot-form" onSubmit={(e) => sendEmail(e)}>
                    <fieldset id="forgot-form">
                        <div className="floating-input">
                            <input type="email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title="Ex: john@zmail.com" id="forgot-email" placeholder=" " required
                            onChange={(e) => setEmail(e.target.value.trim())} />

                            <label htmlFor="forgot-email">Email</label>
                        </div>

                        <div className="stacked-btns">
                            <input type="submit" id="forgot-submit" value='Send email' />
                            <input type="button" id="login-submit" value='Go Back'
                            onClick={() => navigate("/")} />
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    );
}