import CreateGeneralExpense from "./CreateGeneralExpense";
import ListExpenses from "./ListExpenses"
import LedgerView from "../../Ledger/LedgerView";
export default function GeneralExpenses (){


    return(
        <>
      
<h2>General Expenses</h2>
        <CreateGeneralExpense/>
        <br />
        <ListExpenses/>
        </>
    )
}