import React, { useState, useEffect, useRef } from "react";
import { brand } from '../../models/Products/brand';
import { Product } from '../../models/Products/product';
import { useAuthUser } from '../../contexts/auth-context';
import customaxios from "../../helpers/axios-helper";
import "./Style/Style.css";
import NotesList from "./Components/Product_Components/NotesList";
/*interface Product {
  suppliercode: any;
  oaM_ACode: any;
}*/
/*
interface HistoryRow {
  product: Product; 
  total_Amount: any;
}
*/
interface ModalSummaryProps {
  historyRow: any;
  onClose: () => void;
}

export const LineProductSummary: React.FC<ModalSummaryProps> = ({
  historyRow,
  onClose,


}) => {
const auth = useAuthUser();

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const [isSaleHistoryVisible, setIsSaleHistoryVisible] = useState(false);
  const [SaleTrend, setSaleTrend] = useState<any>();
  const handleToggleSaleHistory = () => {
    setIsSaleHistoryVisible(!isSaleHistoryVisible);
  };

  useEffect(() => {
    if (isSaleHistoryVisible) {
      // Code to run when isPurchaseHistoryVisible is true
      customaxios.get(`Stock/GetSalesTrend?productId=${historyRow.product.productId}`)
      .then((res) => {
        console.log(res.data);
        setSaleTrend(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
      console.log("Purchase history is visible!");
    }
  }, [isSaleHistoryVisible]);
  console.log(historyRow)
  return (
    <div className="line-product-summary-modal" onClick={handleBackgroundClick}>
      <div className="line-product-summary-content">
        <div className="Modal_Summary">
<div  className="table_Order_Product_Info">
<table className="table_Order_Product_Info_table">
  <tbody>
    <tr className="table_Order_Product_Info_table_tr">
      <th>Product Name</th>
      <td>: {historyRow.product.productName}</td>
    </tr>
    <tr className="table_Order_Product_Info_table_tr">
      <th>Supplier Code</th>
      <td>: {historyRow.product.suppliercode}</td>
    </tr>
    <tr className="table_Order_Product_Info_table_tr">
      <th>A Code</th>
      <td>: {historyRow.product.oaM_ACode}</td>
    </tr>
    <tr className="table_Order_Product_Info_table_tr">
      <th>Brand</th>
      <td>: {historyRow.product.brand.brand_Name}</td>
    </tr>


    <tr className="table_Order_Product_Info_table_tr">
    {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
      <>
      <th>Supplier</th>
      <td>: {historyRow.product.supplier.supplier_Name} ({historyRow.product.supplier.supplier_Short})</td>
      
      </>
      )}

    </tr>

    {
      /*
      <tr>
      <th>Order Note</th>
      <td>: {historyRow.note? historyRow.note : "" }</td>
    </tr>
      */
    }
    <tr className="table_Order_Product_Info_table_tr">

  
    {
    (auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
      <>
      <th>Last Purchase Price</th>
      <td>: {historyRow.product.productPrice.last_PurchasePrice}</td>
      </>
      )
      }
    </tr>
    <tr className="table_Order_Product_Info_table_tr">
    {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
      <>
      <th>Profitability</th>
      <td>: {((historyRow.total_Amount - (historyRow.quantity * historyRow.product.productPrice.last_PurchasePrice)) / historyRow.total_Amount * 100).toFixed(2)}%</td>
      
      </>
      )}


    </tr>



    <tr className="table_Order_Product_Info_table_tr">
    {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
      <>
      <th>Profitability LKR</th>
      <td>: {(historyRow.total_Amount - (historyRow.quantity * historyRow.product.productPrice.last_PurchasePrice)).toFixed(2) } </td>
      
      </>
      )}


    </tr>

  </tbody>
        </table>
</div>
      


  <div className="notes_List">

  <p>Order Item Notes</p>
  <NotesList notes={historyRow.notes} refId={historyRow.id}/>
 
  </div>

            {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") && (
  <div className="profit">



<button onClick={handleToggleSaleHistory}>
                View Sales Trend
              </button>



              {isSaleHistoryVisible && (
  <div>

    <table id="Product_sales_trend">
  <thead>
    <tr>
      <th>Sale Date</th>
      <th>unitPrice LKR</th>
      <th>Sale Qty</th>
      <th>total_Amount lkr</th>
    </tr>
  </thead>
  <tbody>
    {SaleTrend &&
      SaleTrend.map((Sale) => (
        <tr key={Sale.id}>
          <td>
            {Sale.orderItem.created_Datetime?.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: '2-digit',
            })}
          </td>
          <td>{Sale.orderItem?.unitPrice?.toLocaleString()} </td>
          <td>{Sale.movement?.toLocaleString()}</td>
          <td>{Sale.orderItem.total_Amount?.toLocaleString()}</td>
        </tr>
      ))}
    {!SaleTrend && <tr><td >No Data Available</td></tr>}
  </tbody>
</table>
  </div>
)}



   </div>
)}


      
        </div>
      </div>
    </div>
  );
};
