import React, { useState, useRef } from 'react';
import CategorySelect from "./SelectCategory";

interface CreateVoucherItemProps {
  onAddItem: (item: { voucherAmount: number, voucherDescription: string, selectedCategory: string,note:string }) => void;
}

export default function CreateVoucherItem({ onAddItem }: CreateVoucherItemProps) {
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [voucherDescription, setVoucherDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [note, setNote] = useState("");

  const descriptionInputRef = useRef<HTMLInputElement>(null);

  const handleVoucherAmountChange = (event) => {
    setVoucherAmount(event.target.value);
  };

  const handleVoucherDescriptionChange = (event) => {
    setVoucherDescription(event.target.value);
  };

  const handleCategorySelected = (category: string) => {
    setSelectedCategory(category);
  }

  const handleAddItem = () => {
    onAddItem({ voucherAmount, voucherDescription, selectedCategory,note });
    setVoucherAmount(0);
    setVoucherDescription('');
    setSelectedCategory('');
    if (descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddItem();
    }
  };

  return (
    <div className='Add_itemVoucher'>
      <input
        type="text"
        placeholder="Voucher Description"
        value={voucherDescription}
        onChange={handleVoucherDescriptionChange}
        ref={descriptionInputRef}
      />
      <input
        type="number"
        placeholder="Voucher Amount"
        value={voucherAmount}
        onChange={handleVoucherAmountChange}
        onKeyPress={handleKeyPress}
      />
      <CategorySelect onCategorySelected={handleCategorySelected} />
      <button className='button_one' onClick={handleAddItem}>Add Item</button>
    </div>
  );
}
