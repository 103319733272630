import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import customaxios from "../../helpers/axios-helper";
import { disableLoad, enableLoad } from "../loader";

import CheckPasswordRequirements from "../password-requirements";


export default function ResetPassword() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetToken, setResetToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    
    const navigate = useNavigate();


    useEffect(() => {
        console.log(searchParams.get("token"));
        console.log(searchParams.get("email"));
        setResetToken(searchParams.get("token"));
        setEmail(searchParams.get("email"));
    }, [searchParams])
    

    const resetPassword = (e: FormEvent) => {
        e.preventDefault();

        enableLoad("We're updating your password...");

        customaxios.post('users/resetpassword', null, {
            params: {
                token: resetToken,
                email: email,
                newPassword: password
            }
        })
        .then((response) => {
            disableLoad();

            const data = response.data;

            if(data === "Success"){
                alert("Your password has been updated!");
                navigate("/");
            }
            else{
                alert(data);
            }

            setEmail("");
        });
    }
    

    return(
        <section className="reset">
            <h1>Reset password</h1>

            <div className="reset-form-wrapper">
                <form className="reset-form" id="reset-form" onSubmit={(e) => resetPassword(e)}>
                    <fieldset id="reset-form">
                        <CheckPasswordRequirements
                        checkPasswordMatch={false}
                        setIsPasswordValid={setIsPasswordValid}
                        setPassword={setPassword} />

                        <div className="stacked-btns">
                            <input type="submit" id="reset-submit" value='Update Password' />
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    );
}