import React, { useState, useEffect,useCallback,useMemo } from 'react';
import customaxios from '../../../helpers/axios-helper';
import { makeStyles } from '@material-ui/core/styles';
import View_Vouchers_row_Head from './View_Vouchers_row_Head';

import { TablePagination } from '@material-ui/core';

import View_Vouchers_row from './View_Vouchers_row';

const useStyles = makeStyles({
  root: {
    borderBottom: '2px solid',
    borderColor: theme => "Blue", // replace with your desired color
    '&$selected': {
      backgroundColor: theme => "Green", // replace with your desired color
    },
  },
  selected: {},
  table: {
    minWidth: 650,
  },
});

export default function VoucherInfo() {
  const classes = useStyles();
  const [vouchers, setVouchers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalVouchers, setTotalVouchers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pagedVouchers, setPagedVouchers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  const fetchData = useCallback(async (pageNumber, pageSize, search) => {
    try {
      let response;
      if (search) {
        response = await customaxios.post(`/Voucher/Search_voucher?keyword=${search}`, {
          pageNumber,
          pageSize,
        });
      } else {
        response = await customaxios.get(`Voucher/GetAllVouchers?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      }
      setVouchers(response.data.vouchers);
      console.log("response.data.vouchers");
      console.log(response.data.vouchers);

      setTotalVouchers(response.data.totalVouchers);
      setTotalPages(response.data.totalPages);
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, response.data.vouchers.length);
      setPagedVouchers(response.data.vouchers);

    } catch (error) {
      console.log(error);
    }
  }, []);
  
  useEffect(() => {
    fetchData(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm, fetchData]);
  
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };
  
  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };
  
  return (
    <>
{
  /*
  
      <input type="text" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />*/
}


<div className="voucherView">
<View_Vouchers_row_Head/>

{pagedVouchers.map((voucher) => (
        <View_Vouchers_row voucher={voucher}/>
          ))}

<TablePagination
className='pagination-bar'
        component="div"
        count={totalVouchers}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage="Results per page:"
        onRowsPerPageChange={handleRowsPerPageChange}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} (page ${currentPage} of ${totalPages})`}
      />
</div>

      
     
    </>
  );
}
