
import React, { useState, useEffect } from "react";
import customAxios from "../../helpers/axios-helper";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { CheckCircle } from '@mui/icons-material';

interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function Town({ id: routeId }: { id: string | null }){
  const { id: paramId } = useParams<RouteParams>();
    const id = paramId ? paramId : routeId;

    console.log(id)
      const [Customers, setCustomers] = useState([]);

  useEffect(() => {
    // Fetch the specific route with towns
    //customAxios.get(`/CustomerRoute/GetRoute?id=${routeId}`).then((response) => {
    customAxios.get(`/CustomerRoute/GetTownCustomers/${id}`).then((response) => {
        setCustomers(response.data);
        console.log(response.data)
    });
  }, [id]);

    return(
        <>
        Town {id}

customer count {Customers.length}



          <table className="CustomerList_Table">
            <thead>
              <tr className="Customer_Row Title">
                <th className="CustomerName">Customer</th>
             
                <th  className="Town">Town</th>
                <th>Sales rep</th>
              </tr>
            </thead>
            <tbody>
              {Customers.map((customer: any) => (
              <>
              <tr
                className="Customer_Row"
                >
                  
                  <td className="CustomerName">
                  <a  href={`/CustomerInformation/${customer.customerId}`}>
  {customer.verified_Customer ? (
    <span dangerouslySetInnerHTML={{ __html: "&#10004;" }} style={{ color: "green" }} />
  ) : null}
  {customer.customerName}
</a>

 </td>
            
             
                  <td className="Town">
        <a  href={`/Town/${customer.town?.townId}`}>
        {customer.town?.name}
    </a>
                  </td>
                  <td className="route_salesrep">
                   {customer.town?.route?.salesrep}
                  </td>
                </tr>
              </>
              ))}
            </tbody>
            
          </table>
        {/*Customers.map((customer)=> (
                <tr key={customer.customerid}>

                </tr>
            ))*/
        }
        </>
    )
}