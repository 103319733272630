import { useNavigate } from 'react-router-dom';

import { useAuthUpdate } from '../contexts/auth-context';
import { disableLoad, enableLoad } from '../components/loader';
import { UserLogin } from '../models/users/user-login';
import { removeCookie } from '../helpers/cookie-helper';
import { clearLSOnLogout, getAuthUserFromLS } from '../helpers/storage-helper';
import customaxios from '../helpers/axios-helper';

export const ProcessLogout = (e, toggleAuthFunction, navigateFunction) => {
  e?.preventDefault();
  
  const authUser = getAuthUserFromLS();

  const user: UserLogin = {
    email: authUser.email, 
    password: '',
    location: 'web'
  };

  console.log(user);

  enableLoad("Logging you out...");

  customaxios.post('auth/logout', user)
  .then((response) => {
    console.log(response);
    const data = response.data;

    disableLoad();

    if(data) {
      if(data === "Success") {
        alert("You have been logged out");

        removeCookie("JWT", new Date(sessionStorage.getItem('expiry')));

        toggleAuthFunction();

        clearLSOnLogout();

        navigateFunction('/');
      }
      else{
        alert('Something went wrong.');
      }
    }
    else{
      alert('Something went wrong.');
    }
  })
}

export default function Logout() {
  const toggleAuth = useAuthUpdate();
  const navigate = useNavigate();
  
    return (
        <a href='' onClick={(e) => ProcessLogout(e, toggleAuth, navigate)}>Logout</a>
    ); 
}