import React, { useEffect } from "react";
import customaxios from "../helpers/axios-helper";
import TablePagination from "@mui/material/TablePagination";
import Loading_Defualt from "../components/Loading_Defualt";
import OrderList_V2 from "./OrderList_V2";
import { useParams } from 'react-router-dom';
import './Style_sales_order_V2/Style.css'; 
import SalesOrderDate from "./SalesOrderDate";
import Sales_order_information from "./Sales_order_information";
import {  useAuth,useAuthUser } from '../contexts/auth-context';
interface RouteParams {
  id: string;
  [key: string]: string;
}
export default function Sales_order_v2({ id: routeId }: { id: string | null }) {
  const { id: paramId } = useParams<RouteParams>();
  const id = paramId ? paramId : routeId;
 
  const isAuth = useAuth();
  const Authuser = useAuthUser();

    const [salesOrders, setSalesOrders] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [selectedOrder, setSelectedOrder] = React.useState<string | null>(null);
    useEffect(() => {
        if (salesOrders.items && salesOrders.items.length > 0) {
          setSelectedOrder(salesOrders.items[0].salesOrderId);
        }
      }, [salesOrders]);
    const handleOrderClick = (salesOrderId: string) => {
        setSelectedOrder(salesOrderId);
      };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };
    useEffect(() => {
      let url = `/SalesOrder/GetSalesOrder_Summery?pageIndex=${page + 1}&pageSize=${rowsPerPage}` + (id ? (`&salesRepId=`+id) :("")) ;
    
      if (Authuser.role == "Sales_Rep") {
        url += `&salesRepId=${Authuser.id}`;
      }
      console.log("url",url);
    
      customaxios.get(url)
        .then((res) => {
          console.log(res.data);
          setSalesOrders(res.data);
          setIsLoading(false);
        });
    }, [page, rowsPerPage, isAuth]);
    

    return (
        
        <div className="SalesOrder_V2_dashboard">
  



  <div className="OrderList">
 
  <div className="orderlist_Pagination">
    <TablePagination
            rowsPerPageOptions={[5,10, 50,100]}
            component="div"
            count={salesOrders.totalCount ? salesOrders.totalCount : 0}
            rowsPerPage={rowsPerPage ? rowsPerPage : 25}
            page={page ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
  {isLoading ? (
      <Loading_Defualt/>
        ) : (

                 <OrderList_V2 salesOrders={salesOrders} selectedOrder={selectedOrder} handleOrderClick={handleOrderClick}/>
        )}
  

  </div>

 {
  /* <div className="OrderDetail">
    <Sales_order_information id={selectedOrder}/>

    
  </div> */
 }
</div>
    )
}