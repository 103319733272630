import React from "react";
import "./Style/Style.css";

interface OutStockTableProps {
  stockLevels: any[];
}

const OutStockTable: React.FC<OutStockTableProps> = ({ stockLevels }) => {
  const renderStockLevels = () => {
    const filteredLevels = stockLevels.filter((level) => level.type === "out");

    if (filteredLevels.length === 0) {
      return (
        <tr>
          <td colSpan={4}>No Out stock levels found.</td>
        </tr>
      );
    }

    return filteredLevels.map((level) => (
      <tr   className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={level.stocklevelsId}>
<td>{level.levelDateTime.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
<td><a href={`/OrderPage/${level.orderItem.order.orderId}`}>{level.orderItem.order.orderId}</a></td>

<td><a href={`/CustomerInformation/${level.orderItem.order.customer?.customerId}`}>{level.orderItem.order.customer?.customerName}</a></td>


<td>{level.orderItem.listPrice != null ? level.orderItem.listPrice.toLocaleString('en-GB', { maximumFractionDigits: 2 }) : ''}</td>
<td>{level.movement}</td>
<td>{level.orderItem.amount != null ? level.orderItem.amount.toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'GBP' }) : ''}</td>


      </tr>
    ));
  };

  return (
    <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
     <tr >
          <th>Date</th>
          <th>Order</th>
          <th>Customer</th>
          <th>list Price</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>{renderStockLevels()}</tbody>
    </table>
  );
};

export default OutStockTable;
