import React from "react";
import "./Style/Style.css";
interface InStockTableProps {
  stockLevels: any[];
}

const RTNStockTable: React.FC<InStockTableProps> = ({ stockLevels }) => {
  const renderStockLevels = () => {
    const filteredLevels = stockLevels.filter((level) => level.type === "RTN");

    if (filteredLevels.length === 0) {
      return (
        <tr>
          <td colSpan={9}>No In stock levels found.</td>
        </tr>
      );
    }

    return filteredLevels.map((level) => (
      <tr  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={level.stocklevelsId}>
<td>{level.levelDateTime.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>

        <td>
          <a href={`/grn/${level.rtnItem?.product_Return_ItemId}`}>
            Rtn {level.rtnItem?.product_Return_ItemId}
          </a>
        </td>
        <td>{level.rtnItem?.product_Return.partyname }</td>
        <td>{level.rtnItem?.price.toLocaleString() }</td>
        <td>{level.movement}{" "+level.rtnItem.qty_unit}</td>
        <td>{level.rtnItem?.total.toLocaleString() }</td>
      </tr>
    ));
  };

  return (
    <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
   <tr >
          <th>Date</th>
          <th>Movement ID</th>
          <th>Customer</th>
          <th>Unit Price</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>{renderStockLevels()}</tbody>
    </table>
  );
};

export default RTNStockTable;
