import customaxios from "../../../helpers/axios-helper" 
import React, { useEffect, useState } from "react"
import LedgerView from "../../Ledger/LedgerView";
import { Tab, Tabs } from "@mui/material";
import Account from "../../Account/Account";

export default function ListFinanceExpenses() {
    const [expenses, setExpenses] = useState<any[]>([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedTab, setSelectedTab] = useState<string | null>('all');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

       // Group expenses by company_Name
  const groupedExpenses: { [key: string]: any[] } = {};
  expenses.forEach((expense) => {
    const company = expense.company_Name || "N/A"; // Use "N/A" for null values
    if (!groupedExpenses[company]) {
      groupedExpenses[company] = [];
    }
    groupedExpenses[company].push(expense);
  });
    useEffect(() => {
      fetchExpenses();
    }, []);
  
    const fetchExpenses = async () => {
      try {
        const response = await customaxios.get("DirectorRemuneration/GetAllExpenses");
        setExpenses(response.data);
      } catch (error) {
        console.error("Error fetching expenses:", error);
        // Optionally, you can handle errors here, e.g., showing an error message to the user.
      }
    };
    const handleNameClick = (asset) => {
        setSelectedAsset(asset);
        console.log(asset)
      };
    return (
      <>
          <Account open={selectedAsset !== null} onClose={() => setSelectedAsset(null)} id={selectedAsset?.account_.account_Id
}/>        <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        centered
      >
          <Tab key="all" label="All" value="all" />
        {Object.keys(groupedExpenses).map((companyName) => (
          <Tab key={companyName} label={companyName} value={companyName} />
        ))}
      </Tabs>

 {/* Render expenses for the selected tab */}
 <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr >
            <th>Name</th>
            <th>Description</th>
            <th>company Name</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {selectedTab === "all"
            ? expenses.map((expense) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>  
              
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"  onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.description}</td>
                  <td>{expense.company_Name || "N/A"}</td>    
                         <td>{expense.account_.balance || "N/A"}</td>
                </tr>
              ))
            : groupedExpenses[selectedTab]?.map((expense) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>  
              
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"  onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.description}</td>
                  <td>{expense.company_Name || "N/A"}</td>    
                         <td>{expense.account_.balance || "N/A"}</td>
                </tr>
              ))}
        </tbody>
      </table>
      </>
    );
  }
  