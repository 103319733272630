import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function ProductHistoryTabs({ productStats }) {

  const [tabValue, setTabValue] = useState(0);
  const purchaseHistory = productStats.purchaseTrend.filter(purchase => purchase.type === 'In');
  const salesHistory = productStats.purchaseTrend.filter(purchase => purchase.type === 'out');
console.log(salesHistory)
if (!productStats) {
    return <p>No history data available.</p>;
  }
  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        aria-label="Purchase and Sales History Tabs"
      >
        <Tab label={`Purchase History (${purchaseHistory.length})`} />
  <Tab label={`Sales History (${salesHistory.length})`} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {purchaseHistory.length > 0 ? (
          <div className="purchase-history">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>USD</th>
                  <th>Unit LKR</th>
                  <th>Qty</th>
                  <th>Total LKR</th>
                </tr>
              </thead>
              <tbody>
                {purchaseHistory.map((purchase) => (
                  <tr key={purchase.id}>
                    <td>
                      {new Date(purchase.grnItem.created_Datetime).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                      })}
                    </td>


                    <td>
                    {purchase.grnItem.price_Usd != null
    ? formatCurrency(purchase.grnItem.price_Usd, 'USD', 2)
    : ''}
                    </td>
                    <td>{purchase.grnItem.price_mainUnit.toLocaleString()}</td>
                    <td>{purchase.movement.toLocaleString()}</td>
                    <td>
                        
                    {purchase.grnItem.amount != null
    ? formatCurrency(purchase.grnItem.amount, 'LKR', 2)
    : ''}
                </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No purchase history available.</p>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {salesHistory.length > 0 ? (
          <div className="sales-history">
            <table>
            <thead>
            <tr>
          <th>Date</th>
          <th>Order</th>
          <th>Customer</th>
          <th>List Price</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
              </thead>
              <tbody>
                {salesHistory.map((level) => (
                   <tr key={level.stocklevelsId}>
                   <td>{level.levelDateTime.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                   <td><a href={`/OrderPage/${level.orderItem.order.orderId}`}>{level.orderItem.order.orderId}</a></td>
                   
                   <td><a href={`/CustomerInformation/${level.orderItem.order.customer?.customerId}`}>{level.orderItem.order.customer?.customerName}</a></td>
                   
                   
                   <td>
  {level.orderItem.listPrice != null
    ?
    formatCurrency(level.orderItem.listPrice, 'LKR', 2)
    : ''}   
</td>
   <td>{level.movement}</td>
                   <td>
  {level.orderItem.amount != null
    ? formatCurrency(level.orderItem.amount, 'LKR', 2)
    : ''}
</td>

                   
                         </tr>
                ))}
              </tbody>
              {/* ... your table structure for sales history */}
            </table>
          </div>
        ) : (
          <p>No sales history available.</p>
        )}
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default ProductHistoryTabs;


function formatCurrency(amount, currency, decimalDigits) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
    }).format(amount);
  }