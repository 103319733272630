import React from 'react';
import { CartItem, useCart } from './CartProvider';

import {  useAuthUser ,useAuth} from '../../contexts/auth-context';
import { useNavigate } from 'react-router-dom';
import CartRow from './Cartrow';
import Switch from '@mui/material/Switch';


import SelectedCustomer from '../Customer/SelectedCustomer';
import SelectedSales_rep from '../Sales_rep/SelectedSales_rep';
import 'react-toastify/dist/ReactToastify.css';

interface GroupedCartItems {
  [key: string]: {
    items: CartItem[];
    subtotal: number;
    paymentModeGroups: {
      [key: string]: {
        items: CartItem[];
      };
    };
  };
}

const Cart: React.FC = () => {
const { 
  cartItems, 
  removeFromCart, 
  clearCart, 
   getTotalItemsInCart,
   SubmitOrder,
   getTotal_ValueInCart,
   urgent, toggleUrgent 
} = useCart();






//console.log(cartItems)
const AuthUser = useAuthUser();
const isAuth = useAuth();

const navigate = useNavigate();
if (!isAuth) {
  // Redirect to the login page if not verified
  navigate('/');
}

// Replace 'role1', 'role2', etc., with the actual roles you want to check
const allowedRoles =['Director','Developer','Praveen','Sales_Rep','Billing_Agent'] ;

// Check if the user has at least one of the allowed roles
const isVerified = allowedRoles.some(role => AuthUser.role === role);

if (!isVerified) {
  // Redirect to the login page if not verified
  navigate('/');
}
const handleSubmitOrder = () => {
  if (window.confirm('Are you sure you want to place your order?')) {
    SubmitOrder();
  }
};
  const handleRemoveItem = (itemId: number) => {
    removeFromCart(itemId);
  };

  const handleClearCart = () => {
    if (window.confirm('Are you sure you want to clear your cart?')) {
      clearCart();
    }
  };
console.log(cartItems)
  const groupedCartItems: GroupedCartItems = cartItems.reduce((groups, item) => {
    const groupKey = item.Product.billing_Group_Contained.name;
    const paymentMode = item.PaymentMode;
    const subtotal =  item.Total_Amount;
   //const subtotal =  item.Product.productPrice.price * item.Quantity * (1 - (item.Discount_Percentage / 100));
  
    if (!groups[groupKey]) {
      groups[groupKey] = { paymentModeGroups: {} , subtotal: 0 };
    }
  
    if (!groups[groupKey].paymentModeGroups[paymentMode]) {
      groups[groupKey].paymentModeGroups[paymentMode] = { items: [], subtotal: 0 };
    }
  
    groups[groupKey].paymentModeGroups[paymentMode].items.push(item);
    groups[groupKey].paymentModeGroups[paymentMode].subtotal += subtotal;
    groups[groupKey].subtotal += subtotal;
  
    return groups;
  }, {});
  


  return (
<div>
 {/* <Search/>
 */}
<div className='CartPage'>
{/* <h2>Your Cart Value : {cartItems.reduce((acc, item) => acc + item.UnitPrice * item.Quantity * (1- (item.Discount_Percentage/100)), 0).toFixed(2)} LKR</h2>
     */}
      {cartItems.length === 0 && (
  <>
   <div className="Cartemptymessage">
   <p>Your cart is empty.</p>

   </div>
  </>
)}


       <p className='totalItemsincart'>Items in Cart: {getTotalItemsInCart()} 

      
       </p>
       <div className="Urgent">
  <table>
    <tr><td>
    <p>Urgent: </p> </td>
    <td>
    <Switch
        checked={urgent}
        onChange={toggleUrgent}
        color="primary"
        inputProps={{ 'aria-label': 'toggle urgent status' }}
      />  
      </td></tr>
  </table>
     
  
    </div>
      <SelectedCustomer/>
      {AuthUser.role === "Sales_Rep" ? null : <SelectedSales_rep />}

 
     
      {cartItems.length > 0 && (

        
     <>
     
     <div className='CartItems'> 
   
           

          

      {Object.entries(groupedCartItems).map(([groupKey, group]) => (
  <div className='billing_group' key={groupKey}>
       
    <h2>{groupKey} : {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(group.subtotal)} LKR</h2>
    {Object.entries(group.paymentModeGroups).map(([paymentMode, paymentModeGroup]) => (
      <div key={paymentMode}>
        <h3>Payment Mode : {paymentMode}</h3>
        {Object.entries(
          paymentModeGroup.items.reduce((groups: any, item: CartItem) => {
            const creditPeriod = item.CreditPeriod || "Cash";
            if (!groups[creditPeriod]) {
              groups[creditPeriod] = { items: [], subtotal: 0 };
            }
           const subtotal = item.Total_Amount;
       /*     const subtotal =
              item.Product.productPrice.price *
              item.Quantity *
              (1 - item.Discount_Percentage / 100);*/
            groups[creditPeriod].items.push(item);
            groups[creditPeriod].subtotal += subtotal;
            return groups;
          }, {})
        ).map(([creditPeriod, creditPeriodGroup]) => (
          <div key={creditPeriod}>
         <h4>{paymentMode === "cash" ? "Immediate Payment" : `Credit Period for: ${creditPeriod} Days`} {new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format((creditPeriodGroup as { items: CartItem[]; subtotal: number; }).subtotal)}{" "}
              LKR</h4>
        

        {/* <p>
              Subtotal : {" "}
              {new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format((creditPeriodGroup as { items: CartItem[]; subtotal: number; }).subtotal)}{" "}
              LKR
            </p>*/}


<br />

<table className='w-full text-sm text-left rtl:text-right  dark:text-gray-400'>
        <thead  className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
        
            <th>Product</th>
            <th>Brand</th>
            <th>QTY  </th>
            <th>List Price</th>
            <th >Discount</th>
            <th >Nett</th>
            <th >Sub Total</th>
            <th ></th>


          </tr>
        </thead>
        <tbody>
        {
    
    (creditPeriodGroup as { items: CartItem[]; subtotal: number; }).items.map((item) => (
      <CartRow key={item.Product.productId} cartItem={item} />
    ))
  
  }

        </tbody>

      </table>
  


           
          </div>
        ))}
      </div>
    ))}
  </div>
))}





     </div>
     
     </>
     
     )}
     {cartItems.length > 0 && (

<div className="cartbottom">
<div className="carttotal">
{/*

<p>Grand Total ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cartItems.reduce((acc, item) => acc + item.UnitPrice * item.Quantity * (1 - (item.Discount_Percentage / 100)), 0))}</p>

*/}


  <p>Grand Total: {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cartItems.reduce((total, item) => total + item.Total_Amount, 0))} LKR</p>
  
</div>
<div className="placeorder">
<button className='button_one' onClick={handleSubmitOrder}>Place Order</button>
</div>
<div className="clearcart">
<button className='button_one danger' onClick={handleClearCart}>Clear Cart</button>
</div>
</div>
     )}
   
    </div>
</div>
  );

  
};

export default Cart;
