import { Routes  } from "react-router-dom";

import './styles/styles.css';
import { Route, Navigate } from "react-router-dom";
import Header from "./components/Header/header";
import Loader from "./components/loader";
import React, { useState } from 'react';
import Register from "./components/pages/register";
import Login from "./components/pages/login";
import { AuthProvider,useAuthUser } from "./contexts/auth-context";
import { useEffect,useContext } from "react";
import Invoice_Controller from "./components/Invoice/Invoice_Controller";
import UserManagement from "./components/UserManagement/UserManagement";
import 'chartjs-adapter-date-fns';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
import ResetPassword from "./components/pages/reset-password";
import ForgotPassword from "./components/pages/forgot-password";
import Categories from "./Product/Category";
import Search from "./components/Product/Search/Search";
import ProductPage from "./Product/ProductPage";
import ProductListbygroup from "./Product/ProductListbygroup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customers from "./Customers/CustomerList";
import CustomerInformation from "./Customer/CustomerInformation";

import Customerorders from "./Customers/Customerorders";
import Productstocklevel from "./Product/Productstocklevel";
import ProductListbybrand from "./Product/ProductListbybrand";
import ProductsByCategory from "./Product/ProductsByCategory";
import ProductsByBrand from "./Product/ProductsByBrand";
import Brands from "./Product/Brands";
import { CartProvider  } from './components/Cart/CartProvider';
import Cart from "./components/Cart/Cart";
import OrderPage from "./components/Order/OrderPage";
import SalesOrder from "./components/Order/SalesOrder";
import Suppliers from "./components/Suppliers/SuppliersPage";
import Supplier from "./components/Suppliers/Supplier";
import Product from "./components/Product/Product";
import ProductImage from "./components/Product/ProductImage";

import Create_Payment from "./components/Order/payment/Create_Payment";
import Payments from "./components/Order/payment/Payments";
import PaymentPage from "./components/Order/payment/PaymentPage";
import Home from "./Home/Home";
import Twofactor from "./components/pages/2Fa";
import SalesOrderDashboard from "./Sales_order/Sales_order";

/*
import BrandDashboard from "./components/Product/Brand/Brands";
import CategoriesDashboard from "./components/Product/Category/Categories";
import SupplierDashboard from "./components/Product/Supplier/Suppliers";
*/
import CustomerDashboard from "./Customer/Customer";
import Category_GridView from "./components/Product/Collection/Category/Category_GridView";
import Model_GridView from "./components/Product/Collection/Models/Model_GridView";
import Brands_GridView from "./components/Product/Collection/Brand/Brands_GridView";
//vouchers

import Create_Voucher from "./components/Payment/Voucher/Create_Voucher";
import Voucher from "./components/Payment/Voucher/Voucher";
import VoucherInfo from "./components/Payment/Voucher/View_Vouchers";
import VoucherDashboard from "./components/Payment/Voucher/VoucherDashboard";
import VoucherByPayee from "./components/Payment/Voucher/VoucherByPayee/VoucherByPayee";
import Payment_Category from "./components/Payment/Voucher/VoucherByCategory/Payment_Category";

import Sales_Order from "./components/Billing/Sales_order/Sales_Order";
import Order from "./components/Billing/Order/Order";
import Export_PriceList from "./components/Controls/Export_Data_Feature/Export_PriceList";
import Suppliers_GridView from "./components/Product/Collection/Supplier/Suppliers_GridView";
import Arch_Page from "./components/Product/Arch/Arch_Page/Arch_Page";
//puchers

//cheques
import Cheque_list from "./Administration/Cheque/ChequeList";
import { ThemeContext, Theme } from './components/Controls/Theme/ThemeContext';
import Sales_order_information from './Sales_order/Sales_order_information';

import Customer_Route from "./components/Customer_Routes/Route";
import Customer_Routes from "./components/Customer_Routes/Customer_Routes";
import Town from "./components/Customer_Routes/Town";
import { PosProvider } from "./components/POS/PosProvider";
import {PurchaseProvider} from "./components/Product_Purchase/Product_Purchase_Provider"
import "./components/WheelComponent/Wheel.css";
import Admin from "./Administration/Admin";
import Pending_Products from "./components/Product/Pending_Products/Pending_Products";
import Product_Purchase from "./components/Product_Purchase/Product_Purchase";
import LocationComponent from "./LocationComponent ";

import Sales_order_v2 from "./Sales_order/Sales_order_v2";
import Customer_Dash_V2 from "./Customer/Customer_Dash_V2";
import Collection from "./components/Product/Collection/Collection";
import Sales_Rep from "./components/Sales_rep/Sales_Rep";
import List_Sales_reps from "./components/Sales_rep/List_Sales_reps";
import Pos from "./components/POS/Pos";
import { Three_DCartProvider } from "./components/3D_Cart/Three_DCartProvider";

import ChatComponent from "./Chat/ChatComponent";


import Purchase_List_Page from "./components/Product_Purchase/Purchase_Dashboard/Purchase_List_Page";
import Purchase_Dashboard from "./components/Product_Purchase/Purchase_Dashboard/Purchase_Dashboard";
import Purchase_Order_Page from "./components/Product_Purchase/Purchase_Dashboard/Purchase_Order_Page";
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    Chart.register(CategoryScale);
  }, []);

  const AuthUser = useAuthUser();

  return(
    <GoogleOAuthProvider clientId="83176009116-qd2pgeb1sg2osqhqdn2s1bhkrg76k2g7.apps.googleusercontent.com">
    <AuthProvider>
   <Three_DCartProvider>
    <CartProvider>
<PurchaseProvider > 
    <PosProvider>

<ToastContainer />

     {/* <Header/> */}
    <Header/> 
    
  {/*  <Search />*/} 
      <div  className="dark:bg-gray-800">
        <Routes>

{/*vouchers*/}

      <Route path="/Invoice_Controller/:id" element={<Invoice_Controller id={null} />} />
      <Route path="/Sales_Rep/:id" element={<Sales_Rep id={null} />} />
      <Route path="/Route/:id" element={<Customer_Route id={null} />} />
      <Route path="/Town/:id" element={<Town id={null} />} />



      <Route path="/chat/" element={<ChatComponent />} />
      <Route path="/Customer_Routes/" element={<Customer_Routes />} />
      <Route path="/List_Sales_Reps/" element={<List_Sales_reps />} />
      <Route path="/Product_Purchase/" element={<Product_Purchase />} />
      <Route path="/Pos/" element={<Pos />} />
      <Route path="/Accounts/" element={<Admin />} />
      <Route path="/products/" element={<Collection />} />
      <Route path="/Customer_Dash_V2/" element={<Customer_Dash_V2 />} />
     
      <Route path="/Home/" element={<Home />} />

      <Route path="/Location/" element={<LocationComponent/>} />
      
      
      <Route path="/payee/:id" element={<VoucherByPayee />} />
          <Route path="/Category/:id" element={<Payment_Category />} />
          <Route path="/dashboard/voucher" element={<VoucherDashboard />} />
          <Route path="/Voucher/:id" element={<Voucher />} />
          <Route path="/Vouchers" element={<VoucherInfo />} />
          <Route path="/Voucher" element={<Create_Voucher />} />
          <Route path="/2fa" element={<Twofactor />} />
          <Route path="/Pending_Products" element={<Pending_Products />} />
{/*vouchers*/}


        <Route path="/Customer" element={<CustomerDashboard />} />
{
  /*
        <ProtectedRoute path="/Home" element={<Home />} />
      <Route path="/o/:id" element={<Order o={null} />} />
      <Route path="/Sales_Order/" element={<Sales_order_v2 id={null}/>} />
  
        <Route path="/Brand" element={<BrandDashboard/>} />
        <Route path="/Supplier" element={<SupplierDashboard/>} />
        <Route path="/Categories" element={<CategoriesDashboard/>} />
  */
}
        <Route path="/P" element={<Purchase_Dashboard/>} />
        <Route path="/PO/:id" element={<Purchase_Order_Page id={null}/>} />
        <Route path="/P/:id" element={<Purchase_List_Page id={null}/>} />

        <Route path="/C" element={<Category_GridView/>} />
        <Route path="/M" element={<Model_GridView/>} />
        <Route path="/B" element={<Brands_GridView/>} />
        <Route path="/S" element={<Suppliers_GridView/>} />

        <Route path="/U" element={<UserManagement/>} />


          <Route path="/Payment/:id" element={<PaymentPage/>} />


          <Route path="/ProductImage/:productid" element={<ProductImage/>} />
          <Route path="/Product/:productid" element={<Product id={null}/>} />
          <Route path="/Supplier/:id" element={<Supplier />} />
          <Route path="/Suppliers" element={<Suppliers />} />

          <Route path="/Customers" element={<Customers />} />
          <Route path="/SalesOrder_v2/:id" element={<Sales_order_information id={null} />} />

         {
          /*
      <Route path="/so/:id" element={<Sales_Order id={null} />} />

        <Route path="/SalesOrderDashboard" element={<SalesOrderDashboard/>} />
          <Route path="/Customerorders" element={<Customerorders />} />
          <Route path="/OrderPage/:id" element={<OrderPage />} />
          <Route path="/SalesOrder/:id" element={<SalesOrder/>} />
          <Route path="/Productstocklevel" element={<Productstocklevel />} />

          */
         }
          <Route path="/CustomerInformation/:id" element={<CustomerInformation id={null} />} />


          <Route path="/Arch/:id" element={<Arch_Page id={null} />} />

          <Route path="/Cart" element={<Cart />} />
          <Route path="/ProductsByBrand/:id" element={<ProductsByBrand />} />
          <Route path="/ProductsByCategory/:id" element={<ProductsByCategory />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/ProductListbybrand" element={<ProductListbybrand />} />
          <Route path="/Search" element={<Search />} />
          <Route path="/ProductPage" element={<ProductPage />} />
          <Route path="/ProductListbygroup" element={<ProductListbygroup />} />
          <Route path="/Categories_" element={<Categories />} />

          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>
      
     

      <Loader />

    </PosProvider>
   </PurchaseProvider>
   </CartProvider>
   </Three_DCartProvider>
    </AuthProvider>
    </GoogleOAuthProvider>
  )
}



function ProtectedRoute({ path, element, requiredRoles }) {
  const AuthUser = useAuthUser();

  if (!AuthUser) {
    return <Navigate to="/login" />; // Redirect to the login page if not authenticated
  }

  // Check if the user has any of the required roles
  const hasRequiredRole = requiredRoles.some(role => AuthUser.role === role);

  if (!hasRequiredRole) {
    // Redirect to a permission-denied page or another appropriate page
    return <Navigate to="/permission-denied" />;
  }

  return <Route path={path} element={element} />;
}