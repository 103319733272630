import React, { createContext, useContext, useEffect, useState } from 'react';
import {Product} from '../../models/Products/product';
import { Customer } from '../../models/Customer/Customer';
import { Salesrep } from '../../models/Sale_Rep/Salesrep';
import {  useAuth,useAuthUser } from '../../contexts/auth-context';
import customaxios from '../../helpers/axios-helper';
import { toast } from 'react-toastify';
 export interface CartItem {
 // Order?: Order;
 Purchase_Order_ItemId?: number;
  id?: number;
  name: string;
  UnitPrice: number;
  Quantity: number;
  Product : Product;
  Discount_Percentage: number;
  Total_Amount: number;
  Note:string;
  PaymentMode:string;
  CreditPeriod:string;
  Nett_Price:number;
}
export interface cart {
  PurchaseItems: CartItem[];
  customer: any;
  Sales_rep: any;

}

interface Product_Purchase_ProviderContextValue {
  customer: Customer;
  salesrep: Salesrep;

  PurchaseItems: CartItem[];
 // updateCustomer: (customer: Customer) => void;
 SubmitOrder: () => void;
  AddSalesrep: (salesrep: Salesrep) => void;

  AddCustomer: (customer: Customer) => void;
  addToPurchase: (item: CartItem) => void;
  removeFromCart: (itemId: number) => void;
  updateCartItemNote: (itemId: number,note:string) => void;
  updateCartItemQuantity: (itemId: number, Quantity: number) => void;
  updateCartItemDiscount: (itemId: number, Discount_Percentage: number) => void;
  updateCartItem: (itemId: number, updatedItem: CartItem) => void;
  clearCart: () => void;
  getTotalItemsInCart: () => number;
  getTotal_ValueInCart: () => number;
}

const Purchase_ProviderContext = createContext<Product_Purchase_ProviderContextValue>({
  customer: {} as Customer,
 /*updateCustomer: () => {},
 */ 
 SubmitOrder: () => {},
 AddSalesrep: () => {},
 AddCustomer: () => {},
 salesrep: {} as Salesrep,

 PurchaseItems: [],
  addToPurchase: () => {},
  updateCartItemNote: () => {},
  updateCartItemQuantity: () => {},
  updateCartItemDiscount: () => {},
  updateCartItem: () => {},
  removeFromCart: () => {},
  getTotalItemsInCart: () => 0,
  getTotal_ValueInCart: () => 0,
  clearCart: () => {},
});

export const usePurchase_Prov = () => useContext(Purchase_ProviderContext);

interface CartProviderProps {
  children: React.ReactNode;
}

export const PurchaseProvider: React.FC<CartProviderProps> = ({ children }) => {


  const isAuth = useAuth();
  const AuthUser = useAuthUser();

  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [salesrep, setsalesrep] = useState<any>({} as any);
  useEffect(() => {
    const storedCustomer = localStorage.getItem('Customer');
    if (storedCustomer && JSON.stringify(customer) !== storedCustomer) {
      setCustomer(JSON.parse(storedCustomer));
    }
  }, [customer]);

  useEffect(() => {
    const storedsalesrep = localStorage.getItem('Sale_rep');
    if (storedsalesrep && JSON.stringify(salesrep) !== storedsalesrep) {
      setCustomer(JSON.parse(storedsalesrep));
    }
  }, [salesrep]);

  const AddCustomer = (customer: Customer) => {
    setCustomer(customer);
    localStorage.setItem('Customer', JSON.stringify(customer));
    console.log("Customer added" );
    console.log(JSON.stringify(localStorage.getItem('Customer')));
  }

  const AddSalesrep = (salesrep: Salesrep) => {
    setsalesrep(salesrep);
    localStorage.setItem('Sale_rep', JSON.stringify(salesrep));
    console.log("salesrep added" );
    console.log(JSON.stringify(localStorage.getItem('Sale_rep')));
  }


  function SubmitOrder() {
  
    console.log("Order Submitted");
    console.log(AuthUser);
  
    const total = Number(
      PurchaseItems.reduce(
        (acc, item) => acc + item.UnitPrice * item.Quantity * (1 - item.Discount_Percentage / 100),
        0
      ).toFixed(2)
    );
  
    const order = {
  
      Createdby: {
        id:AuthUser.id,
      },
    
      Purchase_Order_Items: PurchaseItems,
    };
    
  
    console.log(order);
  
    customaxios
       //.post("/", order)
     .post("/Purchase/CreatePurchase_Order", order)
      .then((response) => {
        console.log("response");
        console.log(response);
       clearCart();
        toast.success("Order Submitted Successfully #" + response.data.salesOrderId);
      })
      .catch((error) => {
        console.log("err");
        console.log(error);
      });
  }
  
  const [PurchaseItems, setPurchaseItems] = useState<CartItem[]>([]);

  useEffect(() => {
    const storedPurchaseItems = localStorage.getItem('Purchase_list');
    if (storedPurchaseItems) {
      setPurchaseItems(JSON.parse(storedPurchaseItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('Purchase_list', JSON.stringify(PurchaseItems));
  }, [PurchaseItems]);

  const addToPurchase = (item: CartItem) => {
  
    setPurchaseItems((prevItems) => {
      const existingItem = prevItems.find((i) => i.id === item.id);
      if (existingItem) {
        return prevItems.map((i) =>
          i.id === item.id ? { ...item } : i
        );
      } else {
        return [...prevItems, { ...item, Quantity: item.Quantity }];
      }
    });
  };
  const updateCartItem = (itemId: number, updatedItem: CartItem) => {
    setPurchaseItems((prevItems) =>
      prevItems.map((i) =>
        i.id === itemId ? { ...updatedItem } : i
      )
    );
  };
  useEffect(() => {
  calculateTotalValue(PurchaseItems);
    
  }, [PurchaseItems]);
  // Function to recalculate the total value in the cart
  const calculateTotalValue = (items: CartItem[]) => {
    return items.reduce((acc, item) => {
      let priceToUse = 0;
  
      if (item.Nett_Price > 0) {
        // Use Nett_Price if it's greater than 0 and exclude the discount
        priceToUse = item.Nett_Price;
      } else {
        // Use UnitPrice if Nett_Price is 0 and apply the discount
        const discountedPrice = item.UnitPrice * (1 - item.Discount_Percentage / 100);
        priceToUse = discountedPrice > 0 ? discountedPrice : 0;
      }
  
      return acc + priceToUse * item.Quantity;
    }, 0);
  };
  
  
  const updateCartItemQuantity = (itemId: number, Quantity: number) => {
    setPurchaseItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? {
              ...item,
              Quantity,
              Total_Amount: (item.Nett_Price > 0 ? item.Nett_Price : item.UnitPrice) * Quantity * (1 - item.Discount_Percentage / 100),
            }
          : item
      )
    );
  };
  const updateCartItemNote = (itemId: number, Note: string) => {
    setPurchaseItems((prevItems) =>
      prevItems.map((i) =>
        i.id === itemId ? { ...i, Note } : i
      )
    );
  };
  const updateCartItemDiscount = (itemId: number, Discount_Percentage: number) => {
    setPurchaseItems((prevItems) =>
      prevItems.map((i) =>
        i.id === itemId ? { ...i, Discount_Percentage } : i
      )
    );
  };
  const removeFromCart = (itemId: number) => {
    setPurchaseItems((prevItems) =>
      prevItems.filter((i) => i.id !== itemId)
    );
  };

  const clearCart = () => {
    setPurchaseItems([]);
  };
  const getTotalItemsInCart = () => {
    return PurchaseItems.length;
  };
  const getTotal_ValueInCart = () => {
    let total = 0;
    PurchaseItems.forEach(element => {
      total += element.Total_Amount;
    });
    return total;
  }
  
  return (
    <Purchase_ProviderContext.Provider value={{
      SubmitOrder,
      AddCustomer, 
      AddSalesrep,
      salesrep,
      customer,
     //,updateCustomer,
      PurchaseItems,
      getTotalItemsInCart,
      getTotal_ValueInCart,
      updateCartItemNote,
      updateCartItemDiscount ,
      updateCartItem,
      updateCartItemQuantity,
      addToPurchase, 
      removeFromCart, 
      clearCart }}>
      {children}
    </Purchase_ProviderContext.Provider>
  );
};
