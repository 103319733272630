import React, { useEffect, useState, useCallback, memo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthProvider, useAuthUser } from "../contexts/auth-context";
import customaxios from "../helpers/axios-helper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

function Customers() {
  const AuthUser = useAuthUser();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  const fetchCustomers = useCallback((pageNumber, pageSize) => {

    customaxios
      .get(`Customer/GetCustomers?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((response) => {
        setCustomers(response.data.results);
        setTotalCustomers(response.data.totalCustomers);
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchCustomers(page + 1, rowsPerPage);
  }, [fetchCustomers, page, rowsPerPage]);


  const fetchCustomers_search = useCallback((pageNumber, pageSize, keyword = "") => {
   
    customaxios
      .post(`Customer/SearchCustomer`,
      {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize
      })
      .then((response) => {

        setCustomers(response.data.customers);
        setTotalCustomers(response.data.totalCustomers);
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const handleSearch = useCallback((event) => {
    const keyword = event.target.value;
    setPage(0);
    fetchCustomers_search(1, rowsPerPage, keyword);
  }, [fetchCustomers_search, rowsPerPage]);
 
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCustomers - page * rowsPerPage);
  if (loading) { // Display loading while fetching data
    return <div>Loading...</div>;
  }
  return (
    <div>
      {AuthUser.role === "Director" || AuthUser.role === "Developer" ? (
        
        <Paper>
          <TextField
  label="Search"
  variant="outlined"
  onChange={handleSearch}
/>
<TablePagination
  component="div"
  count={totalCustomers}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={(event, newPage) => setPage(newPage)}
  onRowsPerPageChange={(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }}
  rowsPerPageOptions={[5, 10, 25]}
/>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Group Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {customers && customers.map((customer) => (
                  <TableRow
                    key={customer.customerId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Link
                        to={`/CustomerInformation/${customer.customerId}`}
                      
                      >
                        {customer.customerName}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{customer.addressline2}</TableCell>
                    <TableCell align="left">{customer.group_name}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          
        </Paper>
      ) : (
        <div>no auth</div>
      )}
    </div>
  );
}

export default memo(Customers);
