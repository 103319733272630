import FinanceExpenses from "./Finance/FinanceExpenses";
import GeneralExpenses from "./General/GeneralExpenses";

import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AdministrationExpense from "./Administration_Expense/AdministrationExpense";
import DirectorRemuneration from "./DirectorRemuneration/DirectorRemuneration";
import Selling_And_DistributionExpense from "./Selling_And_Distribution/Selling_And_DistributionExpense";
import "./Style/Style.css"
const tabsData = [
    { label: "(P4) Director Remuneration", component: <DirectorRemuneration/> },
    { label: "(P5) Administration", component: <AdministrationExpense/> },
    { label: "(P6) Selling & Distribution", component: <Selling_And_DistributionExpense/> },
    { label: "(P7) Finance", component: <FinanceExpenses/> },
    { label: "(P8) General", component: <GeneralExpenses/> },
    
  ];
  

export default function Expense(){
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

    const handleTabChange = (event, newValue) => {
      setSelectedItem(newValue);
    };
    return(
        <>
        <div className="">
          <div className="">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
              className="tabs_Expenses"
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
          </div>
  <br />
          <div className="">
            {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    )
}