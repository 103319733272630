
import ListBankAccounts from "./ListBankAccounts"
import CreateBank_Account from "./CreateBank_Account"
export default function Bank(){

    return(
        <>

        <CreateBank_Account/>
      <ListBankAccounts/>
        </>

        
    )
}