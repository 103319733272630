import ListNon_Current_Assets from "./ListNon_Current_Assets";
import CreateNon_Current_Assets from "./CreateNon_Current_Assets";
export default function  Non_Current_Assets (){
    
    return(
    <>
   
      <CreateNon_Current_Assets/>
        <br />
        <ListNon_Current_Assets/>
    </>)
}