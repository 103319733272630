import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.jpeg';
import Nav from '../nav';
import "./Style/style.css";
import Search_Header from '../Search_Header/Search_Header'; 
import { useAuthUser } from "../../contexts/auth-context";
import {  Navigate, useNavigate  } from "react-router-dom";
import Logout from '../logout';

export default function Header() {
  const navigate = useNavigate();
  const AuthUser = useAuthUser();
  const expiryDateStr = localStorage.getItem('expiry');
  const issueDatetimeStr = localStorage.getItem('issue_datetime');
  const [isSticky, setIsSticky] = useState(false);
  // Parse expiryDate and issueDatetime as Date objects
  const expiryDate = new Date(expiryDateStr);
  const issueDatetime = new Date(issueDatetimeStr);
  const [timeLeft, setTimeLeft] = useState(calculateCountdown(expiryDate,issueDatetime));
  const [borderWidth, setBorderWidth] = useState(100); // Initial border width (100%)

  useEffect(() => {
    if (AuthUser) {
      const expiryDateStr = localStorage.getItem('expiry');
      const issueDatetimeStr = localStorage.getItem('issue_datetime');
      // Parse expiryDate and issueDatetime as Date objects
      const expiryDate = new Date(expiryDateStr);
      const issueDatetime = new Date(issueDatetimeStr);

      const intervalId = setInterval(() => {
        const timeRemaining = calculateCountdown(expiryDate, issueDatetime);
        setTimeLeft(timeRemaining);

        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          // Logout and navigate to the desired route
          // You should implement the Logout function
      //    Logout();
       //   navigate('/');
        } else {
          // Calculate the border width based on timeLeft and issue time
          const screenWidth = window.innerWidth;
          const timePercentage =
            (timeRemaining / (expiryDate.getTime() - issueDatetime.getTime())) * 100;
          const calculatedWidth = (screenWidth * (timePercentage / 100));
          setBorderWidth(calculatedWidth);
        }
      }, 1000); // Update every second

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [AuthUser, navigate]);

  function calculateCountdown(expiryDate, issueDatetime) {
    const now = new Date();

    if (expiryDate <= now) {
      return 0; // Return 0 to indicate that it's expired
    }

    const secondsRemaining = Math.floor((expiryDate.getTime() - now.getTime()) / 1000);

    return secondsRemaining;
  }


  return (
    <header className={`header${isSticky ? ' sticky' : ''}`}
 > {
  //timeLeft
 }
      <br />
      {//Math.floor( (expiryDate.getTime() - issueDatetime.getTime()) /1000)
      }
      <div className="HeaderWrapper">
      <div className="logo">
  <Link to={AuthUser?.role === 'Customer' ? '/products' : '/home'}>
    <img className="header-logo" src={logo} alt="Cristal Code" />
  </Link>
</div>

        <div className="search_head">
          <Search_Header />
        </div>
        <div className="navheader">
          <Nav />
        </div>
      </div>
    </header>
  );
}
