import React, { useState, useEffect } from 'react';
import axios from 'axios';
import customaxios from '../../helpers/axios-helper';
import { toast } from 'react-toastify';

interface HistoryRow {
    quantity: number;
    discount_Percentage: number;
    quantity_Unit: string;
  id: number;
  nett_Price: number;
  listPrice: number;
}

interface DiscountProps {
  value: number;
  onChange: (value: number) => void;
}

const Discount: React.FC<DiscountProps> = ({ value, onChange }) => {
  const [disc, setDisc] = useState<number>(value);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleDiscChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDisc = Number(e.target.value);
    setDisc(newDisc);
    onChange(newDisc);
  };

  const handleDiscClick = () => {
    setIsEditing(true);
  };

  const handleDiscBlur = () => {
    setIsEditing(false);
  };

  return (
    <p onClick={!isEditing ? handleDiscClick : undefined}>
       {' '}
      {isEditing ? (
        <input
          type="number"
          value={disc}
          onChange={handleDiscChange}
          onBlur={handleDiscBlur}
          autoFocus
        />
      ) : (
        `${disc}%`
      )}
    </p>
  );
};

const LineItem: React.FC<{ historyRow: HistoryRow }> = ({ historyRow }) => {
    const [id, setId] = useState<number>(historyRow.id); // <-- This is the only difference
  const [qty, setQty] = useState<number>(historyRow.quantity);
  const [qtytype, setqtytype] = useState<number>(historyRow.quantity);
  const [disc, setDisc] = useState<number>(historyRow.discount_Percentage);
  const [isEditingQty, setIsEditingQty] = useState<boolean>(false);
  const [isEditingnett_price, setisEditingnett_price] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [nett_price, setNettPrice] = useState<number>(0);
  const [is_nettprice, setis_nettprice] = useState<boolean>(false);

  useEffect(() => {
    // Calculate the nett_price based on the historyRow data
    const calculatedNettPrice = historyRow.nett_Price > 0
      ? historyRow.nett_Price
      : historyRow.listPrice * (1 - historyRow.discount_Percentage / 100) ;
      setis_nettprice(true)
    
    // Update the state with the formatted nett_price
    setNettPrice(calculatedNettPrice);
  }, [historyRow.nett_Price, historyRow.listPrice]);
  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQty(Number(e.target.value));
    setDataChanged(true);
  };
  const handleNettChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNettPrice(Number(e.target.value));
    setDataChanged(true);
  };

  const handleQtyClick = () => {
    setIsEditingQty(true);
  };
  const handleNettClick = () => {
    setisEditingnett_price(true);
  };
  const handleNettBlur = () => {
    setisEditingnett_price(false);
  };
  const handleQtyBlur = () => {
    setIsEditingQty(false);
  };

  const handleDiscChange = (value: number) => {
    if (!is_nettprice) {
      setDisc(value);
      setDataChanged(true);
    }
    else{
      toast.error(`Item is created with Nett price # ${id}`)
    }
  };
  

  useEffect(() => {
    const saveData = async () => {
      try {
        await customaxios.post('OrdeOrderrs/UpdateLineItem', { id, qty, disc,nett_price });
        setDataChanged(false);
        toast.success(`Successfully updated Line Item # ${id}`)
      } catch (error) {
        console.error('Error saving updated line item data:', error);
        toast.error(`Failed to update Line Item # ${id}`)

      }
    };
  
    if (!isEditingQty && dataChanged) {
      saveData();
    }
  }, [id, qty, disc, isEditingQty, dataChanged,isEditingnett_price]);

  return (
    <>
      <td className="Line_Item_Qty">
        <p onClick={!isEditingQty ? handleQtyClick : undefined}>
          {' '}
          {isEditingQty ? (
            <input
              type="number"
              value={qty}
              onChange={handleQtyChange}
              onBlur={handleQtyBlur}
              autoFocus
            />
          ) : (
            qty +" "+ historyRow.quantity_Unit
          )}
        </p>
      </td>
      <td className="Line_Item_Discount">
        <Discount value={historyRow.discount_Percentage} onChange={handleDiscChange} />
      </td>

      <td className="price">
      <p onClick={!isEditingnett_price ? handleNettClick : undefined}>
          {' '}
          {isEditingnett_price ? (
            <input
              type="number"
              value={nett_price}
              onChange={handleNettChange}
              onBlur={handleNettBlur}
              autoFocus
            />
          ) : (
            nett_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          )}
        </p>
        {/*Number(historyRow.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })*/}

     
      
      </td>
    </>
  );
};

export default LineItem;
