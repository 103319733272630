
import React, { useEffect, useState } from "react";  
import { useLocation } from "react-router-dom";
import customaxios from "../helpers/axios-helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Orderrow from "./orderrow";


export default function Customerorders({state}:any) {
    //console.log(state);
    const location = useLocation();
   // console.log(location);
   // console.log(location.state);
    const notify = () => 
    toast.success(orders.length +" Products Loaded");
    //set customer ledgers 
    const [orders, setItems] = useState([]);
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('OrdeOrderrs/GetOrdersByCustomer/',{
            customerid: state,
        })
        .then((response) => {
          //  console.log(response);
            setItems(response.data);
            //console.log(response.data);
        })
    }, []);
   
   // console.log(location.state)
  return (
<div className="orderview">

<table className="ledger_View_Table">
                  <thead>
                  <tr className="Ledger_View_Table_Row Title">
                   <th align="left"></th>
            <th align="left">vchseries</th>
            <th align="left">billDate</th>

            <th align="left">billNo</th>
            <th align="left">Sales Rep</th>
            <th align="left">Grand Total</th>      
            <th align="left">Status</th>      
          </tr>
        </thead>
        <tbody>
          {orders?.map((row) => (
            <Orderrow key={row.name} row={row} />
          ))}
        </tbody>
      </table>
  
</div>
 );
 
}