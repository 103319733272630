import productnamereducer from "./ProductName";
import { combineReducers } from "redux";
import zeroqtyreducers from "./ZeroQty"; 
import ProductLables from "./ViewProductLables"; 
import DiscountValue from "./DiscountValue"; 
import Archimagereducer from "./ArchImage"; 
import Arch_minmise from "./Arch_minmise"; 
import Pos_Mode from "./Pos_Mode";
import Purchase_Mode from "./Purchase_Mode";
const allReducers = combineReducers({
    productname: productnamereducer,
    zeroqty: zeroqtyreducers,
    lable:ProductLables,
    Discount:DiscountValue,

    ArchImages:Archimagereducer,


    Arch_minmise:Arch_minmise,
    Pos_Mode:Pos_Mode,
    Purchase_Mode:Purchase_Mode,


});
export default allReducers;