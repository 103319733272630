import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import {Salesrep } from '../../models/Sale_Rep/Salesrep';
import { useCart } from '../Cart/CartProvider';
import "./Style/Style.css"
export default function SelectedSales_rep() {
    const { 
        //updateCustomer, Customer,
        salesrep,
         AddSalesrep,
} = useCart();
         const [IsEditingsalesrep, setIsEditingsalesrep] = useState(false);
         const Sales_rep:Salesrep = {
          firstName  :"string" ,
          id :"string" ,
          lastName :"string",
          name :"string",
           
        
          
          }

         const [relatedsalesrep, setRelatedsalesrep] = useState([]);
         useEffect(() => {
          if (IsEditingsalesrep) {
            console.log("Customer call");
            // Make API call to fetch related items here
            customaxios
            .get("/User/SearchUser?keyword="+salesrep.name, )
              .then((response) => {
                setRelatedsalesrep(response.data);
                console.log("Customer list");
                console.log(response.data);
              })
              .catch((error) => {
                if (error.response && error.response.data) {
                  // Handle validation errors
                  const validationErrors = error.response.data;
                  console.log("Validation errors:", validationErrors);
                  // Update state or show error messages to the user
                } else {
                  console.log("Error:", error);
                  // Handle other types of errors
                }
              });
          }
        }, [IsEditingsalesrep, salesrep]);
        
       
         const handleCustomerchange = (event) => {
           Sales_rep.name = event.target.value;
           AddSalesrep(Sales_rep);
         };
       
         const handleSaveChanges = (item) => {
          console.log("handleSaveChanges");
          console.log(item);
           setIsEditingsalesrep(false);
           AddSalesrep(item)
           console.log("Savedinstorage");
           console.log(JSON.stringify(localStorage.getItem('Sale_rep')));
           
         };
         const handleCancelEditing = () => {
           setIsEditingsalesrep(false);
           AddSalesrep(salesrep);
         };


  return (
    <div className="Sales_rep_selector">

    {IsEditingsalesrep ? (
<div className="Customer_Name">
  <input  type="text" value={salesrep.name} onChange={handleCustomerchange} />
  <button onClick={handleSaveChanges}>Select</button>
  <button onClick={() => {handleCancelEditing()}}>Cancel</button>
</div>
        ) : (
        <p   onClick={() => setIsEditingsalesrep(true)}>
        sales rep : {salesrep.firstName}  {salesrep.lastName}<br/>

        </p>
        )}{IsEditingsalesrep && relatedsalesrep.length > 0 && (
        <ul className='sales_RepSelectlist'>
        {relatedsalesrep.map(item => (
            <li className='sales_RepSelect' key={item.id} onClick={() => handleSaveChanges(item)}>
            {item.firstName}     {item.lastName}
            </li>
        ))}
        </ul>
        )}
        <div className="customerid">

       
        </div>
</div>


  );
}

