
import React, { useEffect, useState } from "react";  

import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function  Orderrow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
     <tr className="Ledger_View_Table_Row">
          <td>
            {row.ordeOrderItems.length> 0?
            (<IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>):
            (null)
        
        }
            
          </td>
         
          <td align="left">{row.vchseries}</td>
          <td align="left">{row.billDate}</td>
          <td align="left">{row.billNo}</td>
          
          <td align="left">{row.brokername}</td>
          <td align="left">{row.amountgrandtotal}</td>
          <td align="left">{row.orderStatus}</td>
        </tr>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <table className="ledger_View_Table">
                    <thead>
                    <tr className="Ledger_View_Table_Row Title">
                       <th>Supplier Code</th>

                      <th>Name</th>

                      <th align="left"> A Code </th>
                      <th align="left">QTY</th>
                      <th align="left">Listing Price</th>
                      <th align="left">Discount</th>
                      <th align="left">After Discount Price</th>
                      <th align="left">Sub Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    row.ordeOrderItems.map((historyRow) => (
                      <tr className="Ledger_View_Table_Row" key={historyRow}>
              

<td>{historyRow.product?.suppliercode ?? "N/A"}</td>
<td>{historyRow.product?.productName ?? "N/A"}</td>
<td>{historyRow.product?.oaM_ACode ?? "N/A"}</td>
<td>{historyRow.qty ?? "N/A"}</td>
<td>{historyRow.listPrice ?? "N/A"}</td>
<td>{historyRow.disc ? historyRow.disc + "%" : "N/A"}</td>
<td>{historyRow.price ?? "N/A"}</td>
<td>{historyRow.amount ?? "N/A"}</td>

                       
                      </tr>
                    ))}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> Grand Total</td>
                        <td> {row.amountgrandtotal?.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>
                  

                  </tbody>
                </table>
            </Collapse>
       
      </React.Fragment>
    );
  }
  