import React,{useEffect,useState, Suspense} from "react";

import { useParams } from 'react-router-dom';
import customaxios from "../../helpers/axios-helper";

import ProductArch from "../Product/Arch/ProductArch";
interface RouteParams {
    id: string;
    [key: string]: string;
  }
  
export default function Supplier() {
    const { id } = useParams<RouteParams>();



    const [productList, setProductList] = useState([]);


    useEffect(() => {
        customaxios.get(`supplier/GetSupplierProducts/${id}`)
            .then((response) => {
                console.log(response);
                setProductList(response.data);
            })
    }, [id]);
    
    return(
        <>

     


                <div className="groupProductview">
                <div className="CategoryInformation">
        <h1>Supplier Page </h1>

        </div>
        <br />
        <br />
        <br />
        <br />
                < div className="ProductScroll">
                <Suspense fallback={<div>Loading...</div>}>
        {productList.map((row, index) => (
            <ProductArch key={row.productArch.productArchId} products={row.products} arch={row.productArch}/>
        ))}

      </Suspense>
                        </div>
                </div>
        </>
    )
}