import CreateDirectorRemuneration from "./CreateDirectorRemuneration";
import ListExpenses from "./ListExpenses"
import LedgerView from "../../Ledger/LedgerView";
export default function DirectorRemuneration (){


    return(
        <>
        <CreateDirectorRemuneration/>
        <br />
        <ListExpenses/>
        </>
    )
}