import React, { useEffect, useState } from "react";  
import customaxios from "../helpers/axios-helper";
import 'react-toastify/dist/ReactToastify.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Customer_ledger from "./Components/Customer_ledger";
import Modal from 'react-modal';
import Customerorders from "./Customerorders";
import Customer_Invoices_Short from "./Components/Customer_Invoices_Short";
import Customer_Payments_short_Optimised from "./Components/Customer_Payments_short_Optimised"
import { toast } from 'react-toastify';
import Customer_Payments_short from "./Components/Customer_Payments_short";
import { useAuth, useAuthUser } from "../contexts/auth-context";
import { CheckCircle } from '@mui/icons-material';
import { confirmAlert } from 'react-confirm-alert';
import Customer_Payments from "./Components/Customer_Payments";
  interface RouteParams {
    id: string;
    [key: string]: string;
  }
  
  export default function CustomerInformation({ id: routeId }: { id: string | null }) {
    const { id: paramId } = useParams<RouteParams>();
    const id = paramId ? paramId : routeId;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const AuthUser = useAuthUser();

 
    //set customer ledgers 
    const [Customer, setItems] = useState<any>([]);

  
    const handle_Move_To_asset = () => {
 
    
      const confirmationMessage = 'Are you sure you want to Move this as an Asset?';
    
      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios
              .post(`Asset/Migrate_As_Asset/${id}`)
                .then((response) => {
                  console.log(response.data);
                  toast.success('Moved_ To asset');
                })
                .catch((error) => {
                  console.log(error);
                  toast.error('failed');
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    };


    //axios call to get items post request
    useEffect(() => {
        customaxios.post('Customer/GetCustomerById/',{
            Customerid: id,
        })
        .then((response) => {
            console.log(response);
            setItems(response.data);
        })
    }, [id]);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

   // console.log(Customer)


    const handle_Move_To_Loan = () => {
 
    
      const confirmationMessage = 'Are you sure you want to Move this as a Loan?';
    
      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios
              .post(`Loan/Migrate_As_Loan/${id}`)
                .then((response) => {
                  console.log(response.data);
                  toast.success('Moved To Loan');
                })
                .catch((error) => {
                  console.log(error);
                  toast.error('failed');
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    };


    const handle_Move_To_Staff_loan = () => {
 
    
      const confirmationMessage = 'Are you sure you want to Move this as a Loan?';
    
      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios
              .post(`StaffLoan/Migrate_As_StaffLoan/${id}`)
                .then((response) => {
                  console.log(response.data);
                  toast.success('Moved To Staff Loan');
                })
                .catch((error) => {
                  console.log(error);
                  toast.error('failed');
                });
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    };

    return (
      <div className="customerinformation">

     <div className="customer_data">



<div className="customer_Info card">

<h2>{" "+Customer.customerName} {Customer.verified_Customer && <CheckCircle style={{ color: 'green', marginLeft: 8 }} />}</h2>
<p><strong>Sales Rep</strong> {Customer.town?.route? Customer.town.route.salesrep : "Not Allocated"}</p>
<p><strong>Group Name </strong> {Customer.town?.route? Customer.town.route.salesrep : "Not Allocated"}</p>

</div>

<div className="customer_contact card">
  <h3>Contact</h3>
  <tr>
    <td>Contact Name</td>
    <td>:{" "+Customer.contact_Name}</td>
  </tr>
  <tr>
    <td>Primary Contact</td>
    <td>:{" "+Customer.primary_Contact}</td>
  </tr>
  <tr>
    <td>Secondary Contact</td>
    <td>:{" "+Customer.secondary_Contact}</td>
  </tr>
  <tr>
    <td>Telephone No:</td>
    <td>:{" "+Customer.tel_no}</td>
  </tr>
  <tr>
    <td>Email</td>
    <td>:{" "+Customer.email}</td>
  </tr>
  <tr>
    <td>Fax Number</td>
    <td>:{" "+Customer.faxNo}</td>
  </tr>
</div>

<div className="customer_Location card">
  <h3>Location</h3>

  <tr>
    <td>Address Line 1</td>
    <td>:{" "+Customer.addressline1}</td>
  </tr>
  <tr>
    <td>Address Line 2</td>
    <td>:{" "+Customer.addressline2}</td>
  </tr>
  <tr>
    <td>Address Line 3</td>
    <td>:{" "+Customer.addressline3}</td>
  </tr>
  <tr>
    <td>Address Line 4</td>
    <td>:{" "+Customer.addressline4}</td>
  </tr>

  <tr>
  <p><strong>Route</strong></p>

</tr>

<tr>

  <td>Route </td>
  <td>:{Customer.town?.route? Customer.town.route.name : "Not Allocated"}</td>

</tr>
<tr>
  <td>Town </td>
  <td>:{Customer.town? Customer.town.name : "Not Allocated"}</td>

</tr>
</div>
<div className="Customer_Accounts card">
  <h3>Accounts</h3>
  <table>
    <thead>
      <tr>
        <th>Company</th>
        <th>Balance</th>
      </tr>
    </thead>
    <tbody>
      {Customer.customerSales?.map((sales_account) => (
        <tr key={sales_account.company.name}>
          <td>{sales_account.company.name}</td>
          <td className={sales_account.account_.balance < 0 ? 'text-red-500' : 'text-green-500'}>
            {sales_account.account_.balance.toLocaleString("en-US", { style: "currency", currency: "LKR" })}
          </td>
        </tr>
      ))}
      <tr>
        <td>Total Payable</td>
        <td className={Customer.customerSales?.some(sales_account => sales_account?.account_?.balance < 0) ? 'text-red-500' : 'text-green-500'}>
          {/* Calculate total payable by summing up all balances */}
          {Customer.customerSales?.reduce((total, sales_account) => total + sales_account?.account_.balance, 0).toLocaleString("en-US", { style: "currency", currency: "LKR" })}
        </td>
      </tr>
    </tbody>
  </table>
</div>



     </div>
        <Box sx={{ width: '100%' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="customer tabs">
            <Tab label="Invoices" />
         {/*   <Tab label="Invoices_2" /> */}
            <Tab label="Orders" />
            <Tab label="Ledgers" />
            <Tab label="Payments" />
            {/*
    ( AuthUser?.role === "Developer") && (
      <Tab label="Payments_optimised" />
      )
    */}
          </Tabs>
          {tabValue === 0 && (
            <Customer_Invoices_Short CustomerId={id} />
          )}
        
 
          {tabValue === 1 && (
            <Customerorders state={id} />
          )}
  
      
  
          {tabValue === 2 && (
            <Customer_ledger Customerid={id} />
          )}
          
          {tabValue === 3 && (
            <Customer_Payments CustomerId={id} />
          )}
         {/*
         {tabValue === 3 && (
            <Customer_Payments_short CustomerId={id} />
          )}
    ( AuthUser?.role === "Developer") && (
      tabValue === 4 && <Customer_Payments_short_Optimised CustomerId={id} />
    )*/
}

          
        </Box>
      </div>
    );
}