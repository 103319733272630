import React, { useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import { toast } from "react-toastify";
import "./style/Style.css";

export default function Export_PriceList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    let timer;
    if (isCompleted) {
      timer = setTimeout(() => {
        setIsCompleted(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isCompleted]);

  const handleExportClick = () => {
    setIsLoading(true);

    customaxios
      .get("export/export_products", { responseType: "blob" })
      //.get("export/Export_Products_By_Model_per_Brand", { responseType: "blob" })
      .then((response) => {
        setIsLoading(false);
        setIsCompleted(true);

        const date = new Date();
        const day = date.getDate();
        const month = date.toLocaleString("en-US", { month: "short" });
        const filename = `${day} ${month} pricelist.xlsx`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        toast.success("File downloaded successfully.");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Export failed:", error);
        toast.error("Failed to export file.");
      });
  };

  return (
    <>
      <button className="button_one" onClick={handleExportClick} disabled={isLoading}>
        {isLoading ? "Exporting" : isCompleted ? "Completed" : "Export"}
        {isLoading && (
          <span className="dots-animation">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        )}
      </button>

    </>
  );
}
