

import React, { useState } from "react";
import "./Style/Style.css"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Deposit_Advance_Prepayments from "../Deposit_Advance_Prepayments/Deposit_Advance_Prepayments";
import Cheque_list from "../../Cheque/ChequeList";
import Bank from "./Bank/Bank";
const tabsData = [
    { label: "-", component: <></> },
    { label: "(B2.1) Deposit,Advance & PrePayments", component: <Deposit_Advance_Prepayments/> },
    { label: "(B2.2) Sundry Debtors", component: <></> },
    { label: "(B2.3) Cash At Bank", component: <Bank/> },
    { label: "(B2.4) Cash & Cheque In Hand", component: <></> },
    { label: "Cheque", component: <Cheque_list /> },
   
    
  ];
  
export default function Current_Asset(){
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

    const handleTabChange = (event, newValue) => {
      setSelectedItem(newValue);
    };
    return(
     <>
        <div className="">
          <div className="">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
              className="tabs_Current_Asset"
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
          </div>
  <br />
          <div className="">
            {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
  );
}