import  { useState,  useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import {  useAuthUser } from "../../contexts/auth-context";
export default function Product_Name_change({ product }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [productName, setcategoryname] = useState(product.productName);
  const AuthUser = useAuthUser();

  const modalRef = useRef(null);


  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/product/update_Product_Name", {
        Product_name: productName,
        Product: product.productId,
      })
      .then((response) => {
        console.log("set Item Deitals");
        console.log(response.data);
        console.log("set false");

        setIsEditing(false);
        toast.success('product Name updated');

        product.productName = productName;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setcategoryname(product.productName);
  };

  const handleBrandNameChange = (event) => {
    setcategoryname(event.target.value);
  };

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal_brand" onClick={handleClose} ref={modalRef}>
          <div className="modal_brand-content">
            {isEditing ? (
              <div className="brand_Name">
                <h2>Update 'product Name</h2>
                <input
                  type="text"
                  value={productName}
                  onChange={handleBrandNameChange}
                />
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={handleCancelEditing}>Cancel</button>
              </div>
            ) : (
              <>
                <h4>'product Name</h4>
                <p onClick={() => setIsEditing(true)}> click here to edit {productName}</p>
              </>
            )}
            
          </div>
        </div>
      )}


{
  (AuthUser?.role === "CEO" || AuthUser?.role === "Director"|| AuthUser?.role === "Praveen" || AuthUser?.role === "Developer") ? (
    <p onClick={() => setIsModalOpen(true)}> {product.productName}</p>
  ) : (
    <p>{product.productName}</p>
  )
}

      
    </>
  );
}
