import React, { useEffect, useState } from 'react';
import customaxios from '../../helpers/axios-helper';
import './Style/Style.css';
import { Tab, Tabs, Box } from '@mui/material';

import ChequeSearch from './Search_Cheque';
export default function Cheque_list() {
  const [chequeList, setChequeList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  useEffect(() => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const endOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
    const endRange = new Date(endOfCurrentMonth.getTime() + 14 * 24 * 60 * 60 * 1000);

    setStartDate(firstDayOfMonth);
    setEndDate(endRange);

    fetchCheques(firstDayOfMonth, endRange);
  }, []);

  const fetchCheques = (start, end) => {
    const params = {
      startDate: start ? start.toISOString() : '',
      endDate: end ? end.toISOString() : '',
    };

    customaxios
      .get('cheque/Get_Cheques', { params })
      .then((response) => {
        const sortedCheques = response.data.sort((a, b) => {
          const stateOrder = {
            Pending: 1,
            "In Books": 2,
            SubmittedToBank: 3,
            Realised: 4,
            Bounced: 5,
          };

          return stateOrder[a.state] - stateOrder[b.state];
        });

        setChequeList(sortedCheques);
        console.log('cheque list', sortedCheques);
      })
      .catch((error) => {
        console.error('Error retrieving cheque list:', error);
      });
  };

  const groupedCheques: { [key: string]: any[] } = {};
  chequeList.forEach((cheque) => {
    if (!groupedCheques[cheque.state]) {
      groupedCheques[cheque.state] = [];
    }
    groupedCheques[cheque.state].push(cheque);
  });

  return (
    <div>
      <ChequeSearch />
      <h3>select date range for cheques</h3>
      <div className="date-selectors">
        <label htmlFor="startDate">Start Date: </label>
        <input
          type="date"
          id="startDate"
          value={startDate ? startDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />

        <label htmlFor="endDate">End Date: </label>
        <input
          type="date"
          id="endDate"
          value={endDate ? endDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setEndDate(new Date(e.target.value))}
        />

        <button onClick={() => fetchCheques(startDate, endDate)}>Apply</button>
      </div>

      <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      {Object.keys(groupedCheques).map((state, index) => (
        <Tab label={`${state} Cheques`} key={index} />
      ))}
    </Tabs>

    {Object.entries(groupedCheques).map(([state, cheques], index) => (
      <TabPanel value={selectedTab} index={index} key={index}>
       
       <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
         <tr >
                <th>Number</th>
                <th>Bank</th>
                <th>Amount</th>
                <th>Date</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {cheques.map((cheque) => (
              <tr className={`bg-white border-b dark:bg-gray-800 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-600 ${cheque.state}`} key={cheque.chequeId}>
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{cheque.chequeNumber}</td>
                  <td>{cheque.bank}</td>
                  <td>{cheque.amount}</td>
                  <td>{new Date(cheque.chequeDate).toLocaleDateString('en-UK', { day: 'numeric', month: 'short', year: '2-digit' })}</td>
                  <td>{cheque.state}</td>
                </tr>
              ))}
            </tbody>
          </table>
      </TabPanel>
      ))}
    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
