import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Tabs, Tab } from "@material-ui/core";
import Create_Voucher_category from "./Create_Voucher_category";
import CategoryList from "./CategoryList";

export default function VoucherCategory() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        <Tab label="Create Category" />
        <Tab label="Category List" />
      </Tabs>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <Create_Voucher_category />
        <CategoryList />
      </SwipeableViews>
    </>
  );
}

