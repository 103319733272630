// Import necessary modules
import React, { useState, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';

// Define the component
const ChatComponent: React.FC = () => {
  const [messages, setMessages] = useState<string[]>([]);
  const [user, setUser] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const hubConnection = useRef<signalR.HubConnection | null>(null);

  useEffect(() => {
    // Create a hub connection
    hubConnection.current = new signalR.HubConnectionBuilder()
      .withUrl('https://api.oam.lk/chat') // Specify the URL of your SignalR hub
      .build();

    // Start the connection
    hubConnection.current
      .start()
      .then(() => console.log('Connection started'))
      .catch((error) => console.error('Error starting connection:', error));

    // Set up a listener for the "ReceiveMessage" event
    hubConnection.current.on('ReceiveMessage', (receivedUser: string, receivedMessage: string) => {
      setMessages((prevMessages) => [...prevMessages, `${receivedUser}: ${receivedMessage}`]);
    });

    // Clean up the hub connection when the component unmounts
    return () => {
      hubConnection.current?.stop();
    };
  }, []);

  const sendMessage = async () => {
    // Send the message to the server
    if (hubConnection.current && user && message) {
      try {
        await hubConnection.current.invoke('sendmessage', user, message);
        setMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <div>
      <div>
        <input
          type="text"
          placeholder="Enter your name"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Enter your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
      <div>
        <ul>
          {messages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChatComponent;
