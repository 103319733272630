
import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Current_Liablities from "./Current Liablities/Current Liablities";
import Long_Term_Liability from "./Long_Term_LIability/Long_Term_Liability";
import "./Style/Style.css"
const tabsData = [
    { label: "(B3) Long Term Liablities", component: <Long_Term_Liability/> },
    { label: "(B4) Current Liablities", component: <Current_Liablities/> },
  ];
  


export default function Equity_And_Liabilities(){
    
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);
    const handleTabChange = (event, newValue) => {
        setSelectedItem(newValue);
      };
    return(
        <>
        <div className="">
          <div className="">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
              className="tabs_Expenses"
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
          </div>
  <br />
          <div className="">
            {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    )
}