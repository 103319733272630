import { useState,useCallback } from 'react';
import Switch from '@mui/material/Switch';
import Line_Item from './Line_Item';
import customaxios from "../../helpers/axios-helper";
import { useAuth, useAuthUser } from '../../contexts/auth-context';
import { ToastContainer, toast } from 'react-toastify';
import OrderItemsFilter from "./Order_Comp/OrderItemsFilter";
import { Product } from '../../models/Products/product';

interface Props {
  //selectedOrderItems: any[];
 // taxinvoice: boolean;
  // handletaxinvoice: () => void;
 // clickedItemIndex: any;
 // handleButtonClick: (index: any) => void;
  //handleCheckboxChange: (e: any, orderItem: any) => void;
  //handleCloseModal: () => void;
  vchseries:string;
  billing_Group_Contained:any;
 // orders:any;
  ids:any[];

}

const SelectedToInvoices = ({
  ids,
 // selectedOrderItems,
 // taxinvoice,
  vchseries,
  billing_Group_Contained,
  //handletaxinvoice,
  /*clickedItemIndex,
  handleButtonClick,
  handleCheckboxChange,
  handleCloseModal,*/
 // orders
}: Props) => {
 
  const auth = useAuthUser();
  const [taxinvoice, setTaxInvoice] = useState(true);
  const handletaxinvoice = useCallback(() => {
    setTaxInvoice(prevState => !prevState);
  }, []);
    const AuthUser = useAuthUser();
    const CreateInvoice = async () => {
        console.log(AuthUser.id);
    
        try {
          // Replace 'your-url' with the actual URL you want to send the data to
          customaxios
            .post("invoice/CreateInvoice_LineIds", {
              Line_Items:ids,
              //OrderItems: selectedOrderItems,
              taxBill: taxinvoice,
              Billing_Group:vchseries,
              Billing_Group_Contained:billing_Group_Contained,
             // invoiceValue: selectedOrderItems.reduce((total, orderItem) => total + parseFloat(orderItem.amount), 0),
            //  invoiceValueWithTax: taxinvoice ? selectedOrderItems.reduce((total, orderItem) => total + parseFloat(orderItem.amount), 0) * 1.15 : 0,
              createdby: {
                id: AuthUser.id,
              }
            })
            .then((response) => {
              console.log(response.data);
            });
          toast.success('Order items successfully posted!');
        } catch (error) {
          console.error('Error posting order items:', error);
          toast.error('Error posting order items. Please try again.');
        }
      };
  return (
    <div className="Selected orderitems">
      <div className="SelectedOrderItems">
        <div className="InvoiceData">
          
          <div className="TaxType">
            <h4>
              Tax type : {taxinvoice ? (
              <>Tax Invoice</>):(<>Non Tax Invoice</>)}
            </h4>
            <Switch checked={taxinvoice} onChange={handletaxinvoice} />
          </div>
          <div className="placeinvoice">
            <button className="button_one Place_invoice" onClick={() => CreateInvoice()}>
              Create Invoice
            </button>
          </div>
        </div>

     
      </div>
    </div>
  );
};

export default SelectedToInvoices;
