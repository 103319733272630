import React from "react";
import "./Style/Style.css";

interface InStockTableProps {
  stockLevels: any[];
}

const InStockTable: React.FC<InStockTableProps> = ({ stockLevels }) => {
  const renderStockLevels = () => {
    const filteredLevels = stockLevels.filter((level) => level.type === "In");

    if (filteredLevels.length === 0) {
      return (
        <tr>
          <td colSpan={9}>No In stock levels found.</td>
        </tr>
      );
    }

    return filteredLevels.map((level) => (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={level.stocklevelsId}>
        <td>{level.levelDateTime.toLocaleString()}</td>
        <td>{level.movement}</td>
        <td>
          <a href={`/grn/${level.grnItem?.grnId}`}>
            GRN {level.grnItem?.grnItemId}
          </a>
        </td>
        <td>{level.grnItem?.quantity}</td>
        <td>{level.grnItem?.price_Usd}</td>
        <td>N/A</td>
        <td>{level.grnItem?.price_mainUnit}</td>
      </tr>
    ));
  };

  return (
    <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr >
          <th>Level Date/Time</th>
          <th>Movement</th>
          <th>Grn ID</th>
          <th>Quantity</th>
          <th>price_Usd</th>
          <th>price_mainUnit</th>
        </tr>
      </thead>
      <tbody>{renderStockLevels()}</tbody>
    </table>
  );
};

export default InStockTable;
