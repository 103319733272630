import { useState  } from "react";


import { CartItem,useCart } from "./CartProvider";
import {  toast } from 'react-toastify';

export default function CartRow({ cartItem }: { cartItem: CartItem }) {

  const {  removeFromCart,  updateCartItemQuantity, updateCartItemDiscount } = useCart();

  const handleUpdateQuantity = (itemId: number, Quantity: number) => {
    updateCartItemQuantity(itemId, Quantity);
  };

  const handleDiscount = (itemId: number, Discount_Percentage: number) => {
    updateCartItemDiscount(itemId, Discount_Percentage);
  };
  const [editableQtyItemId, setEditableQtyItemId] = useState<number | null>(null);
  const handleQtyClick = (itemId: number) => {
    setEditableQtyItemId(itemId);
  };
  const handleqtyBlur = () => {
    setEditableQtyItemId(null);
  };
  const handleRemoveItem = (itemId: number) => {
    removeFromCart(itemId);
    window.location.reload();

  };
  const [editableDiscountItemId, setEditableDiscountItemId] = useState<number | null>(null);
  const handleDiscountClick = (itemId: number) => { // adjust the type of cartItem as per your needs
    if (cartItem.Nett_Price === 0) {
        setEditableDiscountItemId(itemId);
    } else {
        toast.error("Operation failed, Item Set With Nett Price");
    }
};


  const handleDiscountBlur = () => {
    setEditableDiscountItemId(null);
  };
 
  return (
    <tr  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      <td>   <p className="font-semibold text-black-900">{cartItem.Product.productName}</p>
          <p>{cartItem.Product.oaM_ACode} {" - "}
            {cartItem.Product.suppliercode && cartItem.Product.suppliercode.trim() !== "" ? cartItem.Product.suppliercode.trim() : "Supplier Code"}</p>

</td>

<td>
<p>{cartItem.Product.brand.brand_Name}</p>


</td>

<td>

{editableQtyItemId === cartItem.id ? (
                            <input
                            type="number"
                            value={cartItem.Quantity}
                            min={1}
                            onChange={(e) => handleUpdateQuantity(cartItem.id, parseFloat(e.target.value))}
                            onBlur={handleqtyBlur}
                            autoFocus
                            />
                        ) : (
                            <span onClick={() => handleQtyClick(cartItem.id)}>{ " "+cartItem.Quantity}</span>
                )}
</td>
<td>
<p>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cartItem.Product.productPrice.price)}</p>

</td>
<td>
<p>
            {editableDiscountItemId === cartItem.id ? (
                         <>
                            <input
                            type="number"
                            value={cartItem.Discount_Percentage }
                            min={1}
                            onChange={(e) => handleDiscount(cartItem.id, parseFloat(e.target.value))}
                            onBlur={handleDiscountBlur}
                            autoFocus
                            /> 
                         </>
                        ) : (
                            <span onClick={() => handleDiscountClick(cartItem.id)}>{ " "+cartItem.Discount_Percentage}</span>
                )} %</p>  
</td>
<td>
<p>
  {(
    cartItem.Nett_Price !== 0 
    ? cartItem.Nett_Price
    : cartItem.Product.productPrice.price * (1 - (cartItem.Discount_Percentage / 100))
  ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>
</td>
<td>
  <p>
  {cartItem.Total_Amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

  </p>
</td>

<td className="px-4 py-2">
  <p className="text-red-500 cursor-pointer" onClick={() => handleRemoveItem(cartItem.id)}>
    Remove
  </p>
</td>

    </tr>
     
     
    

  );
}

