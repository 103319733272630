import React, { useState, useEffect } from 'react';
import customaxios from '../../../helpers/axios-helper';

export default function SelectCategory({ onCategorySelected }) {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    customaxios.get('VoucherCategory/GetAllCategories')
      .then(response => setCategories(response.data))
      .catch(error => console.log(error));
  }, []);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    onCategorySelected(category);
  };

  return (
    <div className='selectcategory'>
     <select value={selectedCategory} onChange={handleCategoryChange}>
  <option value="">Select category...</option>
  {categories.map((category) => (
    <option key={category.voucherCategoryId} value={category.description}>
      {category.description}
    </option>
  ))}
</select>

    </div>
  );
}
