import SalesOrderDate from "./SalesOrderDate";
import { Menu, MenuItem } from "@mui/material";
import { useRef } from 'react';
import { useState } from 'react';
import StatusDropdown_SO from "./Status_Model/StatusDropdown_SO";
import "./Style_sales_order_V2/Style.css";
export default function OrderList_V2({ salesOrders, selectedOrder, handleOrderClick }) {
  const [contextMenu, setContextMenu] = useState<{
    open: boolean;
    anchorEl: null | HTMLElement;
  }>({ open: false, anchorEl: null });

  const handleOrderContextMenu = (event: React.MouseEvent<HTMLTableRowElement>, salesOrderId: string) => {
    event.preventDefault();
    setContextMenu({ open: true, anchorEl: event.currentTarget });
    handleOrderClick(salesOrderId);
  };

  const handleContextMenuClose = () => {
    setContextMenu({ open: false, anchorEl: null });
  };
  console
  .log(salesOrders)

  return (
    <div className="orderlist-container"> {/* Wrap the table inside a container */}
      <table  className="max-w-screen-xl m-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    

          <tr className=" ">
            <th className="">Urgent</th>
            <th className="">S/O No</th>
            <th className="">Date</th>
            <th className="">Customer </th>
            <th className="">Est Total</th>
           <th className="">Sales Rep</th>
            <th className="">Status</th>
          </tr>
        </thead>
        <tbody>
          {salesOrders.items.map((salesOrder: any) => (
              <tr
              className={`bg-white border-b dark:bg-gray-800  hover:bg-gray-50 dark:hover:bg-gray-600 ${selectedOrder === salesOrder.salesOrderId ? '_selected' : ''}`}
              key={salesOrder.salesOrderId}
         //     onClick={() => handleOrderClick(salesOrder.salesOrderId)}
         onClick={() => {
          handleOrderClick(salesOrder.salesOrderId);
          window.location.href = `/SalesOrder_v2/${salesOrder.salesOrderId}`;
        }}  
         onContextMenu={(event) => handleOrderContextMenu(event, salesOrder.salesOrderId)}
            >
              <td className="">{salesOrder.urgent? ("Urgent"):("")}</td>
              <td className="">#{salesOrder.salesOrderId}</td>
              <td className=""><SalesOrderDate createdDateTime={salesOrder.created_Date_Time} /></td>
              <td className="">{salesOrder.customerName}</td>
              <td className="">{salesOrder.total ? salesOrder.total.toLocaleString() : "0"} LKR</td>
              <td className="">{` ${salesOrder.salesRepFullName}`}</td>
              <td className=""><StatusDropdown_SO onStatusChange={()=>{}} id={salesOrder.salesOrderId} status={salesOrder.status} item={salesOrder}/></td>
            </tr>
          ))}
        </tbody>
      </table>
      <OrderContextMenu
        salesOrderId={selectedOrder}
        anchorEl={contextMenu.anchorEl}
        handleClose={handleContextMenuClose}
      />
    </div>
  );
}

interface OrderContextMenuProps {
  salesOrderId: string;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

function OrderContextMenu({ salesOrderId, anchorEl, handleClose }: OrderContextMenuProps) {

  const handleCopyLink = async () => {
    try {
      const currentUrl = window.location.href;
      const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/") + 1);
      const linkToCopy = `${baseUrl}SalesOrder/${salesOrderId}`;
      await navigator.clipboard.writeText(linkToCopy);
      handleClose();
    } catch (error) {
      console.error("Copy failed: ", error);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleCopyLink}>Copy Link</MenuItem>
    </Menu>
  );
}
