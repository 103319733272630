import React, { useEffect, useState, useRef } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Defualt from "../../../Loading_Defualt";
import { useAuthUser,useAuth } from '../../../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';
interface Brand {
  id: number;
  brandId:any;
  brand_Name: string;
  productcount: number;
  archCount: number;
  image_url: string;
}

export default function Brands_GridView() {
  const [brandsList, setBrandsList] = useState<Brand[]>([]);
  const [currentLetter, setCurrentLetter] = useState("");
  const letterRefs = useRef<{ [key: string]: HTMLElement }>({});
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const AuthUser = useAuthUser();
  const isAuth = useAuth();

  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles = ['Director','Developer','Praveen','Sales_Rep','Billing_Agent'];

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }
  function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchInput(e.target.value);
  }
  function clearLocalStorage() {
    localStorage.removeItem("brandsList");
    setBrandsList([]);
    setCurrentLetter("");
  }
  useEffect(() => {
    const storedData = localStorage.getItem("brandsList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setBrandsList(data);
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("brandsList");
      }
    }
  
    customaxios.get("product/Getproductbrands").then((response) => {
      setBrandsList(response.data);  
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("brandsList", JSON.stringify(data));
    });
  }, []);
  
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let closestLetter = null;
        let minDistance = Number.MAX_VALUE;
        entries.forEach((entry) => {
          const letter = entry.target.getAttribute("data-letter");
          if (entry.isIntersecting) {
            const distance = Math.abs(entry.boundingClientRect.top);
            if (distance < minDistance) {
              closestLetter = letter;
              minDistance = distance;
            }
          }
        });
        if (closestLetter !== null) {
          setCurrentLetter(closestLetter);
        }
      },
      { threshold: 1 }
    );

    Object.values(letterRefs.current).forEach((ref) => {
      observer.observe(ref);
    });
    return () => {
      observer.disconnect();
    };
  }, [currentLetter]);

  brandsList.sort((a, b) => (a.brand_Name ?? "").localeCompare(b.brand_Name ?? ""));

  const filteredBrands = brandsList.filter((brand) =>
  (brand.brand_Name ?? "").toLowerCase().includes(searchInput.toLowerCase())
);


const groupedBrands = filteredBrands.reduce((groups, brand) => {
  const firstLetter = (brand.brand_Name ?? "").charAt(0).toUpperCase();
  if (!groups[firstLetter]) {
    groups[firstLetter] = [];
  }
  groups[firstLetter].push(brand);
  return groups;
}, {});

if (loading) { // Display loading while fetching data
  return <Loading_Defualt/>;
}
  return (
    <div>
        <h3>Search Brands</h3>
         <input
      type="text"
      className="groupProductview"
      placeholder="Search brands..."
      value={searchInput}
      onChange={handleSearchInput}
    />
    <button onClick={clearLocalStorage}>Force_Re Cache</button>
      {Object.entries(groupedBrands).map(([letter, brands]: [string, Brand[]]) => (
        <div className="BrandPage" key={letter}>
          <h3
            className={`Letter ${currentLetter === letter ? "active" : ""}`}
            ref={(el) => {
              letterRefs.current[letter] = el!;
            }}
            data-letter={letter}
          >
            {letter}
          </h3>
          <div className="BrandCard_wrapper">
            {brands.map((brand) => (
                   <a key={brand.brandId} href={"/ProductsByBrand/"+ brand.brandId}>
                <div
                  className="BrandCard"
                  style={{
                    backgroundImage: brand.image_url
                      ? `url(${brand.image_url})`
                      : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p className="ItemName">
                    {!brand.image_url && brand.brand_Name}
                  </p>
                  <p className="ProductCount">
                    {"Products " + brand.productcount}
                  </p>
                  <p className="ArchCount">
                    {"Arch " + brand.archCount}
                  </p>
                </div>
                </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

}