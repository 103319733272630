import customaxios from "../../helpers/axios-helper";
import { useState, useEffect } from "react";
import { User } from "../../models/users/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Loading_Default from "../Loading_Defualt"; // Corrected the import name
import { useAuthUser } from "../../contexts/auth-context";
import Sms_Status from "./Sms_Status";
export default function UserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const AuthUser = useAuthUser();
let coutner_id = 0 ;
  useEffect(() => {
    if (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer" || AuthUser?.role === "Praveen") {
      customaxios
        .get(`User/GetAll/`)
        .then((res) => {
          setUsers(res.data);
          setIsLoading(false); // Data fetched, set loading to false
          console.log(users)
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false); // Even if there's an error, set loading to false
        });
    }
  }, [AuthUser]);

  const refreshAuth = (userId: any) => {
    customaxios
      .post(`User/CristalCodeForUser?id=${userId}`)
      .then((res) => {
        // Update the user data in the list of users
        const updatedUsers = users.map((user) => {
          if (user.id === userId) {
            // Update the user's auth code or any other relevant data
            user.cristal_Code = res.data.cristal_Code;
          }
          return user;
        });
        coutner_id = coutner_id+1;
        setUsers(updatedUsers);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

      
  };


  function calculateCountdown(expiryDate) {
    const now = new Date();
    const expiry = new Date(expiryDate);
    
    if (expiry <= now) {
      return "Expired";
    }
  
    const timeDifference = expiry.getTime() - now.getTime();
  
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
  
  return (
    <>
    <Sms_Status key={coutner_id}/>
      <h2>User Management</h2>
      <Countdown expiryDate={localStorage.getItem('expiry')} />
      {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer"|| AuthUser?.role === "Praveen") ? ( // Added parentheses for conditional rendering
        <>
          {isLoading ? (
            <Loading_Default /> // Show loading indicator while fetching data
          ) : (
            <table className="_Table_Custom">
              <thead>
                <tr className="_Table_Custom_Row Title">
                  <th>Email</th>
                  <th>Role</th>
                  <th>Phone Verified</th>
                  <th>Cristal Code</th>
                  <th>Validity</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr className="_Table_Custom_Row" key={user.id.toString()}> {/* Changed key to use toString() */}
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{user.phoneNumberConfirmed ? "True" : "False"} {" "+user.phoneNumber}</td>
                    <td   style={{
      color: user.cristal_Code && new Date(user.cristal_Code.expiery_Date_Time) < new Date() ? "red" : "green",
    }}>
  <span
    onClick={() => refreshAuth(user.id)}
    style={{ marginLeft: "5px", cursor: "pointer" }}
  >
    <FontAwesomeIcon icon={faSync} />
  </span>
  
  <span
  >
    {" "+user.cristal_Code?.auth_Code}
  </span>
</td>

<td>
  {user.cristal_Code ? (
    <Countdown expiryDate={user.cristal_Code.expiery_Date_Time} />
  ) : (
    "N/A" // Or any other fallback text
  )}
</td>
</tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      ) : (
        <>No Auth</>
      )}
    </>
  );
}


export function Countdown({ expiryDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateCountdown(expiryDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeRemaining = calculateCountdown(expiryDate);
      setTimeLeft(timeRemaining);

      if (timeRemaining === "Expired") {
        clearInterval(intervalId);
      }
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, [expiryDate]);

  function calculateCountdown(expiryDate) {
    const now = new Date();
    const expiry = new Date(expiryDate);

    if (expiry <= now) {
      return "Expired";
    }

    const timeDifference = expiry.getTime() - now.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (days === 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  }

  return <span>{timeLeft}</span>;
}