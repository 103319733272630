import { useState,useEffect, useRef, } from 'react';
import { useAuth } from '../../contexts/auth-context';
import Search from "../Product/Search/Search";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch } from '@fortawesome/free-solid-svg-icons';

import "./Style/Style.css"


export default function Search_Header() {
  
  const isAuth = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal

  const modalRef = useRef(null); // Reference to the modal
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  // Add a resize event listener to detect changes in screen size
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);


  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };


  const handleBackgroundClick = (event) => {
    // Close the modal if the click event target is the modal background
    if (event.target === modalRef.current) {
      setIsModalOpen(false);
    }
  };

  // Function to update the screen size state
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768); // Adjust the screen width as needed
  };


  return isAuth ? (
    <div className='search_header_wrapper'  >
 
      {isSmallScreen ? (
        <FontAwesomeIcon icon={faSearch}  onClick={handleModalToggle}/> // Render FontAwesome icon on small screens
      ) : (
        <Search /> // Render Search component on large screens
      )}
   

      {isModalOpen && (
        <div className="controls-modal" ref={modalRef} onClick={handleBackgroundClick}>
          <div  className={`controls-modal-content `}>
            <Search/>

          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
