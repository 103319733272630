import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";

import { AuthUser } from "../models/auth-user";

import { getAuthUserFromLS } from "../helpers/storage-helper";
import { initAxios } from "../helpers/axios-helper";


const AuthContext = createContext(null);
const AuthUserContext = createContext(null);
const AuthUpdateContext = createContext(null);

//Custom hooks
export const useAuth = () => {
    return useContext(AuthContext);
}
export const useAuthUser = () => {
    return useContext(AuthUserContext);
}
export const useAuthUpdate = () => {
    return useContext(AuthUpdateContext);
}

const isJWT : boolean = typeof Cookies.get('JWT') != 'undefined';   //Check if JWT is set or not
const initAuthUser : AuthUser = getAuthUserFromLS();  //Get user deets from local storage

export const AuthProvider = ({ children })  =>  {
    const [isAuth, setIsAuth] = useState(isJWT);
    const [authUser, setAuthUser] = useState<AuthUser>(initAuthUser);

    useEffect(() => {
        if(isAuth) {
            setAuthUser(getAuthUserFromLS());
            initAxios();
        }
        else{
            setAuthUser(null);
        }

        console.log("Auth is: " + isAuth);
    }, [isAuth]);
    
    const toggleAuth = () => {
        setIsAuth(!isAuth);
    }

    return (
        <AuthContext.Provider value={ isAuth }>
            <AuthUpdateContext.Provider value={ toggleAuth }>
                <AuthUserContext.Provider value={ authUser }>
                    {children}
                </AuthUserContext.Provider>
            </AuthUpdateContext.Provider>
        </AuthContext.Provider>
    );
};