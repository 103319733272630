// Define the action types as constants

// Define the productnamereducer to handle the SET_PRODUCT_NAME action
const Archimagereducer = (state = true, action) => {
    switch (action.type) {
      case "SET_Arch_Images":
        return !state; // Toggle the boolean value
      default:
        return state;
    }
  };

  export default Archimagereducer;