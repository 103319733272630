import { useEffect, useState, useCallback, } from "react";
import customaxios from "../helpers/axios-helper";
import Loading_Default from "../components/Loading_Defualt";
import './Style_Customer/Style.css'; 
import {  useAuthUser } from "../contexts/auth-context";
import TablePagination from "@mui/material/TablePagination";
import CustomerInformation from "./CustomerInformation";
interface Customer {
  customerId  :string ,
  customerName :string ,
  alias :string ,
  group_name :string ,
  opening_bal_in_base_currency :string ,
  prev_Bal :string ,
  addressline1 :string ,
  addressline2 :string ,
  addressline3 :string ,
  addressline4 :string ,
  Tel_no :string ,
  contact :string ,
  FaxNo :string ,
  Email :string ,
  Opt_field1 :string ,
  CustomerState :string ,
  logo_url :string ,
  verified_Customer:boolean,

}
export default function CustomerDashboard() {
  const AuthUser = useAuthUser();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const fetchCustomers = useCallback((pageNumber, pageSize) => {

    customaxios
      .get(`Customer/GetCustomers?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((response) => {
        setCustomers(response.data.results);
        console.log(response.data.results)
        setTotalCustomers(response.data.totalCustomers);
        if (customers.length > 0) {
          setSelectedCustomer(customers[0].customerId);
        }
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchCustomers(page + 1, rowsPerPage);
  }, [fetchCustomers, page, rowsPerPage]);


  const fetchCustomers_search = useCallback((pageNumber, pageSize, keyword = "") => {
   
    customaxios
      .post(`Customer/SearchCustomer`,
      {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize
      })
      .then((response) => {

        setCustomers(response.data.customers);
        setTotalCustomers(response.data.totalCustomers);
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const handleSearch = useCallback((event) => {
    const keyword = event.target.value;
    setPage(0);
    fetchCustomers_search(1, rowsPerPage, keyword);
  }, [fetchCustomers_search, rowsPerPage]);
 
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCustomers - page * rowsPerPage);
  if (loading) { // Display loading while fetching data
    return  <Loading_Default />;
  }
    return (

       <>
        <div className='CustomerDashboard'>
        <div className="CustomerList">
          <h3>Customers </h3>
         <div className="controls">
         <input className="Search_bar" type="text" placeholder="Search Customers..."   onChange={handleSearch} />
         {/*<button className="refresh" onClick={()=>{}}><FontAwesomeIcon icon={faSyncAlt} /> </button> */}
         </div>
                {loading ? (
              <Loading_Default />
            ) : (
              <div className="CustomerList_Item_Wrapper">
                {customers.map((customer: Customer) => (
                <div
                  className={`CustomerList_Item ${
                    customer.customerId === selectedCustomer ? "_selected" : ""
                  }`}
                  key={customer.customerId}
                  onClick={() => setSelectedCustomer(customer.customerId)}
                >
                  <div className="CustomerName">{customer.customerName}  
   </div>
                  {customer.logo_url && (
                    <div className="CustomerLogo">
                      <img src={customer.logo_url} alt="" />
                    </div>
                  )}
                  <div className="Count">
                    <div className="ProductCount">Prev Bal {customer.prev_Bal}</div>
                    <div className="ProductArchCount">
                      Opening Bal {customer.opening_bal_in_base_currency}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            )}
<TablePagination
  component="div"
  count={totalCustomers}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={(event, newPage) => setPage(newPage)}
  onRowsPerPageChange={(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }}
  rowsPerPageOptions={[5, 10, 25,100]}
  style={{overflow: 'hidden' }}
/>
        </div>
        <div className="CustomerDetails">
        <CustomerInformation id={selectedCustomer}/>
        </div>
      </div>

 


       </>

    )
}