// Define the action types as constants

// Define the action creator to toggle the productname property
export const toggleProductName = () => {
  return {
    type: "SET_PRODUCT_NAME"
  };
};

export const Togglezeroproduct = () => {
    return {
      type: "SET_ZERO_QTY"
    };
  };

  export const ProductLables = () => {
    return {
      type: "SET_PRODUCT_LABLES"
    };
  };
  export const ArchImage = () => {
    return {
      type: "SET_Arch_Images"
    };
  };
  export const discountvalue = (discount) => {
    return {
      type: "SET_Discount_Value",
      payload:discount
    };
  };

  export const Archminmise = () => {
    return {
      type: "SET_Arch_minmise_Value",
    };
  };

  export const Pos_Mode = () => {
    return {
      type: "SET_Pos_Mode",
    };
  };


  export const Purchase_Mode = () => {
    return {
      type: "SET_Purchase_Mode",
    };
  };