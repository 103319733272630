import ListExpenses from "./ListExpenses";
import CreateAccrudeCharges from "./CreateAccrudeCharges";
export default function  AccrudeCharges (){
    
    return(
    <>
   
      <CreateAccrudeCharges/>
        <br />
        <ListExpenses/>
    </>)
}