import React, { useState, useEffect } from "react";
import customAxios from "../../helpers/axios-helper";
import { useParams } from 'react-router-dom';

import { Link } from "react-router-dom";
interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function Customer_Route({ id: routeId }: { id: string | null }) {
    const { id: paramId } = useParams<RouteParams>();
    const id = paramId ? paramId : routeId;

  const [route, setRoute] = useState(null);

  useEffect(() => {
    // Fetch the specific route with towns
    //customAxios.get(`/CustomerRoute/GetRoute?id=${routeId}`).then((response) => {
    customAxios.get(`/CustomerRoute/GetRoute/${id}`).then((response) => {
        setRoute(response.data);
        console.log(route)
    });
  }, [id]);

  return (
    <div>
      {route ? (
        <div>
          <h2>Route ID: {route.routeId}</h2>
          <h3>Route Name: {route.name}</h3>
          <h3>Sales rep: {route.salesrep}</h3>
          <h4>Towns:</h4>

          <table className="Town_Table">
          <thead>
            <tr className="Town_Wrapper Title"> 
              <th className="Town_Id">ID</th>
              <th className="Town_Name">Town</th>
           
            </tr>
          </thead>
          <tbody>
            {route.towns.map((town) => (
              <tr className="Town_Wrapper"
                key={town.townId}
             //   onClick={() => handleTownClick(town)}
                style={{ cursor: "pointer" }}
              >
                <td className="Town_Id">{town.townId}</td>
                <td className="Town_Name">
                <a  href={`/Town/${town.townId}`}>
                {town.name}
                </a>
              
                </td>
           
              </tr>
            ))}
          </tbody>
        </table>

         
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
