import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../helpers/axios-helper";
import Chart from 'chart.js/auto';
import ProductStockLevels from "./Product_Stocks/ProductStockLevels";
import ProductInfo from "./ProductInfo";
import { useAuthUser } from '../../contexts/auth-context'; 
import "./Style/Style.css";
import Search from "./Search/Search";

interface RouteParams {
  id: string;
  [key: string]: string;
}
export interface StockLevel {
    stocklevelsId: number;
    product: string;
    levelDateTime: string;
    type: string;
    movement: number;
    currentlevel: number;
  }
export default function Product({ id: routeId }: { id: string | null }) {

    const { productid:paramId } = useParams<RouteParams>();
    const productid = paramId ? paramId : routeId;
    const AuthUser = useAuthUser();
    const [openingStock, setOpeningStock] = useState<number>(null);

  
    useEffect(() => {
      // get product data from custom axios
      customaxios.get(`Product/GetProduct/${productid}`)
        .then((res) => {
          setOpeningStock(res.data.stockBalances[0]?.openingBalance);

        })
        .catch((error) => {
          console.log(error);
        });
      }, [productid]);
  return (
    <>
     {AuthUser.role == "Director" || "Developer" ? (
<>
{/*<Search /> */}

<div className="product_page_wrapper">
<div className="product_info_page">

<>
<ProductInfo id={productid}  />
</>
</div>
  

<div className="Product_stocklevels">
{(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (// Added parentheses for conditional rendering
             
           
             <ProductStockLevels id={productid} opening_stock={openingStock} />  
             
             )}
</div>
</div>

</>
    ) : (<>No Auth</>)}
      
     
    </>
  );
  

  
};
