import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import "./Style/Style.css"
interface ProductStockBarChartProps {
  data: any[];
}

const Productchart: React.FC<ProductStockBarChartProps> = ({ data }) => {
  const [groupByMonth, setGroupByMonth] = useState(true);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  useEffect(() => {
    if (data.length > 0) {
      setFromDate(new Date(data[0].levelDateTime));
    }
  }, [data]);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setFromDate(selectedDate);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setToDate(selectedDate);
  };

  const toggleGroupByMonth = () => {
    setGroupByMonth(!groupByMonth);
  };

  const chartOptions: ChartOptions<'bar'> = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: groupByMonth ? 'month' : 'day',
          displayFormats: {
            month: 'MMM yyyy',
            day: 'MMM dd yyyy',
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: groupByMonth ? 20 : 30, // Adjust the maxTicksLimit as per your requirements
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const filteredData = filterDataByDateRange(data, fromDate, toDate);
  const chartData = groupByMonth
    ? getChartDataByMonth(filteredData, fromDate, toDate)
    : getChartData(filteredData, fromDate, toDate);

  return (

    <div className="Data_Draw_Chart">
    <button className='button_one' onClick={toggleGroupByMonth}>
        {groupByMonth ? 'Group by day' : 'Group by month'}
      </button>
      <div>
        <label htmlFor='fromDate'>From: </label>
        <input
          type='date'
          id='fromDate'
          name='fromDate'
          value={fromDate.toISOString().substr(0, 10)}
          onChange={handleFromDateChange}
        />
      </div>
      <div>
        <label htmlFor='toDate'>To: </label>
        <input
          type='date'
          id='toDate'
          name='toDate'
          value={toDate.toISOString().substr(0, 10)}
          onChange={handleToDateChange}
        />
      </div>
    <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

const getChartDataByMonth = (
  data: any[],
  fromDate: Date = new Date(),
  toDate: Date = new Date()
) => {
  const filteredData = filterDataByDateRange(data, fromDate, toDate);
  const stockInData = groupByMonth(filteredData, 'In');
  const stockOutData = groupByMonth(filteredData, 'out');
  const stockReturnData = groupByMonth(filteredData, 'RTN');
  const stockAdjustmentData = groupByMonthAdjustment(filteredData, 'ADJUSTMENT');
  const labels = Object.keys({
    ...stockInData,
    ...stockOutData,
    ...stockReturnData,
    ...stockAdjustmentData,
  }).sort();

  return {
    labels: labels.map((key) => {
      const [year, month] = key.split('-');
      return new Date(parseInt(year), parseInt(month));
    }),
    datasets: [
      {
        label: 'Stock In',
        data: labels.map((key) => stockInData[key] || 0),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
      {
        label: 'Stock Out',
        data: labels.map((key) => stockOutData[key] || 0),
        backgroundColor: '#25d44262',
        borderColor: '#25D442',
        borderWidth: 2,
      },
      {
        label: 'Stock Return',
        data: labels.map((key) => stockReturnData[key] || 0),
        backgroundColor: '#f4426262',
        borderColor: '#F44262',
        borderWidth: 2,
      },
      {
        label: 'Stock Adjustment',
        data: labels.map((key) => stockAdjustmentData[key] || 0),
        backgroundColor: 'rgba(255, 191, 0, 0.2)', // Amber color
        borderColor: 'rgba(255, 191, 0, 1)',
        borderWidth: 2,
      },
    ],
  };
};

const getChartData = (data: any[], fromDate: Date, toDate: Date) => {
  const filteredData = filterDataByDateRange(data, fromDate, toDate);
  const labels = filteredData.map((level) => new Date(level.levelDateTime));

  const stockInData = {
    label: 'Stock In',
    data: filteredData.map((level) => (level.type === 'In' ? level.movement : 0)),
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 2,
  };

  const stockOutData = {
    label: 'Stock Out',
    data: filteredData.map((level) => (level.type === 'out' ? level.movement : 0)),
    backgroundColor: '#25d44262',
    borderColor: '#25D442',
    borderWidth: 2,
  };

  const stockReturnData = {
    label: 'Stock Return',
    data: filteredData.map((level) => (level.type === 'RTN' ? level.movement : 0)),
    backgroundColor: '#f4426262',
    borderColor: '#F44262',
    borderWidth: 2,
  };

  const stockAdjustmentData = {
    label: 'Stock Adjustment',
    data: filteredData.map((level) => (level.type === 'ADJUSTMENT' ? level.movement : 0)),
    backgroundColor: 'rgba(255, 191, 0, 0.2)', // Amber color
    borderColor: 'rgba(255, 191, 0, 1)',
    borderWidth: 2,
  };

  return {
    labels: labels,
    datasets: [stockInData, stockOutData, stockReturnData, stockAdjustmentData],
  };
};

const filterDataByDateRange = (data: any[], fromDate: Date, toDate: Date) => {
  return data.filter((level) => {
    const levelDate = new Date(level.levelDateTime);
    return levelDate >= fromDate && levelDate <= toDate;
  });
};

const groupByMonth = (data: any[], type: string) => {
  const filteredData = data.filter((level) => level.type === type);
  const groupedData: { [key: string]: number } = {};

  filteredData.forEach((level) => {
    const date = new Date(level.levelDateTime);
    const monthYear = `${date.getFullYear()}-${date.getMonth()}`;

    if (!groupedData[monthYear]) {
      groupedData[monthYear] = 0;
    }

    groupedData[monthYear] += level.movement;
  });

  return groupedData;
};

const groupByMonthAdjustment = (data: any[], type: string) => {
  const filteredData = data.filter((level) => level.type === type);
  const groupedData: { [key: string]: number } = {};

  filteredData.forEach((level) => {
    const date = new Date(level.levelDateTime);
    const monthYear = `${date.getFullYear()}-${date.getMonth()}`;

    if (!groupedData[monthYear]) {
      groupedData[monthYear] = 0;
    }

    groupedData[monthYear] += level.movement;
  });

  return groupedData;
};

export default Productchart;
