// Define the action types as constants

// Define the productnamereducer to handle the SET_PRODUCT_NAME action
 const Pos_Mode = (state = false, action) => {
    switch (action.type) {
      case "SET_Pos_Mode":
        return !state; // Toggle the boolean value
      default:
        return state;
    }
  };

  export default Pos_Mode;