import ListExpenses from "./ListExpenses";
import CreateLong_Term_Liability from "./CreateLong_Term_Liability";
export default function  Long_Term_Liability (){
    
    return(
    <>
   
      <CreateLong_Term_Liability/>
        <br />
        <ListExpenses/>
    </>)
}