const alphUpperExp = /[A-Z]/;
const alphLowerExp = /[a-z]/;
const numExp = /[0-9]/;

export const doesContainAlphabets = (pwd : string) => {
    return alphUpperExp.test(pwd) || alphLowerExp.test(pwd);
}
export const doesContainAlphabetsWithCase = (pwd : string, isUpper: boolean) => {
    var doesContain = false;

    isUpper ? doesContain = alphUpperExp.test(pwd) : doesContain = alphLowerExp.test(pwd)

    return doesContain;
}

export const doesContainNumbers = (pwd : string) => {
    return numExp.test(pwd);
}

export const doesContainNumOfChars = (pwd : string, num : Number) => {
    return pwd ? pwd.length >= num : false;
}

export const doesMatch = (pwd : string, cPwd : string) => {
    return pwd === cPwd;
}