import { useEffect, useState, useCallback, } from "react";
import customaxios from "../helpers/axios-helper";
import Loading_Default from "../components/Loading_Defualt";
import './Style_Customer/Style.css'; 
import {  useAuthUser,useAuth } from "../contexts/auth-context";
import TablePagination from "@mui/material/TablePagination";
import { CheckCircle } from '@mui/icons-material';
import { Link } from "react-router-dom";
import CustomerInformation from "./CustomerInformation";


import { useNavigate } from 'react-router-dom';
interface Customer {
  customerId  :string ,
  customerName :string ,
  alias :string ,
  group_name :string ,
  opening_bal_in_base_currency :string ,
  prev_Bal :string ,
  addressline1 :string ,
  addressline2 :string ,
  addressline3 :string ,
  addressline4 :string ,
  Tel_no :string ,
  contact :string ,
  FaxNo :string ,
  Email :string ,
  Opt_field1 :string ,
  CustomerState :string ,
  logo_url :string ,
  verified_Customer:boolean,
town:any,

}
export default function Customer_Dash_V2() {
  const AuthUser = useAuthUser();
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const isAuth = useAuth();

  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles =['Director','Developer','Praveen','Billing_Agent'] ;

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  const fetchCustomers = useCallback((pageNumber, pageSize) => {

    customaxios
      .get(`Customer/GetCustomers?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((response) => {
        setCustomers(response.data.results);
        console.log(response.data.results)
        setTotalCustomers(response.data.totalCustomers);
        if (customers.length > 0) {
          setSelectedCustomer(customers[0].customerId);
        }
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchCustomers(page + 1, rowsPerPage);
  }, [fetchCustomers, page, rowsPerPage]);


  const fetchCustomers_search = useCallback((pageNumber, pageSize, keyword = "") => {
   
    customaxios
      .post(`Customer/SearchCustomer`,
      {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize
      })
      .then((response) => {

        setCustomers(response.data.customers);
        setTotalCustomers(response.data.totalCustomers);
      setLoading(false); // Set loading to false once data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const handleSearch = useCallback((event) => {
    const keyword = event.target.value;
    setPage(0);
    fetchCustomers_search(1, rowsPerPage, keyword);
  }, [fetchCustomers_search, rowsPerPage]);
 
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCustomers - page * rowsPerPage);
  if (loading) { // Display loading while fetching data
    return  <Loading_Default />;
  }
    return (

      <>
      <div className="CustomerList">
        <div className="controls">
          <input
            className="search_Primary"
            type="text"
            placeholder="Search Customers..."
            onChange={handleSearch}
          />
        </div>
        {loading ? (
          <Loading_Default />
        ) : (
        <>
         <TablePagination
          component="div"
          count={totalCustomers}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          rowsPerPageOptions={[25,50, 100,1000,]}
          style={{ overflow: 'hidden' }}
        /> 
          <table className="CustomerList_Table">
            <thead>
              <tr className="Customer_Row Title">
                <th className="CustomerName">Customer</th>
             
                <th  className="Town">Town</th>
                <th className="route_name">Route</th>
                <th>Sales rep</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer: Customer) => (
              <>
              <tr
                className="Customer_Row"
                >
                  
                  <td className="CustomerName">
                  <a  href={`/CustomerInformation/${customer.customerId}`}>
  {customer.verified_Customer ? (
    <span dangerouslySetInnerHTML={{ __html: "&#10004;" }} style={{ color: "green" }} />
  ) : null}
  {customer.customerName}
</a>

 </td>
            
             
                  <td className="Town">
        <a href={`/Town/${customer.town?.townId}`}>
        {customer.town?.name}
    </a>
                  </td>
                  <td className="route_name">
                  <a  href={`/route/${customer.town?.route.routeId}`}>
                  {customer.town?.route.name}
    </a>
                  
                  </td>
                  <td className="route_salesrep">
                   {customer.town?.route.salesrep}
                  </td>
                </tr>
              </>
              ))}
            </tbody>
            <TablePagination
          component="div"
          count={totalCustomers}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          rowsPerPageOptions={[25,50, 100,1000,]}
          style={{ overflow: 'hidden' }}
        />
          </table>
           
        </>
        )}
       
      </div>
    </>
    

    )
}



