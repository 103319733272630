import React, { useState,useCallback } from 'react';
import { toast } from 'react-toastify';
import customaxios from '../../../helpers/axios-helper';
import { useAuthUser } from '../../../contexts/auth-context';
import { Menu, MenuItem, IconButton, Modal } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "./Status_Model.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface StatusDropdownProps {
  ids:any[];
  onStatusChange: (id: Number, newStatus: string) => void;
}

const StatusDropdown_Bulk: React.FC<StatusDropdownProps> = ({ ids,  onStatusChange }) => {
 // const [orderStatus, setOrderStatus] = useState(status);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuthUser();
  console.log("ids",ids)

  const handleSelectedItemsStatusChange = async ( newStatus: string) => {
console.log("ids",ids)
    try {
      // Create an array to store all the promises for the requests
      const promises = ids.map(async (id) => {
        const response = await customaxios.post(`OrdeOrderrs/UpdateOrderStatus/${id}/${newStatus}`);
        onStatusChange(id, newStatus);
        return response.data;
      });
  
      // Wait for all promises to resolve
      const responses = await Promise.all(promises);
  
      // Check for any failed updates
      const failedUpdates = responses.filter((response) => response.error);
  
      if (failedUpdates.length > 0) {
        const failedIds = failedUpdates.map((response) => response.id);
        toast.error(`Failed to update status for Line Items: ${failedIds.join(', ')}`);
      } else {
        toast.success(`Successfully updated status for Order # ${ids.join(', ')}`);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while updating the status');
    }
  };
  


  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleOptionSelect = (newStatus: string) => {
    handleSelectedItemsStatusChange(newStatus);
    handleMenuClose();
  };

  return (
    <>
     <div className="status">
     <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon  />
      </IconButton>
     </div>
   
     <Modal
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <Menu
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={`State_selector_orders `}
          >
            <h3>Item Status</h3>
               <List>
               {
                  /*
                  ["Pending", "Approved", "Declined", "ReadyToPack", "ReadyToDispatch", "Dispatch", "Invoiced", "Refund"]
                  */
                }
              {["Pending", "Approved", "Declined", "Invoiced", "Refund"].map(option => (    <ListItem
                  key={option}
                  onClick={() => {
                    handleOptionSelect(option);
                    handleMenuClose();
                  }}
                  className={`options_${option}`}
                >
                  <ListItemText primary={option} />
                </ListItem>
              ))}
            </List>
          </Menu>
        </div>
      </Modal>
    
    </>
  );
};

export default StatusDropdown_Bulk;









{
  /*
  
  <select
  className={`State_selector_orders ${orderStatus}`}
  value={orderStatus}
  onChange={(event) => handleStatusChange(event.target.value)}
  disabled={!["CEO", "Director", "Developer", "Billing"].includes(auth?.role)}
>
  {["CEO", "Director", "Developer", "Billing"].includes(auth?.role) ? (
    <>
      <option className="options_Pending" value="Pending">
        Pending
      </option>
      <option className="options_Approved" value="Approved">
        Approved
      </option>
      <option className="options_Declined" value="Declined">
        Declined
      </option>
      <option className="options_ReadyToPack" value="ReadyToPack">
        Ready To Pack
      </option>
      <option className="options_ReadyToDispatch" value="ReadyToDispatch">
        Ready To Dispatch
      </option>
      <option className="options_Dispatch" value="Dispatch">
        Dispatch
      </option>
      <option className="options_Invoiced" value="Invoiced">
        Invoiced
      </option>
      <option className="options_Refund" value="Refund">
        Refund
      </option>
    </>
  ) : (
    <option className={`options_${orderStatus}`} value={orderStatus}>
      {orderStatus}
    </option>
  )}
</select>
  */
}