

function QuantityInput({ value, onChange }) {

  
    return (
      <div className="Quantity">
  <div className="quantity-input">
            <p>Quantity:</p>
      <div className="quantity-control">
      {
        /*    <button className="decrement-button" onClick={handleDecrement}>
          <RemoveCircleOutlineIcon />
        </button>
       */
      }     <input type="number" className="quantity-field" value={value} onChange={(e) => onChange(parseInt(e.target.value))} />
        {
        /*
          <button className="increment-button" onClick={handleIncrement}>
          <AddCircleOutlineIcon />
        </button>
        */
      }   </div>
    </div>
      </div>
      
    );
  }
  export default QuantityInput;