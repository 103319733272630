import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import customAxios from "../../helpers/axios-helper";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "./Style/Style.css"
export default function Routes() {
  const [routes, setRoutes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    // Fetch all routes
    customAxios.get("/CustomerRoute/All_Routes").then((response) => {
      setRoutes(response.data);
      console.log(response.data);
    });
  }, []);

  const handleRouteNameChange = (event, index) => {
    const updatedRoutes = [...routes];
    updatedRoutes[index].name = event.target.value;
    setRoutes(updatedRoutes);

  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
  };

  const handleUpdateRouteName = (route, index) => {
    customAxios
      .post("/CustomerRoute/Update_Route_Name", route)
      .then((response) => {
        console.log(response.data); // Updated route object from the server
        setEditingIndex(null);
        toast.success("Updated Route Name")
      });
  };

  return (
    <div>
      <table className="Routes_Table">
        <thead>
    <tr className="Routes_Table_row Title">
      <th className="Route_id">Route ID</th>
      <th className="Route_name">Name</th>
      <th className="Route_Action"></th>
      <th className="Route_Sales_rep">SalesRep</th>
    </tr>
  </thead>
  <tbody>
            {routes.map((route, index) => (
              <tr className="Routes_Table_row" key={route.routeId}>
                <td className="Route_id">{route.routeId}</td>
                <td className="Route_name">
  {editingIndex === index ? (
    <TextField
      value={route.name}
      onChange={(event) => handleRouteNameChange(event, index)}
      fullWidth
    />
  ) : (
    <a  href={`/route/${route.routeId}`}>
      {route.name}
    </a>
  )}
</td>

<td className="Route_Action">
  {editingIndex === index ? (
    < p
      onClick={() => handleUpdateRouteName(route, index)}
      color="primary"
      style={{ fontSize: '13px' }} // Add inline style for font size
    >
     <span dangerouslySetInnerHTML={{ __html: "&#10004;" }} style={{ color: "green" }} />

    </p>
  ) : (
    < p
      onClick={() => handleEditClick(index)}
      color="primary"
      style={{ fontSize: '13px' }} // Add inline style for font size
    >
        <EditIcon  style={{ fontSize: '13px' }} />
    </ p>
  )}
</td>

                <td className="Route_Sales_rep">
                  {route.salesrep                  }
                </td>
               
              </tr>
            ))}
          </tbody>
          </table>
    </div>
  );
}
