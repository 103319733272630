import React, { useEffect, useState } from "react";
import $ from "jquery";

import { UserRegister } from "../../models/users/user-register";

import { disableLoad, enableLoad } from "../../components/loader";

import { DISTRICTS } from "../../utils/scripts";
import customaxios from "../../helpers/axios-helper";
import CheckPasswordRequirements from "../password-requirements";


export default function Register() {
    const [form, setForm] = useState<UserRegister>({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        /*addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",*/
    });
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);


    useEffect(() => {
        $('#register-form').css({ height: `${$('#register-form-personal').outerHeight()}px` }); //Set height of form
    }, []);


    var Districts : JSX.Element[] = [];
    DISTRICTS.forEach(district => {
        Districts.push(<option key={district} value={district}>{district}</option>);
    });

    var currentFs : JQuery, nextFs : JQuery;  //Fieldsets

    //Form progress handling
    const nextStep = (e) => {
        if(e) e.preventDefault();

        if(!checkIfFsEmpty('personal')) {
            alert('Please fill all fields before proceeding.');
        }
        else {
            if(!isPasswordValid) {
                $('#register-form-requirements > li:not([class]),li[class=""]').addClass('failed');
            }
            else {
                currentFs = $('#register-form-personal');
                nextFs = currentFs.next();

                currentFs.removeClass().addClass('completed');
                nextFs.removeClass().addClass('active');

                $('#register-progressbar > li:nth-child(2)').addClass('active');
            }
        }
    }
    const previousStep = (e?) => {
        if(e) e.preventDefault()

        currentFs = $('#register-form-contact');
        nextFs = currentFs.prev();

        currentFs.removeClass().addClass('inactive');
        nextFs.removeClass().addClass('active');

        $('#register-progressbar > li:nth-child(2)').removeClass('active');
    }

    //Fieldset check
    const checkIfFsEmpty = (id) => {
        if(id === 'personal') {
            if(form.email !== '' && 
                form.password !== '' && 
                form.firstName !== '' &&  
                form.lastName !== '') {
                return true;
            }
        }
        else if(id === 'contact') {
            /*if(form.addressLine1 !== '' &&
                form.city !== '' &&  
                form.postalCode !== '' &&
                form.phoneNumber !== '') {
                return true;
            }*/
            if(form.phoneNumber !== '') {
                return true;
            }
        }

        return false;
    }

    function updateForm(value) {
        return setForm((prev) => {
            console.log(value);
            return { ...prev, ...value };
        });
    }
    const setPassword = (value: string) => {
        updateForm({ password: value });
    }

    const processRegistration = (e?) => {
        e.preventDefault();

        if(!checkIfFsEmpty('contact')) {
            alert('Please fill all fields before proceeding.');
        }
        else {
            if(isPasswordValid) {
                console.log(form);
        
                enableLoad();
    
                customaxios.post('register/register', form)
                .then((response) => {
                    console.log(response);
                    
                    if(response.data === 'Success'){
                        $('#register-form').trigger("reset");
                        previousStep();
    
                        alert('Registration successful');
                    }
                    else{
                        previousStep();
                        alert(response.data);
                    }
                    
                    disableLoad();
                })
            }
            else{
                previousStep();
            }
        }
    }
    
    
    return (
        <section className="register">
          <h1>Register</h1>

          <ul className="register-progressbar" id="register-progressbar">
              <li className="active"></li>
              <li></li>
          </ul>
          
          <div className="register-form-wrapper">
              <form className="register-form" id="register-form" onSubmit={processRegistration}>
                  <fieldset id="register-form-personal" className="active">
                        <h2>Personal Information</h2>

                        <div className="inline-inputs">
                            <div className="floating-input">
                                <input type="text" id="register-firstname" placeholder=" " required
                                
                                onChange={(e) => updateForm({ firstName: e.target.value.trim() })} />

                                <label htmlFor="register-firstname">First Name</label>
                            </div>

                            <div className="floating-input">
                                <input type="text" id="register-lastname" placeholder=" " required
                                
                                onChange={(e) => updateForm({ lastName: e.target.value.trim() })} />

                                <label htmlFor="register-lastname">Last Name</label>
                            </div>
                        </div>

                        <div className="floating-input">
                            <input type="email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title="Ex: john@zmail.com" id="register-email" placeholder=" " required
                            
                            onChange={(e) => updateForm({ email: e.target.value.trim().toLowerCase() })} />

                            <label htmlFor="register-email">Email</label>
                        </div>

                        <CheckPasswordRequirements
                        checkPasswordMatch={true}
                        setIsPasswordValid={setIsPasswordValid}
                        setPassword={setPassword} />

                      <div className="inline-btns">
                          <input type="button" id="register-next" value="Next" onClick={(e) => nextStep(e)} />
                      </div>
                  </fieldset>
                  
                  <fieldset id="register-form-contact" className="inactive">
                      <h2>Contact Information</h2>

                      {/* <div className="floating-input">
                          <input type="text" id="register-addressline1" placeholder=" " required
                          
                          onChange={(e) => updateForm({ addressLine1: e.target.value.trim() })} />

                          <label htmlFor="register-addressline1">Address Line 1</label>
                      </div>
                      
                      <div className="floating-input">
                          <input type="text" id="register-addressline2" placeholder=" "
                          
                          onChange={(e) => updateForm({ addressLine2: e.target.value.trim() })} />

                          <label htmlFor="register-addressline2">Address Line 2 (Optional)</label>
                      </div>
                      
                      <div className="inline-inputs">
                          <div className="floating-input">
                              <select className="selector" id="register-city" required
                              onChange={(e) => updateForm({ city: e.target.value.trim() })}>
                                  {Districts}
                              </select>

                              <label htmlFor="register-city">City</label>
                          </div>
                          
                          <div className="floating-input">
                              <input type="text" id="register-postalcode" placeholder=" " required
                              
                              onChange={(e) => updateForm({ postalCode: e.target.value.trim() })} />

                              <label htmlFor="register-postalcode">Postal Code</label>
                          </div>
                      </div> */}
                      
                      <div className="floating-input">
                          <input type="tel" id="register-phonenumber" placeholder="07xxxxxxxx" maxLength={10} minLength={10} required
                          
                          onChange={(e) => updateForm({ phoneNumber: e.target.value.trim() })} />

                          <label htmlFor="register-phonenumber">Phone Number</label>
                      </div>

                      <div className="inline-btns">
                          <input type="button" id="register-prev" value="Previous" onClick={(e) => previousStep(e)} />

                          <input type="submit" id="register-submit" value='Submit' />
                      </div>
                  </fieldset>
              </form>
          </div>
        </section>
    );
}