import React from "react";
import { useLocation } from "react-router-dom";
import { CategoryModel } from "./Model/CategoryModel_";
import customaxios from "../helpers/axios-helper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


import { Bar,Line} from 'react-chartjs-2';
import 'chart.js';


export default function ProductPage(props) {

    //get month lol
    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = new Date();
//document.write("The current month is " + monthNames[item.levelDateTime.getMonth()]);


    const location = useLocation();
    //console.log(location);
    console.log(location);
    console.log("product id")
    console.log(location.state.productId);
    
    //create product list 
    const [stocklevel, setItems] = useState([]);
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('Stock/GetStockLevelsByProductId/',
        {            productid: location.state.productId,
        })

            .then((response) => {
              // console.log(response);
                 console.log(response);
                 console.log(response.data);
                setItems(response.data);
                
            })
    }, []);

    const reportlable = [];
    stocklevel.map((item) => {
        reportlable.push( item.levelDateTime.toLocaleString('en-US', { month: 'short' } )+ " " + item.levelDateTime.getFullYear());
     //   reportlable.push( monthNames[item.levelDateTime.getMonthShortName()] + " " + item.levelDateTime.getFullYear());
    },[])
    const outdata = [];
    const Indata = [];

    stocklevel.map((item) => {

        if(item.type =="out" )
{
    outdata.push(item.movement);

}
if(item.type =="In" ){
    Indata.push(item.movement);

}
    }
    ,[])

const data = {
        labels: reportlable,
        datasets: [
            {
                label: location.state.productName,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: outdata
            },
            {
                label: location.state.productName,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: Indata
            }
        ]
    };
    
    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return(

        <div className="groupProductview">
              <div>
        <h2>{location.state.productName}</h2>
        <Bar data={data} options={options} 
        
        />
    </div>
                
             
      

        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>stocklevelsId</TableCell>
            <TableCell>movement</TableCell>
            <TableCell>type</TableCell>
            <TableCell align="left">Month</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {stocklevel.map((row) => (
             <TableRow 
              key={row.stocklevelsId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
          
              <TableCell align="left">{row.stocklevelsId}</TableCell>
              <TableCell align="left">{row.movement}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{monthNames[row.levelDateTime.getMonth()] }</TableCell>
          
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
        </div>

       
    );

}