import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
export interface Cheque {
  chequeNumber: string;
  bank: string;
  amount: number;
  chequeDate: Date;
  state: string;
}

interface ChequeFormProps {
  onAddCheque: (cheque: Cheque) => void;
}

const ChequeForm: React.FC<ChequeFormProps> = ({ onAddCheque }) => {
  const [cheque, setCheque] = useState<Cheque>({
    chequeNumber: '',
    bank: '',
    amount: 0,
    chequeDate: new Date(),
    state: '',
  });
  const handleDateChange = (date: Date) => {
    setCheque((prevCheque) => ({ ...prevCheque, chequeDate: date }));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCheque((prevCheque) => ({ ...prevCheque, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAddCheque(cheque);
    setCheque({
      chequeNumber: '',
      bank: '',
      amount: 0,
      chequeDate:new Date(),
      state: '',
    });
  };

  return (
    <>
   
    
    <form className='cheque_form' onSubmit={handleSubmit}>
        <p className='title_cheque_form'>Create Cheque</p>
        <input
          type="text"
          name="chequeNumber"
          value={cheque.chequeNumber}
          onChange={handleChange}
          placeholder='Cheque Number'
          className='cheque_input_Number'
        />
      <br />
    
        <input
          type="text"
          name="bank"
          value={cheque.bank}
          onChange={handleChange}
            placeholder='Bank'
            className='cheque_input_bank'
        />
      <br />

        <input
          type="number"
          name="amount"
          value={cheque.amount}
          onChange={handleChange}
          placeholder='Amount'
            className='cheque_input_amount'
        />
      <br />
   
      <div className="cheque_input_date">
      <DatePicker
        selected={cheque.chequeDate}
        onChange={handleDateChange}
        placeholderText="Cheque Date"
        className="cheque_input_date"
        showTimeSelect
        dateFormat="yyyy-MM-dd HH:mm:ss"
        timeFormat="HH:mm:ss"
      />
    </div>
     
      <br />

        <input
          type="text"
          name="state"
          value={cheque.state}
          onChange={handleChange}
        placeholder='State'
        className='cheque_input_state'
        />
      <br />
      <button  className='add_cheque_button button_one' type="submit">Add Cheque</button>
    </form>
    </>
  );
};

export default ChequeForm;
