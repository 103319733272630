import React, { useRef,useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import "./Style/Style.css"
import LetterHead from '../../assets/Print/SVG/Letter Head.svg';
import {  useAuthUser } from "../../contexts/auth-context";
import Footer from '../../assets/Print/SVG/Footer.svg';
import numberToWords from 'number-to-words';
interface PrintObjectProps {
  voucher: any;
}

const PrintInvoice: React.FC<PrintObjectProps> = ({ voucher} ) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [visible , setVisible] = useState(false);
  const AuthUser = useAuthUser();
//  console.log(voucher)
  
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});
  async function handlePrint_options() {
    setVisible(true);
    await new Promise((resolve) => setTimeout(resolve,.00002));
    handlePrint();
    setVisible(false);
  }
  
//console.log(voucher)
  return (
  <div>
     {AuthUser.role === "Director" || AuthUser.role === "Praveen" || AuthUser.role === "Billing_Agent" || AuthUser.role === "Developer" ? (
    <button  className="bg-teal-500 text-white px-4 py-2 rounded" onClick={handlePrint_options}>Print</button>
     ):(<></>)}
      <div ref={componentRef}>
    <div className={`print_invoice ${visible ? 'visible' : 'hidden'}`}> 
    <br />

    {/*<div className={`printvoucher`}>*/}
   <div className="letterhead">
   {
  (() => {
    switch (voucher.billing_Group_Contained.name) {
      case "OAM":
        return <img src={LetterHead} alt="LetterHead_invoice" />;
      case "RAH":
        return <p>RAH Bill</p>;
      case null:
        return <p>without letter head</p>;
      // Add more cases for other billing groups as needed
      default:
        return null; // Default case if no match is found
    }
  })()
}

     
      </div>
      <div className="Information grid grid-cols-5 grid-rows-1 ">
      <div className="col-span-2 row-span-2">
      <p>Customer Details</p>
      <p>{voucher.customer.customerName}</p>
      <p>{voucher.customer.addressline1}</p>
      <p>{voucher.customer.primary_Contact
}</p>
  </div>

  <div className="col-span-2 row-span-2 col-start-3">

{
            voucher.creditDays ? (
            <>
            <p>Payment Mode : Credit</p>
            <p>Credit Period : {voucher.creditDays} Days</p>
            </>)
            :(<p>Payment Mode: Cash</p>)
          }
        
        <p>Payment Due: {voucher.paymentDue?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</p>
        <td><strong>Due Date:</strong> {calculateDueDate(voucher.createdDateTime, voucher.creditDays).toLocaleDateString()}</td>
  
      </div>



      <div className="row-span-2 col-start-5">
        
        <p>Invoice Date {voucher.createdDateTime.toLocaleDateString()}</p>
        <p>Invoice Number {voucher.invoice_Id_Simplified}</p>

       <p>Sales Rep: {voucher.order.salesRep.firstName
} {voucher.order.salesRep.lastName

}</p>
        </div>
       
      </div>


      <div className="items _invoice">
<Items items={voucher}/>
 </div>
 <div className="footer_invoice">
  <br/>
  <br/>
  <br/>
  <table className=" mx-auto my-4">
  <tbody>
    <tr>

      <td className=" p-2">
        <div className="mb-4">..................................................</div>
        <div className='text-sm'>Cheque/Cash</div>
      </td>

      <td className=" p-2">
        <div className="mb-4">..................................................</div>
        <div className='text-sm'>Checked By</div>
      </td>

      <td className=" p-2">
        <div className="mb-4">..................................................</div>
        <div className='text-sm'>Received By</div>
      </td>

      <td className="p-2">
        <div className="mb-4">..................................................</div>
        <div className='text-sm' >Sold By</div>
      </td>
    </tr>
  </tbody>
</table>

  
    </div>
    </div>
   


    </div>
 
   
</div>

  );
};

export default PrintInvoice;
function Items({items}){

  return(

    <div className="Invoice_Items   _print">

    <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
        <thead  className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
         
            <th>Product</th>
            <th>Price</th>
            <th>Discount  </th>
            <th>Qty</th>
            <th >Total</th>

          </tr>
        </thead>
        <tbody>
          {items.orderItems.map((item) => (
            <tr key={item.id} className=' bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" '>
    
              <td><p className='font-medium '>{item.product.productName}</p> <p className='text-xs'>{item.product.oaM_ACode} - {item.product.suppliercode}</p></td>
              <td>
              {item.unitPrice?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}
              </td>
              <td>{item.discount_Percentage.toLocaleString()} %</td>
              <td>{item.quantity.toLocaleString()}  {item.quantity_Unit}</td>
              <td className='subtotal'> {item.total_Amount?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })} </td>
            </tr>
          ))}
           <tr >
           <td>
  <p style={{ textAlign: 'center', fontSize: '12px', overflowWrap: 'break-word', maxWidth: '50px' }}>
    <strong>LKR :</strong> {formatNumberToWords(items.paymentDue)}
  </p>
</td>

              <td></td>
              <td></td>
              <td><strong>Grand Total</strong></td>
              <td className='subtotal'><strong>{items.paymentDue?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</strong></td>
             
            </tr>
          
        </tbody>
    
      </table>

      
       
    </div>
  )
}
{
  /*
     <tr>
       <p style={{ textAlign: 'center', fontSize: '12px' }}>
  <strong>LKR :</strong> {formatNumberToWords(items.paymentDue)}
</p>
       </tr>
  */
}
// Function to format the number to words
export function formatNumberToWords(number) {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 100); // Assuming 2 decimal places

  const integerWords = numberToWords.toWords(integerPart);
  const decimalWords = numberToWords.toWords(decimalPart);

  const currencyWords = 'LKR'; // You can customize the currency words

  let result = `${integerWords.charAt(0).toUpperCase()}${integerWords.slice(1)}`;
  if (decimalPart > 0) {
    result += ` and ${decimalWords} cents`;
  }
  //return `${result} ${currencyWords} only`;
  return `${result}  only`;
}

// Function to calculate due date
export function calculateDueDate(createdDateTime, creditDays) {
  const dueDate = new Date(createdDateTime);
  dueDate.setDate(dueDate.getDate() + creditDays);
  return dueDate;
}