import PropTypes from 'prop-types';


function NettPriceInput({ value, onChange }) {

  return (
   
    <div className="Nett_Price">
         <div className="Nett_Price-input">
      <p>Nett Price:</p>
      <div className="Nett_Price-control">
      {
        /*    <button className="decrement-button" onClick={handleDecrement}>
          <RemoveCircleOutlineIcon />
        </button>
       */
      } 
        <input
          type="number"
          className="discount-field"
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
         {
        /*
          <button className="increment-button" onClick={handleIncrement}>
          <AddCircleOutlineIcon />
        </button>
        */
      }
      </div>
    </div>
    </div>
  );
}

NettPriceInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NettPriceInput;
