import React, { useState } from 'react';
import ProductHistoryTabs from './ProductHistoryTabs '; // Assuming this is the path to your ProductHistoryTabs component
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuth, useAuthUser } from "../../../../contexts/auth-context";
import EditProduct_Brand from "../../Product_Brand_Edit";

function MovementComparison({ product_Stats }) {
  const [isMovementOpen, setIsMovementOpen] = useState(false);
  const AuthUser = useAuthUser();

  const toggleMovement = () => {
    setIsMovementOpen(!isMovementOpen);
  };
//console.log(product_Stats)
  return (
    <div className="movement_comparison">
       <div className={`toggle-header ${isMovementOpen ? 'open' : 'closed'}`} onClick={toggleMovement}>
       <h3>Movement {isMovementOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</h3>
      
      </div>
      {isMovementOpen &&
        product_Stats.map((productStats: any) => (
            <div key={productStats.product.productId} className={`product-card ${isMovementOpen ? 'open' : 'closed'}`}>
            {/* Product Info */}
            <div className="product-info">
              <h2>{productStats.product.productName}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Supplier Code</th>
                    <td>{productStats.product.supplierCode}</td>
                  </tr>
                  <tr>
                    <th>OAM Code</th>
                    <td>{productStats.product.oaM_ACode}</td>
                  </tr>
                  <tr>
                    <th>Brand</th>
                    {
  (AuthUser.role === "Director" || AuthUser.role === "Developer")  ? 
    <td><EditProduct_Brand product={productStats.product} /></td> 
  : 
    <td>{productStats.product.brand.brand_Name}</td>
}       </tr>
                  <tr>
                    <th>Current Stock</th>
                    <td>: {productStats.product.productQuantity.quantity.toLocaleString('en-GB', { maximumFractionDigits: 2 })} {productStats.product.productQuantity.quantity_Units}</td>
                    </tr>
                  <tr>
                    <th>Current Price</th>
                    <td>: {productStats.product.productPrice.price.toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })}</td>
                </tr>
                <tr>
                    <th>Last Purchase Price</th>
                 
                    <td>: 
  {
    productStats.product.productPrice?.last_PurchasePrice?.toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })
  }
</td>


                 
                        </tr>

                        <tr>
                    <th>Est. Net Profit</th>
                 
                    <td>:
  {
    productStats.product.productPrice?.price && productStats.product.productPrice?.last_PurchasePrice
      ? (productStats.product.productPrice.price - productStats.product.productPrice.last_PurchasePrice).toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })
      : null // Or any default value if necessary
  }
</td>



                 
                        </tr>
                        <tr>
  <th>Est. Net Profit for all stock</th>
  <td>:
    {
      productStats.product.productPrice?.price && productStats.product.productPrice?.last_PurchasePrice
        ? ((productStats.product.productPrice.price - productStats.product.productPrice.last_PurchasePrice) * productStats.product.productQuantity.quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })
        : null // Or any default value if necessary
    }
  </td>
</tr>
                
                </tbody>
              </table>
            </div>

            {/* Purchase History */}
            {productStats.purchaseTrend.length > 0 && (
              <ProductHistoryTabs productStats={productStats} />
            )}

            {/* Display if no purchase history */}
            {productStats.purchaseTrend.length === 0 && (
              <p className="no-history">No history available.</p>
            )}
          </div>
        ))}
    </div>
  );
}

export default MovementComparison;
