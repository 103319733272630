import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../../helpers/axios-helper";
import { useAuth,useAuthUser } from '../../../contexts/auth-context';
import {  toast } from 'react-toastify';
import PrintObject from "./Print/PrintObject";
import html2canvas from 'html2canvas';
interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function Product() {
  const { id } = useParams<RouteParams>();
  const [voucher, setVoucher] = useState<any>(null);
  const AuthUser = useAuthUser();
  const [editing, setEditing] = useState(false);
  const [collectorInfo, setCollectorInfo] = useState<string>('');
  const [declinedReason, setDeclinedReason] = useState("");

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    customaxios.get(`Voucher/GetVoucherById/${id}`).then((res) => {
      console.log(res.data);
      setVoucher(res.data);
    });
  }, [id]);


  
//set funciton to post data with vouicher id and user id to verify and approve
//approve ovucher post requewst
const VerifyVoucher = () => {
  const verifiedBy = {
    Voucher: {
      VoucherId: Number(id)
    },
    Verified_User: {
      
      id: AuthUser.id,
      // add any other properties that the User interface requires
    }
  };
console.log("payload");
console.log(verifiedBy);
  customaxios.post('/voucher/VerifyVoucher', 
  
  verifiedBy,
  
  )
  .then(response =>
    { console.log(response)
      toast.success("VerifyVoucher Submitted Successfully")
    }
     )
  .catch(error =>
    
    {console.log(error)
      
      if (error.response && error.response.status === 403) {
        toast.error("Unauthorized: You don't have permission to access this resource.");
      } else {
        toast.error("VerifyVoucher Submission Failed");
      }

}
  );
};

const ApproveVoucher = () => {
      const approvedBy = {
        Voucher: {
          VoucherId: Number(id)
        },
        Approved_User: {
          
          id: AuthUser.id,
          // add any other properties that the User interface requires
        }
      };
    console.log("payload");
    console.log(approvedBy);
      customaxios.post('/voucher/ApproveVoucher', 
      approvedBy,
      )
        .then(response =>
          { console.log(response)
            toast.success("ApproveVoucher Submitted Successfully")
          }
           )
        .catch(error =>
          
          {console.log(error)
            if (error.response && error.response.status === 403) {
              toast.error("Unauthorized: You don't have permission to access this resource.");
            } else {
              toast.error("VerifyVoucher Submission Failed");
            }
      
      }


        );
};




// When voucher is set, initialize collectorInfo
useEffect(() => {
  if (voucher) {
    setCollectorInfo(voucher.collectorInfo);
  }
}, [voucher]);

const handleEditClick = () => {
  setEditing(true);
};

const handleSaveClick = () => {
  voucher.collectorInfo = collectorInfo;

  console.log(voucher);
  // PUT the updated collectorInfo to the API with the voucher id
  customaxios
    .put(`voucher/UpdateCollectorInfo`, 
    voucher
    )
    .then((response) => console.log(response))
    .catch((error) => console.log(error));

  setEditing(false);
};

const DeclineVoucher = () => {
  voucher.collectorInfo = collectorInfo;
  voucher.Declined_Reason = declinedReason; // Use the reason state variable
  console.log("DeclineVoucher");
  console.log(voucher.Declined_Reason);

  console.log(voucher);
  // PUT the updated collectorInfo and Declined_Reason to the API with the voucher id
  customaxios
    .post(`Voucher/Decline_Voucher`, voucher)
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

const handleDecline = () => {
  // Decline voucher here with declinedReason as the reason
  DeclineVoucher();
  setShowModal(false);
};

const handleCancel = () => {
  setShowModal(false);
};

  return (
    <div className="Voucher_view_singular">
     
      {voucher && (
      <>
<PrintObject voucher={voucher} />

       <div className="interactions">
       <br/>
      <br/>
       <div className="createdby">
         <h3>Created by {voucher.created_By.firstName} {voucher.created_By.lastName} </h3>
         <h4>Created DateTime {new Date(voucher.created_DateTime).toLocaleString()} </h4>
         </div>
      {
        voucher.verifiedBy && (
         <div className="Verified">
          <h3>Verified by : {voucher.verifiedBy.verified_User.firstName}  {voucher.verifiedBy.verified_User.lastName}</h3>
          <h4>Verified DateTime :{new Date(voucher.verifiedBy.verified_Date_Time).toLocaleString()}</h4>
         </div>
        )
      }
      {
        voucher.approvedBy && (
         <div className="approvedby">
          <h3>approved By  : {voucher.approvedBy.approved_User.firstName}  {voucher.approvedBy.approved_User.lastName}</h3>
          <h4>approved DateTime :{new Date(voucher.approvedBy.approved_Date_Time).toLocaleString()}</h4>
         </div>
        )
      }
      {!voucher.verifiedBy && ( <button className="button_one" onClick={VerifyVoucher}>Verify</button>)}
        {
          voucher.verifiedBy && (
           !voucher.approvedBy && ( <button className="button_one" onClick={ApproveVoucher}>Approve</button>)

          )
        }
      
      <>
      {!voucher.declined && (
        <button className="button_one danger" onClick={() => setShowModal(true)}>
          Decline Voucher
        </button>
      )}

      {showModal && (
        <div className="modal">
          <h3>Enter Reason for Declining</h3>
          <input
            type="text"
            value={declinedReason}
            onChange={(e) => setDeclinedReason(e.target.value)}
          />
          <div className="modal-buttons">
            <button onClick={handleDecline}>Confirm Decline</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      )}
    </>
        
        {voucher.declined==true  && (<p>Voucher Declined {voucher.declined_Date_Time?.toLocaleDateString()}</p>)}
       
       </div>
       <div className="voucherdetails">
       <br/>
      <br/>
        <p>Voucher no: {voucher.voucherId}</p>
        <h4>Invoice Number: {voucher.invoiceNumber ? voucher.invoiceNumber : 'Unknown'}</h4>
        <h4>Invoice date: {voucher.invoice_date ? new Date(voucher.invoice_date).toLocaleString() : 'Unknown'}</h4>
        <br />

        <h4>Branch: {voucher.branch?.name}</h4>
        <br />
        <h4>Category : {voucher.category.description} </h4>
        <h4>Payee: {voucher.payee?.name || 'Unknown'}</h4>
        <br />
        <p>Payment Mode: {voucher.paymentMode === 'cheque' ? `(${voucher.paymentMode} ${voucher.cheque_no})` : voucher.paymentMode}</p>
        <p>Total: {voucher.voucher_Total.toLocaleString()} LKR</p>




        <div>
    {editing ? (
      <>
        <input
          type="text"
          value={collectorInfo}
          onChange={(e) => setCollectorInfo(e.target.value)}
        />
        <button onClick={handleSaveClick}>Save</button>
      </>
    ) : (
      <p onClick={handleEditClick}>Voucher Collector info: {voucher.collectorInfo}</p>
    )}
  </div>
       </div>
        
     <Voucheritems voucher={voucher}/>
 
      </>
      )}


    </div>
  );
};

 function Voucheritems({voucher}){

  return(

    <div className="voucheritems">
    <br/>
      
        <br/>
        <br/>
        <br/>
    <table>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Note</th>
            <th>Invoice</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
        {voucher.voucherItems.map((item) => (
  <tr key={item.voucherItemId}>
    <td>{item.voucherItemId ? item.voucherItemId : "-"}</td>
    <td>{item.voucherDescription ? item.voucherDescription : "-"}</td>
    <td>{item.note ? item.note : "-"}</td>
    <td>{item.invoice_Number ? item.invoice_Number : "-"}</td>
    <td>{item.voucherAmount ? item.voucherAmount.toLocaleString() : "-"} LKR</td>
  </tr>
))}


           <tr >
              <td></td>
              <td><strong>Total</strong></td>
              <td><strong>{voucher.voucher_Total.toLocaleString()} LKR</strong></td>
            </tr>
        </tbody>
      </table>
    </div>
  )
}