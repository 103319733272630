import ListPrepayments from "./ListPrepayments";
import CreatePrepayments from "./CreatePrepayments";
export default function  Prepayments (){
    
    return(
    <>
   
      <CreatePrepayments/>
        <br />
        <ListPrepayments/>
    </>)
}