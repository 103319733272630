import React, { useEffect, useState,useRef  } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Default from "../../../Loading_Defualt";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the Favorite icon from MUI
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import useDoubleClick from 'use-double-click';
import { useAuth,useAuthUser } from '../../../../contexts/auth-context';

interface Brand {
  id: string;
  brandId: any;
  brand_Name: string;
  productcount: number;
  archCount: number;
  image_url: string;
  favourite:boolean;
}

  interface Brand_select_Props {
    isOpen: boolean;
    closeModal: () => void;

    selectedCollection_Id: string | null;
    setselectedCollection_Name: (category: string | null) => void;
    setselectedCollection_Type: (category: string | null) => void;
    setselectedCollection_Id: (category: string | null) => void;
  }
export default function BrandSelector(props:Brand_select_Props) {
  const auth = useAuthUser();
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
    const [brandsList, setBrandsList] = useState<Brand[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string | null>(props.selectedCollection_Id);

    console.log('From main ',selectedBrand)
    const [searchTerm, setSearchTerm] = useState<string>("");
    console.log(props)
    const filteredBrands = brandsList.filter((brand) => {
      const brandName = brand.brand_Name;
      if (brandName && typeof brandName === 'string') {
        return brandName.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });


   
  const fetchData = () => {
    customaxios.get("product/Getproductbrands").then((response) => {
      setBrandsList(response.data);
      if (brandsList.length > 0) {
        setSelectedBrand(brandsList[0].brandId);
        console.log("default brand",brandsList[0].brandId)
      }
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("brandsList", JSON.stringify(data));
    });
  };


  useEffect(() => {
   
    /*const storedData = localStorage.getItem("brandsList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setBrandsList(data);
        if (brandsList.length > 0) {
          setSelectedBrand(brandsList[0].brandId);
        }
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("brandsList");
      }
    }
    */
    fetchData();
  }, []);
  

  function clearLocalStorage() {
    localStorage.removeItem("brandsList");
    setBrandsList([]);
    setLoading(true);
    fetchData();
  }

  const handleBrandClick = (brandId: string) => {
    console.log(brandId);
    setSelectedBrand(brandId);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const handle_select = (Brand:Brand)=>{
    
    console.log(Brand)
    setSelectedBrand(Brand.brandId)
    props.setselectedCollection_Name(Brand.brand_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_ByBrand_paged")
    props.setselectedCollection_Id(Brand.brandId)
    console.log('selected ', Brand)
    props.closeModal();

  }



    const set_favourite_brand = (brand: Brand) => {
      if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
      // Handle the double click action here
      console.log('Double click on ', brand.brandId);
    
      customaxios
        .post("Brand/Brand_Favourite?BrandId="+ brand.brandId 
        )
        .then((response) => {
          console.log(response);
          fetchData();
        })
        .catch((error) => {
          console.error("Error during double click action:", error);
        });
      }
    };


/*
    const collection = useRef();
    useDoubleClick({
      onSingleClick: e => {
        console.log(e, 'single click');
      },
      onDoubleClick: e => {
        console.log(e, 'double click');
        set_favourite(e)
      },
      ref: collection,
      latency: 250
    });
 */

    return(
    <>
      <div className="CategoriesList">
     
        <div className="controls">
          <input
            className="Search_bar"
            type="text"
            placeholder="Search Brand..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="refresh" onClick={clearLocalStorage}>
            <FontAwesomeIcon icon={faSyncAlt} />{" "}
          </button>
          <a target="_blank" href={`${window.location.origin}/B`}>
      
      <div className="Categorylist_item">
        <div className="CategoryName"><p>&#9734; Open In New Tab</p></div>
      
        
      </div>
         </a>  
        </div>
        {loading ? (
          <Loading_Default />
        ) : (
          <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
             
             <tr>
                      <th className="px-6 py-3">Logo</th>
                      <th className="px-6 py-3">Name</th>
                      <th className="px-6 py-3">No of Items</th>
                      <th className="px-6 py-3">No of Archs</th>
                      <th className="px-6 py-3">Fav</th>
             </tr>
                    </thead>
                  
                  
                  <tbody>
                    
                    {filteredBrands.map((brand) => (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-red-500 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td>  {brand.image_url && (
                           
                              <img src={brand.image_url} style={{ maxWidth: 50 }} alt="" />
                          )} </td>
          
                          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select(brand);  }}>
                          {brand.brand_Name}
                          </td>
                          <td>
                          {brand.productcount}
                          </td>
                          <td>
                          {brand.archCount}
                          </td>
                          <td>
                          {brand.favourite === true ? (<IconButton
                      onClick={() => set_favourite_brand(brand)}
                      color="secondary" // Change color as needed
                    >
                      <FavoriteIcon />
                    </IconButton>):(<IconButton onClick={() => set_favourite_brand(brand)}>
                      <FavoriteBorderIcon />
                    </IconButton>)}
                          </td>
                      </tr>
                         
                    
                      ))}
                  </tbody>
                  </table>
                  
        )}
      </div>
        </>
        )
}