export function Orderfilter(items: any[], filtertype: string) {
 // console.log("items", items);
  if (!items || items.length === 0) {
    return {};
  }
  if (filtertype === "") {
    return items.reduce((acc: any, item: any) => {
    
      const paymentMode = item.paymentMode;
      const orderId = item.orderId;
      const creditPeriod = item.creditPeriod;
      if (!acc[paymentMode]) {
        acc[paymentMode] = {};
      }
      if (!acc[paymentMode][orderId]) {
        acc[paymentMode][orderId] = {};
      }
      if (!acc[paymentMode][orderId][creditPeriod]) {
        acc[paymentMode][orderId][creditPeriod] = [];
      }
      acc[paymentMode][orderId][creditPeriod].push(item);
      return acc;
    }, {});
  } else {
   const filteredItems = items.filter((item) => {
      const isMatch = item.orderItemStatus === filtertype;
    /*  if (!isMatch) {
        console.log(`Filtering out item ${item.orderId} with status ${item.orderItemStatus}`);
      }*/
      return isMatch;
    });
 /*    console.log("filteredItems", filteredItems);*/

  return filteredItems.reduce((acc: any, item: any) => {
   
      const paymentMode = item.paymentMode;
      const orderId = item.orderId;
      const creditPeriod = item.creditPeriod;
      if (!acc[paymentMode]) {
        acc[paymentMode] = {};
      }
      if (!acc[paymentMode][orderId]) {
        acc[paymentMode][orderId] = {};
      }
      if (!acc[paymentMode][orderId][creditPeriod]) {
        acc[paymentMode][orderId][creditPeriod] = [];
      }
      acc[paymentMode][orderId][creditPeriod].push(item);
      return acc;
    }, {});
  /* 
   return filteredItems.reduce((acc: any, item: any) => {
      const orderId = item.orderId;
      const creditPeriod = item.creditPeriod;
      if (!acc[orderId]) {
        acc[orderId] = {};
      }
      if (!acc[orderId][creditPeriod]) {
        acc[orderId][creditPeriod] = [];
      }
      acc[orderId][creditPeriod].push(item);
      return acc;
    }, {});*/
  }
}
