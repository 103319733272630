import React, { useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import { toast } from "react-toastify";
import "./style/Style.css";

export default function Arch_Refresh() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    let timer;
    if (isCompleted) {
      timer = setTimeout(() => {
        setIsCompleted(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isCompleted]);

  const handleExportClick = () => {
    setIsLoading(true);

    customaxios
      .get("Product/gbetduplicatearch",)
      .then((response) => {
        setIsLoading(false);
        setIsCompleted(true);

       
        toast.success("Archs refreshed");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Refresh failed:", error);
        toast.error("Refresh failed.");
      });
  };

  return (
    <>
      <button className="button_one" onClick={handleExportClick} disabled={isLoading}>
        {isLoading ? "Refreshing" : isCompleted ? "Refreshed" : "Refresh"}
        {isLoading && (
          <span className="dots-animation">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        )}
      </button>

    </>
  );
}
