import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";  
import customaxios from "../../helpers/axios-helper";
import Sales_order_v2 from '../../Sales_order/Sales_order_v2';
import "./Style/Style.css"

interface RouteParams {
    id: string;
    [key: string]: string;
  }
export default function Sales_Rep({ id: routeId }: { id: string | null }){
    const { id: paramId } = useParams<RouteParams>();
    const [SalesRep, setSalesRep] = useState<any>([]);

    const id = paramId ? paramId : routeId;

    useEffect(() => {
        customaxios
        .post(`/user/get_sales_rep_byid/${id}`, )
          .then((response) => {
            setSalesRep(response.data);
            console.log("Sales Rep list");
            console.log(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Handle validation errors
              const validationErrors = error.response.data;
              console.log("Validation errors:", validationErrors);
              // Update state or show error messages to the user
            } else {
              console.log("Error:", error);
              // Handle other types of errors
            }
          });

          customaxios
          .post(`/user/get_sales_rep_byid/${id}`, )
            .then((response) => {
              setSalesRep(response.data);
              console.log("Sales Rep list");
              console.log(response.data);
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                // Handle validation errors
                const validationErrors = error.response.data;
                console.log("Validation errors:", validationErrors);
                // Update state or show error messages to the user
              } else {
                console.log("Error:", error);
                // Handle other types of errors
              }
            });
      }, []);


    return(<>

    <a target="_blank" key={SalesRep.id} href={`/Sales_Rep/`+SalesRep.id} className="Sales_rep">
                
                <div className="Name">
                {SalesRep.firstName
                }
                </div>
                <div className="mail">
                    {SalesRep.email}
                </div>
            </a>
 
<Sales_order_v2 id={id}/>

    </>)
}