import React from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';

interface Cheque {
  chequeNumber: string;
  bank: string;
  amount: number;
  chequeDate: Date;
  state: string;
}

interface ChequeListProps {
  cheques: Cheque[];
  onRemoveCheque: (index: number) => void;
}

const ChequeList: React.FC<ChequeListProps> = ({ cheques, onRemoveCheque }) => {
    const handleRemoveCheque = (index: number) => {
      onRemoveCheque(index);
    };
  
    if (cheques.length === 0) {
      return <p>No cheques to display.</p>;
    }
  
    return (
      <>
       <table className="ledger_View_Table">
       <thead>
       <tr className="Ledger_View_Table_Row Title">
            <th className="Date">Date</th>
            <th className="Cheque_no">Cheque No</th>
            <th className="Bank">Bank</th>
            <th className="Amount">Amount </th>
            <th className="state">state </th>
            <th className="Delete">Delete</th>
          </tr>
       </thead>
       <tbody>
       {cheques.map((cheque, index) => (
       <tr className="Ledger_View_Table_Row">

        <td>{cheque.chequeDate.toLocaleDateString('en-UK', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
        <td>{cheque.chequeNumber}</td>
        <td>{cheque.bank}</td>
        <td>{cheque.amount}</td>
        <td>{cheque.state}</td>
        <td className='danger' onClick={() => handleRemoveCheque(index)} >-</td>
       </tr>
             ))}
       </tbody>
       </table>
      
      </>
    );
  };
  

export default ChequeList;
/*
           <button className="remove-icon" onClick={() => handleRemoveCheque(index)}>
              <RiDeleteBinLine style={{ color: 'red' }} />
            </button>


            <div className="chequelist">
        {cheques.map((cheque, index) => (
          <div key={index} className="cheque">
            <p className="number">Cheque No: {cheque.chequeNumber}</p>
            <p className="bank">Bank: {cheque.bank}</p>
            <p className="amount">Amount: {cheque.amount}</p>
            <p className="date">Date: {cheque.chequeDate.toLocaleDateString('en-UK', { day: 'numeric', month: 'long', year: 'numeric' })}</p>

            <p className="state">State: {cheque.state}</p>
            <button className="button_one danger" onClick={() => handleRemoveCheque(index)}>
                Remove
            </button>
          </div>
        ))}
      </div>
*/