import {Modal, Box} from "@mui/material";
import { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import Loading_Defualt from "../../components/Loading_Defualt";
import "./Style/Style.css";

import { format } from 'date-fns'; 
const Account = ({ id, open, onClose }) => {
  const [SelectedLedger, setSelectedLedger] = useState(null);
  const [loading, setLoading] = useState(true); // Step 1: Add a loading state
  const [key, setkey] = useState(0); // Step 1: Add a loading state

console.log(SelectedLedger)
  useEffect(() => {
    setLoading(true); // Step 2: Set loading to false when data is fetched
    
    customaxios.get(`Accounts/Get_Account_with_Transactions/${id}`)
    .then((response) => {
        console.log(response);
        setSelectedLedger(response.data);
        setLoading(false); // Step 2: Set loading to false when data is fetched
  
    })
    .catch((error) => {
        console.log(error);
        setLoading(false); // Step 2: Set loading to false when data is fetched
  
    });
}, [id,key]);




  return (
    <Modal
      open={open}
      onClose={() => { setSelectedLedger(null); onClose(); }}
    >
      <Box sx={{ position: "absolute", overflow: "auto", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "80vw", maxWidth: "1200px", height: "80vh", maxHeight: "900px", bgcolor: "background.paper", boxShadow: 24, p: 4 }}>
        {loading ? (
          <Loading_Defualt />
        ) : (
          // Show main content when loading is false and SelectedLedger is not null
          SelectedLedger && (
            <>
            <p>Account: {SelectedLedger.account.name}</p>
            <p>Balance: {SelectedLedger.account.balance.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</p>
            <p>company: {SelectedLedger.account.company}</p>
           

            
           <div className="transactions">

           <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    
<tr>
  <td>Date</td>
  <td>Account</td>
  <td>Amount</td>
  <td>Reference</td>
  <td>Running bal</td>
</tr>

  </thead>

  <tbody>

  {  SelectedLedger.transactions.map((transaction) => (

<>
{
  transaction.fromAccountId === SelectedLedger.account.account_Id ? (
    <Outbound_row transaction={transaction} />
  ) : (
    <Inbound_row transaction={transaction} />
  )
}



</>
            ))
        }
   

  </tbody>
</table>

         {/*  SelectedLedger.transactions.map((transaction) => (

<>
{
  transaction.fromAccountId === SelectedLedger.account.account_Id ? (
    <Outbound transaction={transaction} />
  ) : (
    <Inbound transaction={transaction} />
  )
}



</>
            ))
      */  }
   
</div>
           {
            /*
             <TransactionForm SelectedLedger={SelectedLedger} setkey={setkey}  /> */
           }
          
            </>
          )
        )}
      </Box>
    </Modal>
  );
  
}

export default Account;
function Inbound_row({transaction}){
  return(
    <tr className="bg-white border-b dark:bg-gray-800 border-green-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      <td className="DateTime">{transaction.transaction_Date_Time ? format(transaction.transaction_Date_Time, 'MM-dd-yy') : ''}</td>
      <td className="account">{transaction.fromAccount.name}</td>
      <td className="amount">+{transaction.amount.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>
      <td className="reference">{transaction.beneficiary_Description}</td>
      <td className="reference">{transaction.toAccountBalance.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>

</tr>
  )
}

//HH:mm
function Outbound_row({transaction}){
  return(
    <tr className="bg-white border-b dark:bg-gray-800 border-red-700 hover:bg-gray-50 dark:hover:bg-gray-600">
    <td className="DateTime">{transaction.transaction_Date_Time ? format(transaction.transaction_Date_Time, 'MM-dd-yy') : ''}</td>
    <td className="account">{transaction.toAccount.name}</td>
    <td className="amount">-{transaction.amount.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>
    <td className="reference">{transaction.sender_Description}</td>
    <td className="reference">{transaction.fromAccountBalance.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>

</tr>
  )
}
function Inbound({transaction}){
    return(
        <div className="card Inbound">
        <p className="account">{transaction.fromAccount.name}</p>
        <p className="amount">+ {transaction.amount}  (running bal: {transaction.toAccountBalance})</p>
        <p className="DateTime">{transaction.transaction_Date_Time ? format(transaction.transaction_Date_Time, 'yyyy-MM-dd HH:mm:ss') : ''}</p>
        <p className="reference">Reference: {transaction.beneficiary_Description}</p>

</div>
    )
}
function Outbound({transaction}){
    return(
        <div className="card Outbound">
        <p className="account">{transaction.toAccount.name}</p>
        <p className="amount">- {transaction.amount} (running bal: {transaction.fromAccountBalance})</p>
        <p className="DateTime">{transaction.transaction_Date_Time ? format(transaction.transaction_Date_Time, 'yyyy-MM-dd HH:mm:ss') : ''}</p>
        <p className="reference">Reference: {transaction.sender_Description}</p>


       
</div>
    )
}
 /* <div className="Transaction_Row">
      <div className={transaction.fromAccount.account_Id === SelectedLedger.account.account_Id ? "right" : "Left"}>
        <td> {transaction.fromAccount.account_Id === SelectedLedger.account.account_Id && (
          <div className="arrow right-arrow"> <ArrowForwardIcon fontSize="small" /> {transaction.fromAccount.name}</div>
        )} </td>
        <p className="runningbal">Running Balance {transaction.fromAccountBalance}</p>
       
      </div>

      <div className={transaction.toAccount.account_Id === SelectedLedger.account.account_Id ? "right" : "Left"}>
        <td>  {transaction.toAccount.account_Id === SelectedLedger.account.account_Id && (
          <div className="arrow left-arrow"> <ArrowBackIcon fontSize="small" /> {transaction.toAccount.name}</div>
        )}</td>
        <p className="runningbal">Running Balance {transaction.toAccountBalance}</p>
      
      </div>
    </div>
    
    
    

               <table>
            <thead>
                <tr>
                    <th>Running <br />balance</th>
                    <th>&#160;&#160;&#160;&#160;&#160;</th>
<th>From Account</th>
<th>&#160;&#160;&#160;&#160;&#160;</th>

<th>To Account</th>
<th>&#160;&#160;&#160;&#160;&#160;</th>
                    <th>Running<br /> balance</th>

                </tr>
            </thead>
            <tbody>
            {
  SelectedLedger.transactions.map((transaction) => (
  <tr>
    <td>
  {transaction.fromAccountBalance}
    </td>
    <th>&#160;&#160;&#160;&#160;&#160;</th>
    <td>
   {transaction.fromAccount.name}
    </td>
    <td>
    &#160;&#160;&#160;&#160;&#160;
    </td>
    <td>
    {transaction.toAccount.name}
    </td>
    <th>&#160;&#160;&#160;&#160;&#160;</th>
    <td>
   {transaction.toAccountBalance}
    </td>
  </tr>
    
  ))
}




            </tbody>
           </table>
    */