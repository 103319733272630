import ListStaffLoan from "./ListStaffLoan";
import CreateStaffLoan from "./CreateStaffLoan";
export default function  StaffLoan (){
    
    return(
    <>
   
      <CreateStaffLoan/>
        <br />
        <ListStaffLoan/>
    </>)
}