import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as UserSVG } from '../assets/vectors/user.svg';
import { useAuth } from '../contexts/auth-context';
import { useAuthUser } from '../contexts/auth-context';
import { useCart } from './Cart/CartProvider';
import Controls from './Controls/Controls';
import Logout from './logout';
import Navigation from './Navigation/Navigation';
import ThemeToggle from '../helpers/Theme';
export default function Nav() {
  const AuthUser = useAuthUser();
  const { getTotalItemsInCart } = useCart();

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const isAuth = useAuth();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const cartItemCount = getTotalItemsInCart();

  if (!AuthUser) {
    return null;
  }

  return (
    <nav className="RightNav">

  <div className="Theme_settings">
        <ThemeToggle />
      </div>
 
<div className="Menu">
        <Navigation />
      </div>
          <div className="controls">
        <Controls />
      </div>
  
      <div className="nav-user" ref={dropdownRef}>
        <button onClick={toggleDropdown} className={isOpen ? 'open' : ''}>
          <UserSVG />
        </button>
        <div id="nav-user-dropdown" className={isOpen ? 'nav-user-dropdown open' : 'nav-user-dropdown'}>
          {isAuth ? (
            <>
              <div>Cart Count: {cartItemCount}</div>
              <div>
                {AuthUser.name ? (
                  <div>
                    {AuthUser.name}
                  </div>
                ) : (
                  <div>No name available</div>
                )}
              </div>
              <Logout />
            </>
          ) : (
            <>
              <Link to="/">Login</Link>
              <Link to="/register">Register</Link>
            </>
          )}
          <div>V0.99 beta</div>
        </div>
      </div>
    </nav>
  );
}
