import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import {Customer } from '../../models/Customer/Customer';
import { useCart } from '../Cart/CartProvider';
import { useAuth,useAuthUser } from '../../contexts/auth-context';

export default function SelectedCustomer() {
  const AuthUser = useAuthUser();
  const isAuth = useAuth();
    const { 
        //updateCustomer, Customer,
        AddCustomer, customer,
    } = useCart();
         const [isEditingCustomer, setIsEditingCustomer] = useState(false);
         const Customernul:Customer = {
            customerId  :"string" ,
            customerName :"string" ,
            alias :"string" ,
            group_name :"string" ,
            opening_bal_in_base_currency :"string" ,
            prev_Bal :"string" ,
            addressline1 :"string" ,
            addressline2 :"string" ,
            addressline3 :"string" ,
            addressline4 :"string" ,
            Tel_no :"string" ,
            contact :"string" ,
            FaxNo :"string" ,
            Email :"string" ,
            Opt_field1 :"string" ,
            CustomerState :"string" ,
        
          
          }

         const [relatedCustomer, setRelatedCustomer] = useState([]);
         useEffect(() => {
           if (isEditingCustomer) {
             console.log("Customer call");
             // Make API call to fetch related items here
             customaxios.post("/Customer/SearchCustomer", {
               keyword: customer.customerName
             })
               .then((response) => {
                 setRelatedCustomer(response.data.customers);
                 console.log("Customer list");
                 console.log(relatedCustomer);
                 console.log(relatedCustomer.length);

               })
               .catch((error) => {
                 console.log(error);
               });
           }
         }, [isEditingCustomer, customer]);
       
         const handleCustomerchange = (event) => {
           Customernul.customerName = event.target.value;
           AddCustomer(Customernul);
         };
       
         const handleSaveChanges = (item) => {
           setIsEditingCustomer(false);
           AddCustomer(item)
       
          
           console.log("Savedinstorage");
           console.log(customer);
           
         };
         const handleCancelEditing = () => {
           setIsEditingCustomer(false);
           AddCustomer(customer);
         };


  return (
   <>
 {
  (AuthUser?.role === "Customer" ) ? (
   <></>
  ) : (
    <div className="customer">

    {isEditingCustomer ? (
<div className="Customer_Name">
  <input  type="text" value={customer.customerName} onChange={handleCustomerchange} />
  <button onClick={handleSaveChanges}>Select</button>
  <button onClick={() => {handleCancelEditing()}}>Cancel</button>
</div>
        ) : (
        <p   onClick={() => setIsEditingCustomer(true)}>
        Customer : {customer.customerName} <br/>

        </p>
        )}{isEditingCustomer && relatedCustomer.length > 0 && (
        <ul className='CustomerSelectlist'>
        {relatedCustomer.map(item => (
            <li className='CustomerSelect' key={item.customerId} onClick={() => handleSaveChanges(item)}>
            {item.customerName}
            </li>
        ))}
        </ul>
        )}
        <div className="customerid">

        Previous : {customer.prev_Bal}<br/>
        Group Name : {customer.group_name}
        </div>
</div>
  )
 }
</>
  );
}

