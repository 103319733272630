import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import { useAuthUser,useAuth } from '../../contexts/auth-context'; 
import Loading_Defualt from "../../components/Loading_Defualt";
import "./Style/Style.css"
import { useNavigate } from 'react-router-dom';
export default function List_Sales_reps(){
    const [SalesReps, setSalesReps] = useState([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const isAuth = useAuth();

    const AuthUser = useAuthUser();
    const navigate = useNavigate();
    if (!isAuth) {
      // Redirect to the login page if not verified
      navigate('/');
    }

    // Replace 'role1', 'role2', etc., with the actual roles you want to check
    const allowedRoles = ['Director','Developer','Praveen','Billing_Agent'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }
  
  useEffect(() => {
    customaxios
    .get("/user/get_sales_reps", )
      .then((response) => {
        setSalesReps(response.data);
      //  console.log("Sales Rep list");
        //console.log(response.data);
        setIsLoading(false);

      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // Handle validation errors
          const validationErrors = error.response.data;
          console.log("Validation errors:", validationErrors);
          // Update state or show error messages to the user
        } else {
          console.log("Error:", error);
          // Handle other types of errors
        }
      });
  }, []);

 


      return (
        <div className="Wrapper">
          
   
          {isLoading ? (
            <Loading_Defualt />
          ) : (
            <>
            <table  className="w-3/4 mx-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
 <tr>
  <th>Sales Rep</th>
  <th>Email</th>
  <th>Incomplete Orders</th>
  <th>Urgent Orders</th>
 </tr>
 </thead>

 <tbody>
 {
            SalesReps.map((sale_rep) => {
              if (sale_rep.value.role === 'Sales_Rep') {
                return (
                  <tr
                  onClick={() => {
              
                    window.location.href = `/Sales_Rep/${sale_rep.value.id}`;
                  }}  
                  key={sale_rep.value.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">  
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{sale_rep.value.firstName}</td>  
                  <td>{sale_rep.value.email}</td>  
                  <td>{sale_rep.value.incompleteOrdersCount}</td>  
                  <td>{sale_rep.value.urgent_Sales_Orders
                      }</td>  
         
         </tr>
               
                );  
              }
            
            })}
            <tr
              className="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">
              <td className="whitespace-nowrap px-6 py-4 font-medium"></td>
              <td
                className="whitespace-nowrap px-6 py-4 text-center">
              Others
              </td>
              <td className="whitespace-nowrap px-6 py-4"></td>
              <td className="whitespace-nowrap px-6 py-4"></td>
              <td className="whitespace-nowrap px-6 py-4"></td>
            </tr>

{
            SalesReps.map((sale_rep) => {
              if (sale_rep.value.role !== 'Sales_Rep') {
                return (
                  <tr  onClick={() => {
              
                    window.location.href = `/Sales_Rep/${sale_rep.value.id}`;
                  }}   key={sale_rep.value.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">  
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{sale_rep.value.firstName}</td>  
                  <td>{sale_rep.value.email}</td>  
                  <td>{sale_rep.value.incompleteOrdersCount}</td>  
                  <td>{sale_rep.value.urgent_Sales_Orders
                      }</td>  
         
         </tr>
               
                );  
              }
              return null; // Render nothing if not a sales rep
            })}
 </tbody>
            </table>


           
    
            </>
             )}
       
      </div>
      
    
    );
    
}

function Indicators_Sales_Orders(){
  return(
    <table>
    <thead>
<tr>
<th>Indicators</th>
</tr>            
    </thead>
<thead>
<tr>
<th>Color</th>
<th>Description</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className="Regular_order">0</div>
</td>
<td>
Regular Order
</td>
</tr>

<tr>
<td >
<div className="Urgent_order">0</div>
</td>
<td>
Urgent Order
</td>
</tr>
</tbody>

  </table>
  )
}