import React, { Component } from 'react';

interface LocationState {
  latitude: number | null;
  longitude: number | null;
  errorMessage: string | null;
}

class LocationComponent extends Component<{}, LocationState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.getLocation();
  }

  getLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.setState({ latitude, longitude, errorMessage: null });
        },
        (error) => {
          this.setState({ errorMessage: error.message });
        }
      );
    } else {
      this.setState({ errorMessage: 'Geolocation is not available in this browser.' });
    }
  }

  requestLocationPermission = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getLocation();
        },
        (error) => {
          this.setState({ errorMessage: error.message });
        }
      );
    }
  }

  render() {
    const { latitude, longitude, errorMessage } = this.state;

    return (
      <div>
        {errorMessage ? (
          <div>
            Error: {errorMessage}
            <button onClick={this.requestLocationPermission}>Request Permission</button>
          </div>
        ) : (
          <div>
            {latitude !== null && longitude !== null ? (
              <div>
                Latitude: {latitude}<br />
                Longitude: {longitude}
              </div>
            ) : (
              <button onClick={this.requestLocationPermission}>Get Location</button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default LocationComponent;
