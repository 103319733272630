import React from "react";

export default function VoucherViewRow() {
  return (
    <div className="voucher_View_Row">
      <div className="voucher_Id">
        <p>Id</p>
      </div>
      <div className="Voucer_Date">
        <p>Date</p>
      </div>
      <div className="VoucherTotal">
        <p>total</p>
      </div>
      <div className="Paymentmode">
        <p>paymentmode</p>
      </div>
      <div className="payee">
        <p>payee</p>
      </div>
      <div className="invoiceNumber">
        <p>Invoice ID</p>

        </div>
    </div>
  );
}
